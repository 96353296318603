import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFooterComponent } from './app-footer.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AppFooterComponent
    ],
    exports: [
        AppFooterComponent
    ]
})
export class AppFooterModule { }
