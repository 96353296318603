import { NgModule, ModuleWithProviders } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import { ErrorHandlerPlugin } from './error-handler.plugin';

/**
 * Solution found on:
 * https://stackoverflow.com/questions/62755093/angular-error-generic-type-modulewithproviderst-requires-1-type-arguments
 */
declare module '@angular/core' {
    interface ModuleWithProviders<T = unknown> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}
@NgModule()
export class NgxsErrorHandlerPluginModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: NgxsErrorHandlerPluginModule,
            providers: [
                {
                    provide: NGXS_PLUGINS,
                    useClass: ErrorHandlerPlugin,
                    multi: true,
                },
            ],
        };
    }
}
