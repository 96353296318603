import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlossaryItemDirective, GlossaryItemHighlightDirective } from './glossary-item.directive';
import { MdDatepickerHighlitDates } from '../../../shared/directives/md-datepicker-highlight-dates.directive';
import { ClickStopPropagation } from '@sportlogiq/shared/directives/stop-propagation.directive';
import { EqualValidator } from '@sportlogiq/shared/directives/equal-validator.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        GlossaryItemDirective,
        GlossaryItemHighlightDirective,
        MdDatepickerHighlitDates,
        ClickStopPropagation,
        EqualValidator
    ],
    exports: [
        GlossaryItemDirective,
        GlossaryItemHighlightDirective,
        MdDatepickerHighlitDates,
        ClickStopPropagation,
        EqualValidator
    ]
})
export class DirectiveModule { }
