<div class="login-container dark-bg">
	<div class="login-inner">
		<a routerLink="/{{ appRoute.LOGIN }}"
			class="sl-logo">
			<sl-logo class="inverted large"
				[logoStyle]="'dark'">SPORTLOGiQ</sl-logo>
		</a>
		<ng-container *ngIf="activatedSection === loginPageSections.Login">
			<div class="top-part sl-text-default">
				<p *ngIf="contextualMessage"
					class="sl-text-default"
					[class.error-message]="contextualMessage.type === 'error'"
					[class.success-message]="contextualMessage.type === 'success'">{{contextualMessage.message}}</p>
				<p *ngIf="videoMessage"
					class="error-message">Please login to download the video</p>
			</div>
			<form class="fs-hide"
				role="form"
				[formGroup]="loginForm"
				(submit)="login($event, username.value, password.value)">
				<div>
					<mat-form-field>
						<input matInput
							class="centered"
							#username
							type="text"
							placeholder="{{'Username or Email' | translate}}"
							autocapitalize="off">
					</mat-form-field>
				</div>
				<div>
					<mat-form-field>
						<input matInput
							class="centered"
							#password
							type="password"
							placeholder="{{'Password' | translate}}">
					</mat-form-field>
				</div>
				<div class="submit-button-wrapper">
					<button class="sl-btn-box"
						type="submit"
						value="Submit"
						[disabled]="loading || (contextualMessage && contextualMessage.type==='success')">{{'Login' |
						translate}}</button>
					<div class="loading-wrapper">
						<loading-component [active]="loading"></loading-component>
					</div>
				</div>
			</form>

			<div class="dark-bg">
				<p class="sl-text-default">Forgot Password? <a
						class="sl-link-secondary sl-text-smaller forgot-password-link"
						routerLink="/{{ appRoute.FORGOT_PASSWORD }}">Click Here.</a></p>
			</div>
		</ng-container>

		<ng-container *ngIf="activatedSection === loginPageSections.ForgotPassword">
			<div class="top-part sl-text-default">
				<p class="sl-text-title welcome-message"
					*ngIf="!errorMessage">Enter your email to reset your password.</p>
				<p *ngIf="contextualMessage"
					class="sl-text-default"
					[class.error-message--secondary]="contextualMessage.status === 410"
					[class.error-message]="contextualMessage.type === 'error'"
					[class.success-message]="contextualMessage.type === 'success'">{{contextualMessage.message}}</p>
			</div>
			<form role="form"
				novalidate
				[formGroup]="forgotPasswordForm"
				(submit)="forgotPassword($event, email.value)"
				*ngIf="!contextualMessage || contextualMessage.type !== 'success'">
				<div>
					<mat-form-field>
						<input matInput
							formControlName="email"
							class="centered"
							#email
							type="email"
							placeholder="{{'Email' | translate}}"
							autocapitalize="off"
							required>
					</mat-form-field>
				</div>
				<div class="submit-button-wrapper">
					<button class="sl-btn-box"
						type="submit"
						value="Submit"
						[disabled]="loading">{{'Reset Password' | translate}}</button>
					<div class="loading-wrapper">
						<loading-component [active]="loading"></loading-component>
					</div>
				</div>
			</form>
		</ng-container>

		<ng-container *ngIf="activatedSection === loginPageSections.ForceResetEmailSent">
			<div class="top-part sl-text-default">
				<p *ngIf="contextualMessage"
					class="sl-text-default"
					[class.error-message]="contextualMessage.type === 'error'"
					[class.success-message]="contextualMessage.type === 'success'">{{contextualMessage.message}}</p>
			</div>
		</ng-container>

		<ng-container *ngIf="activatedSection === loginPageSections.CreateNewPassword">
			<h2 class="sl-text-title">Create New Password</h2>
			<change-password class="dark-bg"
				(changePasswordSuccessful)="passwordChangedSuccessfully($event)"></change-password>
			<div class="loading-wrapper loading-wrapper__change-password">
				<loading-component [active]="loading"></loading-component>
			</div>
		</ng-container>
	</div>

</div>
