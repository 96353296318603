import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkRendererComponent } from './link-renderer.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatMenuModule,
        MatTabsModule
    ],
    declarations: [
        LinkRendererComponent
    ],
    exports: [
        LinkRendererComponent
    ]
})
export class LinkRendererModule { }
