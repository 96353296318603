/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any, complexity */
import { Injectable } from '@angular/core';
import {
    ManpowerSituationType,
    GameRangeType,
    GameState,
    UserPermissions,
    PlayerPosition,
    DateRangeAsDates,
    Metric,
    VidParamsV3Clip,
    NoteTagPlayer,
} from 'app/shared/types';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
    cloneDeep as _cloneDeep,
    isArray as _isArray,
    some as _some,
    filter as _filter,
    head as _head,
    includes as _includes,
    reduce as _reduce,
    isEmpty as _isEmpty,
    xor as _xor,
    isFinite as _isFinite,
    size as _size,
    deburr as _deburr,
    gte as _gte,
    lte as _lte,
    isUndefined as _isUndefined,
    isEqual as _isEqual,
    flatten as _flatten
} from 'lodash';
import { SharedUtils } from '@sportlogiq/main/shared/sharedUtils';
import { Game } from '@sportlogiq/main/core/games/game.model';
import { VideoFile, LinkItem } from '@sportlogiq/main/shared/sl-types';
import { PlayerToi } from '@sportlogiq/main/scouting/player-comparison/player-comparison.types';
import { FaceoffName, FaceoffLocation, faceoffLocationsForGame } from '@sportlogiq/main/core/faceoffs/faceoff.types';
import { ScaleLinear } from 'd3-scale';
import { Path } from 'd3';
import { formatNumber } from '@angular/common';
import { BehaviorSubject, combineLatest, MonoTypeOperatorFunction, Observable, race } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, repeat, shareReplay, startWith, take } from 'rxjs/operators';
import { GameHighlightData } from '@sportlogiq/main/shared/game-highlights/games-highlights.types';
import { GameAPI } from '@sportlogiq/main/core/store/game/game.model';
import { PlayerModelV3Api, SeasonSummary } from '@sportlogiq/main/core/players/player.types';
import { ActionCompletion, Actions, ofActionCompleted, ofActionDispatched } from '@ngxs/store';
import { PlayerShiftV3ClipApiModel, SkaterOnIceV3Clip } from '@sportlogiq/main/game-video/game-video.types';
import { AdvancedStatDataForTable, AdvancedStatsColumnType } from '@sportlogiq/main/statistics/store/advanced-stats.types';
import { PlayerModelAPI } from '@sportlogiq/main/core/store/player/player.models';
import { Team } from '@sportlogiq/main/core/teams/team';
import { Constants } from 'environments/constants';
import { Player } from '@sportlogiq/main/core/players/player.model';
import { SkaterOnIcePlayer } from '@sportlogiq/main/playershift/player-shifts.types';

const positionLetterDic = {
    F: PlayerPosition.forward,
    G: PlayerPosition.goalie,
    D: PlayerPosition.defenceMan,
};

const positionPluralDic = {
    [PlayerPosition.goalie]: 'goalies',
    [PlayerPosition.defenceMan]: 'defencemen',
    [PlayerPosition.forward]: 'forwards',
    [PlayerPosition.all]: '',
};

/**
 * Returns if two arrays are equal.
 *
 * @param Array<Unknown> arr1
 * @param Array<Unknown> arr2
 * @returns
 */
export const isEqualArrays = (arr1: Array<unknown>, arr2: Array<unknown>): boolean => {
    // In order to find out if arrays are equivalent we generate an array with differences using XOR and if its empty, it means they are the equal.
    return _isEmpty(_xor(arr1, arr2));
};

/**
 * Checking to see if the date is valid
 * https://stackoverflow.com/a/1353711
 *
 * @export
 * @param {Date} d
 * @returns
 */
export function isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime());
}

/**
 * Certain browsers Firefox and Safari don't handle certain date formats
 * for ex: 2020-08-26 12:29:33 outputs an invalid date. More Info:
 * https://www.linkedin.com/pulse/fix-invalid-date-safari-ie-hatem-ahmad/
 *
 * @param dateString
 */
export const dateFormatDateForAllBrowsers = (dateString: string) => {
    try {
        // if the date is valid, new Date() will succeed, otherwise it will fail and we will apply the other hacky fix
        // this is mostly to make sure the date format is working across all the browsers we support (chrome, safari, firefox, and iOS safari)
        // some date format results in Invalid Date on iOS so we use lodash isDate to make sure the browser can convert to a supported Date
        const d = new Date(dateString);
        if (isValidDate(d)) {
            return dateString;
        }
        throw Error('Invalid Date');
    } catch (error) {
        return dateString.replace(/-/g, '/');
    }
};

/**
 * Returns the raw route path for current ActivatedRouteSnapshop
 *
 * @param {(ActivatedRouteSnapshot | ActivatedRouteSnapshot[])} route
 * @param {string} [baseStr='']
 * @returns
 */
export const buildPathFromChildren = (route: ActivatedRouteSnapshot | ActivatedRouteSnapshot[], baseStr = '') => {
    const nextRoute: ActivatedRouteSnapshot = _isArray(route) ? _head(route) : route;

    if (!nextRoute) {
        return baseStr;
    }

    if (!nextRoute.routeConfig || nextRoute.routeConfig.path === '' || nextRoute.routeConfig.path === '/') {
        return buildPathFromChildren(nextRoute.children, baseStr);
    }

    if (nextRoute.children.length < 1) {
        baseStr += `/${nextRoute.routeConfig.path}`;
        return baseStr;
    }
    baseStr += `/${nextRoute.routeConfig.path}`;
    return buildPathFromChildren(nextRoute.children, baseStr);
};

/**
 * Returns param map for this and all child routes
 *
 * @param {(ActivatedRouteSnapshot | ActivatedRouteSnapshot[])} route
 * @param {string} [params={}]
 * @returns
 */
export const getPathParams = (route: ActivatedRouteSnapshot | ActivatedRouteSnapshot[], params: { [key: string]: any; } = {}) => {
    const nextRoute: ActivatedRouteSnapshot = _isArray(route) ? _head(route) : route;

    if (!nextRoute) {
        return params;
    }

    if (nextRoute.params) {
        return getPathParams(nextRoute.children, Object.assign(params, nextRoute.params));
    }

    return getPathParams(nextRoute.children, params);
};

export const getOTPeriod = (period: number): string => {
    const periodPosition = getPeriod(period).toLowerCase();
    return `${periodPosition} OT`;
};

export const getPeriod = (period: number): string => {
    let shownPeriod = '';

    if (period === 0) {
        shownPeriod = '';
    } else if (period > 6) {
        shownPeriod = period - 3 + 'th';
    } else if (period % 3 === 1) {
        shownPeriod = '1ST';
    } else if (period % 3 === 2) {
        shownPeriod = '2ND';
    } else if (period % 3 === 0) {
        shownPeriod = '3RD';
    }

    return shownPeriod;
};

export const getOTStatus = (game: Game | GameHighlightData): string => {
    const addShootoutLabel = game.hasShootout;
    if (game.periods > 3 && !addShootoutLabel) {
        return getOTStatusFromPeriod(game.periods);
    } else if (addShootoutLabel) {
        return 'SO';
    } else {
        return '';
    }
};

export const getOTStatusFromPeriod = (periods: number): string => {
    if (periods < 4) {
        return;
    }
    const period = periods === 4 ? '' : periods - 3;
    return `${period}OT`;
};

export const getFullPeriodString = (period: number): string => {
    return period <= Constants.maxRegularPeriod ? getPeriod(period).toLowerCase() : getOTStatusFromPeriod(period);
};

export const getGameState = (game: Game | GameHighlightData): GameState => {
    if (game.inProgress || game.isJustFinished) {
        return GameState.live;
    } else if (game.isUpcoming) {
        return GameState.pre;
    } else {
        return GameState.post;
    }
};

export const getGamePeriod = (game: Game): string => {
    // if game is live and game has ended, then status would show either Final OT or Final
    // if game is live, then status would be 1ST, 2ND, 3RD, 1ST OT, 2ND OT, 3RD OT ...
    // passed games would only show OT
    if (game.isJustFinished || !game.inProgress) {
        return 'Final';
    } else if (game.periods > 3 || game.hasShootout) {
        return getOTStatus(game);
    } else {
        return getPeriod(getCurrentPeriod(game));
    }
};

export const getStatus = (game: Game | GameHighlightData): string => {
    // if game is live and game has ended, then status would show either Final OT or Final
    // if game is live, then status would be 1ST, 2ND, 3RD, 1ST OT, 2ND OT, 3RD OT ...
    // passed games would only show OT
    if (game.isJustFinished) {
        return 'Final';
    } else if (game.inProgress && !game.hasShootout && game.periods < 4) {
        // only show period in the first 3 period
        // if game have 3 period and ends with shootout, don't show
        return getPeriod(getCurrentPeriod(game)).toLowerCase();
    } else {
        return '';
    }
};

export const getCurrentPeriod = (game: Game | GameHighlightData): number => {
    return _size(game.periodScore[game.awayTeamId]);
};

export const getStatusForGameHeader = (isUpcoming: boolean, isFinal: boolean, period: number, hasShootout: boolean): string => {
    const isInProgress = !isUpcoming && !isFinal;
    const so = hasShootout ? '/SO' : '';
    if (isInProgress) {
        if (period < 4) {
            return getPeriod(period);
        }
        return getOTStatusFromPeriod(period);
    } else if (isFinal) {
        if (period < 4 || hasShootout) {
            return 'Final' + so;
        }
        return `Final/${getOTStatusFromPeriod(period)}`;
    } else if (isUpcoming) {
        return '';
    }
};

/**
 * Returns param map of all routes up to the current route
 *
 * @param {ActivatedRouteSnapshot} route
 * @returns {{ [paramKey: string]: string }}
 */
export const allRouteParamsFromChild = (route: ActivatedRouteSnapshot): { [paramKey: string]: string; } => {
    return getPathParams(route.root);
};

export const compareDateWithoutTime = (date1: Date, date2: Date) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const cloneAndSetEndDateToEndDay = (dr: DateRangeAsDates): DateRangeAsDates => {
    const clonedDaterange = _cloneDeep(dr);
    if (clonedDaterange) {
        const end = _cloneDeep(clonedDaterange?.end);
        end.setHours(23, 59, 59, 999);
        clonedDaterange.end = end;
    }
    return clonedDaterange;
};

/**
 * Recursively traverses target's parent elements to see if
 * any of their classes include a specific string
 *
 * This is **not** a strict match. Ex: 'example' **would** match 'example-class-name'.
 * For strict matching see `parentsClassListContainClass`
 *
 * @param {HTMLElement} element
 * @param {string} className
 * @returns {boolean}
 */
export const parentsClassListIncludeString = (target: HTMLElement, className: string) => {
    if (target?.classList.toString().includes(className)) {
        return true;
    }

    if (target.parentElement) {
        return parentsClassListIncludeString(target.parentElement, className);
    }

    return false;
};

/**
 * Recursively traverses target's parent elements to see if
 * any of their classes contains a specific class
 *
 * This **is** a strict match. Ex: 'example' would **not** match 'example-class-name'.
 * For loose matching see `parentsClassListIncludeString`
 *
 * @param {HTMLElement} element
 * @param {string} className
 * @returns {boolean}
 */
export const parentsClassListContainClass = (target: HTMLElement, className: string) => {
    if (target?.classList.contains(className)) {
        return true;
    }

    if (target.parentElement) {
        return parentsClassListIncludeString(target.parentElement, className);
    }

    return false;
};
/**
 * Checks to see if array (arr) contains all values (values).
 * Returns true if it does or false if it doesn't.
 *
 * Only works with primitive types.
 *
 * @param {(string | number)[]} arr
 * @param {(string | number)[]} values
 * @returns {boolean}
 */
export const arrayContainsAllValues = (arr: (string | number)[] = [], values: (string | number)[] = []) =>
    arr.length > 0 && values.length > 0 && _filter(arr, v => !_includes(values, v)).length === arr.length - values.length ? true : false;

export const rgbToHex = (rgb: string) => {
    if (!rgb) {
        return '';
    }
    const rgbValue = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    const toHex = v => `0${parseInt(v, 10).toString(16)}`.slice(-2);
    return `#${toHex(rgbValue[1])}${toHex(rgbValue[2])}${toHex(rgbValue[3])}`;
};

// function to get white or SL blue for text color
// for the best contrast with the background color
export const getContrastYIQ = hexcolor => {
    return getContrast(hexcolor) >= 128 ? '001E61' : 'ffffff';
};

export const getContrast = (hexColor: string) => {
    const r = hexColor[0] === '#' ? parseInt(hexColor.substr(1, 2), 16) : parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    return r * 0.299 + g * 0.587 + b * 0.114;
};

export const isTouch = () => {
    return !!(
        (typeof window !== 'undefined' &&
            ('ontouchstart' in window ||
                ((<any>window).DocumentTouch && typeof document !== 'undefined' && document instanceof (<any>window).DocumentTouch))) ||
        !!(typeof navigator !== 'undefined' && navigator.maxTouchPoints)
    );
};

/**
 * Loops through each object property and creates a query param string
 *
 * @param {object} filterParams Object containing all params
 * @param {boolean} queryParamFormat If true use the new format string
 */
export const stringifyParamFilters = (filterParams, queryParamFormat = false): string => {
    const fullQueryString = _reduce(
        filterParams,
        (queryString, value, filterName) => {
            queryString += queryParamFormat ? getQueryParamString(filterName, value, '&') : getFilterString(filterName, value, '&');
            return queryString;
        },
        ''
    );

    /**
     * Strip the first character which will be '&' and concat the '?'
     */
    return fullQueryString.startsWith('&') ? `?${fullQueryString.substring(1)}` : `?${fullQueryString}`;
};

/**
 * Used for V3 endpoints query params. Does not use the array "filters" in query param.
 * Output format: eventType=shooting&eventFilter[]=shot_set-shotfrominnerslot&mps_skaters[]=5v4
 */
export const getQueryParamString = (filterName: string, value: string | [], queryParamSeparator: string): string => {
    if (_isUndefined(value)) {
        return '';
    }
    if (typeof value === 'object') {
        return [...value].map(x => `${queryParamSeparator}${filterName}[]=${x}`).join('');
    } else {
        return `${queryParamSeparator}${filterName}=${value}`;
    }
};

/**
 * Used for V2 endpoints query params. Uses the array "filters" in query param format.
 * Output format: "&filters[playerprimaryposition][]='F'&filters[playerprimaryposition][]='G'"
 */
export const getFilterString = (filterName: string, value: string | [], query: string): string => {
    /**
     * This check used for player comparison endpoint which query param needs to be
     * set this way: playerSet[]=129
     */

    if (_isUndefined(value)) {
        return '';
    }

    if (filterName === 'playerSet') {
        return (<string[]>value).map(x => `${filterName}[]=${x}`).join('');
    }

    if (typeof value !== 'string' && _isArray(value)) {
        return (<string[]>value).map(x => `${query}filters[${filterName}][]=${x}`).join('');
    } else {
        return `${query}filters[${filterName}]=${value}`;
    }
};

export const getTimeInSecondFromFrame = (frameRate: number, frame: number) => {
    return (frame / frameRate) - 0.001;
    const time = frame / frameRate;
    return time;
};

export const getFrameFromSeconds = (frameRate: number, numSeconds: number) => {
    return Math.round((numSeconds) * frameRate);
    return numSeconds * frameRate;
};

export const secondsToMinutes = (inputSeconds: number) => {
    const roundedInputSeconds = Math.round(inputSeconds);
    const minutes = Math.floor(roundedInputSeconds / 60).toFixed(0);
    const seconds = Math.floor(roundedInputSeconds % 60)
        .toFixed(0)
        .padStart(2, '0');
    return { minutes, seconds };
};

export const secondsToMinuteString = (inputSeconds: number): string => {
    const { minutes, seconds } = secondsToMinutes(inputSeconds);
    return minutes + ':' + seconds.substr(-2);
};

export const getVideoFullUrl = (vid: VideoFile): string => {
    // HACK: Playlists endpoint formats fileName/rootURL wrongly.
    return `https://${vid.rootUrl || (vid as any).rootURL}/${vid.fileName || (vid as any).filename}`;
};

const playbackOffsets = Object.freeze({
    faceoff: { pre: 5.0, post: 5.0 },
    default: { pre: 5.0, post: 10.0 },
    shot: { pre: 5.0, post: 8 },
    carry: { pre: 5.0, post: 10.0 },
    dumpout: { pre: 5.0, post: 6 },
    dumpin: { pre: 5.0, post: 10.0 },
    lpr: { pre: 5.0, post: 12.0 },
    controlledbreakout: { pre: 5.0, post: 15.0 },
});

export function getDefaultLeadTrail() {
    return playbackOffsets;
}

// use faceoff location to mirror the faceoff name. for example. for home team, DZ EAST position is OZ EAST for away team.
// instead of mapping one by one, use offset location to get the away team label because they are symmetric to (0,0)
export const getMirroredTeamFaceoffName = (targetFaceoff: FaceoffLocation): FaceoffName => {
    const mirroredFaceoffEvent = faceoffLocationsForGame.find(
        homeFaceoff => homeFaceoff.offsetX === -targetFaceoff.offsetX && homeFaceoff.offsetY === -targetFaceoff.offsetY
    );
    return mirroredFaceoffEvent.name;
};

export const formatPlayerName = (firstName: string, lastName: string): string => {
    if (firstName) {
        // some name in SHL have an empty space, eg "  Joakim"
        return firstName.trim().substring(0, 1) + '. ' + lastName;
    } else {
        return '';
    }
};

export const draw = (
    context: Path,
    xScatterScale: ScaleLinear<number, number>,
    yScatterScale: ScaleLinear<number, number>,
    radius: number
) => {
    // important points  come from historical rink data
    const rinkPath = [
        [0.26, -0.98],
        [0.26, -0.98, [0.99, -0.98, 0.99, -0.54]],
        [0.99, -0.54],
        [0.99, 0],
        [0.99, 0.54, [0.99, 0.98, 0.26, 0.98]],
        [0.26, 0.98],
        [-0.26, 0.98],
        [-0.26, 0.98, [-0.995, 0.98, -0.995, 0.54]],
        [-0.995, 0.54],
        [-0.995, 0],
        [-0.995, -0.54, [-0.995, -0.98, -0.26, -1]],
        [-0.26, -0.98],
        [0.26, -0.98],
    ];
    context.moveTo(xScatterScale(0), yScatterScale(-1));
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < rinkPath.length; i++) {
        const x = rinkPath[i][0];
        const y = rinkPath[i][1];
        const arc = rinkPath[i][2];

        context.lineTo(xScatterScale(x as number), yScatterScale(y as number));
        if (arc) {
            context
                // arcTo starts from where the above lineTo is set (starting point)
                // the first set of params (x1, x2) are the first control point.
                // the second set of params, (x2, y2) are the second / final control point
                .arcTo(xScatterScale(arc[0]), yScatterScale(arc[1]), xScatterScale(arc[2]), yScatterScale(arc[3]), radius);
        }
    }
    return context;
};

export const computeMetricValueByType = (
    metricType: string,
    rawValue: number | string,
    aggregation?: string,
    distance?: number,
    rateFormat?: string
): string => {
    if (rawValue === null || rawValue === undefined) {
        return 'N/A';
    }

    const stringNotConvertibleToNumber = !_isFinite(+rawValue);

    if (stringNotConvertibleToNumber || metricType === 'string') {
        return rawValue as string;
    }

    const metricTypes = {
        total(value) {
            return value.toFixed(0);
        },
        metric(value) {
            if (aggregation === 'sum' || aggregation === 'total') {
                return value.toFixed(0);
            }

            // https://sportlogiq.atlassian.net/browse/HOC-267
            let decimals = distance && distance < 0.1 ? 2 : 1;
            decimals = value > -10 && value < 10 ? 2 : 1;
            return value.toFixed(decimals);
        },
        decimal(value) {
            if (value === 0) {
                return value.toFixed(0);
            }
            const decimals = value > -10 && value < 10 ? 2 : 1;
            return value.toFixed(decimals);
        },
        ratio(value) {
            return value.toFixed(1);
        },
        rate(value) {
            if (rateFormat === 'thousandPoint') {
                return computeFormatDecimal(value);
            } else {
                return `${value && value !== 1 ? (value * 100).toFixed(1) : value * 100}%`;
            }
        },
        duration(value) {
            return Utils.secondsToMin(value);
        },
        percentile(value) {
            return (value * 100).toFixed(2);
        },
    };

    const action = metricTypes[metricType] || metricTypes.decimal;

    return action.call(this, +rawValue);
};

export const computeFormatDecimal = (value: number): string => {
    return `${value && value !== 1 ? '.' + formatNumber(+(value * 1000).toFixed(0), 'en-US', '3.0-0') : value.toFixed(3)}`;
};

export const skoiVSClipLabel = (skatersOnIce: SkaterOnIceV3Clip[], teamShorthandsByTeamId: Record<string, string>): string => {
    const [skatersOnIceTeam1, skatersOnIceTeam2] = skatersOnIce;
    const skaterSituation = `${skatersOnIceTeam1.skatersCount}v${skatersOnIceTeam2.skatersCount}`;
    const team1ShorthandName = `${teamShorthandsByTeamId[skatersOnIceTeam1.teamId]}`;
    const team2ShorthandName = `${teamShorthandsByTeamId[skatersOnIceTeam2.teamId]}`;

    if (skatersOnIceTeam1.skatersCount !== skatersOnIceTeam2.skatersCount) {
        return `${skaterSituation} vs ${team2ShorthandName} `;
    }

    /**
     * Skoi is equal for both teams show both teams
     */
    return `${skaterSituation} - ${team1ShorthandName} vs ${team2ShorthandName} `;
};

export const convertToVideoFileV2 = (video: VidParamsV3Clip): VideoFile => {
    return {
        fileName: video.filename,
        frameRate: video.frameRate,
        height: video.height,
        id: video.vidId,
        rootUrl: video.rootUrl,
        width: video.width,
        period: video.period,
    };
};

@Injectable()
export class Utils extends SharedUtils {
    public static getManpowerShorthand(type: ManpowerSituationType): string {
        switch (type) {
            case ManpowerSituationType.evenStrength:
                return 'ES';

            case ManpowerSituationType.powerPlay:
                return 'PP';

            case ManpowerSituationType.shortHanded:
                return 'SH';

            default:
                return '';
        }
    }

    private static _generatePlayerLabel = (
        players: string[],
        playersDic: Record<string, PlayerModelV3Api | Player>,
        jerseyNumByPlayerId: Record<string, string>,
        clip: PlayerShiftV3ClipApiModel): string => {
        return players.reduce((label, skoiPlayerId) => {
            const player = playersDic[skoiPlayerId];

            if (jerseyNumByPlayerId?.[skoiPlayerId]) {
                const jerseyNum = jerseyNumByPlayerId[skoiPlayerId];
                return `${label}${label ? ', ' : ''}#${jerseyNum} | ${abbrevFirstName(player.firstName, player.lastName)}`;
            }

            if (player instanceof Player && player?.currentTeam?.jerseyNum) {
                return `${label}${label ? ', ' : ''}#${player.currentTeam.jerseyNum} | ${abbrevFirstName(player.firstName, player.lastName)}`;
            }
            return `${label}${label ? ', ' : ''}${abbrevFirstName(player.firstName, player.lastName)}`;
        }, '');
    };

    public static playerClipLabel(
        clip: PlayerShiftV3ClipApiModel,
        playersDic: Record<string, PlayerModelV3Api | Player>,
        opposingPlayerIds: string[],
        jerseyNumByPlayerId?: Record<string, string>,
        listOpposingPlayersFirst = false
    ): string {
        const skoiPlayerIds = _flatten(clip.skatersOnIce.map(s => s.playersOnIce)).filter(skoi => skoi !== null);
        const forSkoiPlayers = skoiPlayerIds.filter((s) => !opposingPlayerIds.includes(s));

        const forPlayerLabel = this._generatePlayerLabel(forSkoiPlayers, playersDic, jerseyNumByPlayerId, clip);
        const opposingPlayerLabel = this._generatePlayerLabel(opposingPlayerIds, playersDic, jerseyNumByPlayerId, clip);

        const vsLabel = opposingPlayerLabel && forPlayerLabel ? ' vs ' : '';

        if (listOpposingPlayersFirst) {
            return `${opposingPlayerLabel}${vsLabel}${forPlayerLabel}`;
        } else {
            return `${forPlayerLabel}${vsLabel}${opposingPlayerLabel}`;
        }
    }

    public static getPlayerNameFromSkatersOnIce(
        clip: PlayerShiftV3ClipApiModel,
        playersDic: Record<string, PlayerModelV3Api | Player>
    ): SkaterOnIcePlayer[] {
        const skoiPlayerIds = _flatten(clip.skatersOnIce.map(s => s.playersOnIce)).filter(skoi => skoi !== null);

        return skoiPlayerIds.map(skoiPlayerId => {
            const player = playersDic[skoiPlayerId];
            return {
                firstName: player.firstName,
                lastName: player.lastName,
                id: skoiPlayerId,
            };
        });
    }

    // game boxscore use "All" instead of long version "All Manpower Situations" which is used for faceoff component
    public static getManpowerDisplay(type: ManpowerSituationType, simpleManPowerDisplay = false): string {
        switch (type) {
            case ManpowerSituationType.evenStrength:
                return 'Even-Strength';

            case ManpowerSituationType.powerPlay:
                return 'Powerplay';

            case ManpowerSituationType.shortHanded:
                return 'Short-Handed';

            default:
                return simpleManPowerDisplay ? 'All' : 'All Manpower Situations';
        }
    }

    public static getDateRangeDisplay(type: GameRangeType, minDate?: string, maxDate?: string, displayCustomText = false): string {
        switch (type) {
            case GameRangeType.custom:
                return displayCustomText ? 'Custom Game Range' : `${minDate} to ${maxDate}`;

            case GameRangeType.last10Games:
                return 'Last 10 Games';

            case GameRangeType.last5Games:
                return 'Last 5 Games';

            case GameRangeType.lastGame:
                return 'Last Game';

            default:
                return 'All Games';
        }
    }

    public static getPageHeaderTabs(
        game: Game,
        router: Router,
        checkForPermission: (permission: UserPermissions) => boolean,
        gameForTeam: boolean,
        reportStatus: string,
        metricsFullyProcessed: boolean,
        linkRendererCallback: (link: LinkItem, game: Game) => void
    ): LinkItem[] {
        const baseUrl = gameForTeam ? ['/games', 'team', game.homeTeamId] : ['/games', 'league'];
        let links: LinkItem[] = [];

        if (checkForPermission(UserPermissions.canViewGameVideo)) {
            links.push({
                href: router.createUrlTree([...baseUrl, game.id, 'video']).toString(),
                label: 'Game Video',
                isActive: router.isActive(router.createUrlTree([...baseUrl, game.id, 'video']).toString(), true),
                clickCallback: (link, ev) => linkRendererCallback(link, game),
            });
        }
        if (checkForPermission(UserPermissions.canViewTeamReport)) {
            links.push({
                href: router.createUrlTree([...baseUrl, game.id, 'report', reportStatus]).toString(),
                label: 'Full Report',
                disabled: !reportStatus,
                isActive: router.isActive(router.createUrlTree([...baseUrl, game.id, 'report', reportStatus]).toString(), true),
                clickCallback: (link, ev) => linkRendererCallback(link, game),
            });
        }
        if (checkForPermission(UserPermissions.canViewGameBoxscore) && metricsFullyProcessed) {
            links.push({
                href: router.createUrlTree([...baseUrl, game.id, 'boxscore']).toString(),
                label: 'Boxscore',
                isActive: router.isActive(router.createUrlTree([...baseUrl, game.id, 'boxscore']).toString(), true),
                clickCallback: (link, ev) => linkRendererCallback(link, game),
            });
        }
        if (!game.isUpcoming && checkForPermission(UserPermissions.canViewGameSummary)) {
            const gameSummaryLink = [
                {
                    href: router.createUrlTree([...baseUrl, game.id, 'summary']).toString(),
                    label: 'Game Summary',
                    isActive: router.isActive(router.createUrlTree([...baseUrl, game.id, 'summary']).toString(), true),
                    clickCallback: (link, ev) => linkRendererCallback(link, game),
                },
            ] as LinkItem[];
            links = gameSummaryLink.concat(links);
        }

        links.push({
            href: router.createUrlTree(['insideedge', 'event-sequences', game.id]).toString(),
            label: 'Event Sequences',
            isActive: false,
            clickCallback: (link, ev) => linkRendererCallback(link, game),
        });

        return links;
    }

    public static getURLParameterMetrics(filters: any): string {
        return `?filters=${JSON.stringify(filters)}`;
    }
}

export const getPlayerToi = (selectedSeasonSummary: SeasonSummary): PlayerToi => {
    const isInMultipleTeams = !!selectedSeasonSummary.overall;
    const target = isInMultipleTeams ? selectedSeasonSummary.overall : selectedSeasonSummary.teams[0];
    let playerToi: PlayerToi;
    if (!target) {
        return;
    }
    if (isInMultipleTeams) {
        playerToi = {
            toiMinutes: secondsToMinuteString(target.toiSeconds),
            toiMinutesES: secondsToMinuteString(target.toiSecondsES),
            toiMinutesPP: secondsToMinuteString(target.toiSecondsPP),
            toiMinutesSH: secondsToMinuteString(target.toiSecondsSH),
            toiSecondsRaw: target.toiSeconds,
            toiSecondsESRaw: target.toiSecondsES,
            toiSecondsPPRaw: target.toiSecondsPP,
            toiSecondsSHRaw: target.toiSecondsSH,
        };
    } else {
        playerToi = {
            toiMinutes: target.toiSeconds,
            toiMinutesES: target.toiSecondsES,
            toiMinutesPP: target.toiSecondsPP,
            toiMinutesSH: target.toiSecondsSH,
            toiSecondsRaw: target.toiSecondsRaw,
            toiSecondsESRaw: target.toiSecondsESRaw,
            toiSecondsPPRaw: target.toiSecondsPPRaw,
            toiSecondsSHRaw: target.toiSecondsSHRaw,
        };
    }
    return playerToi;
};

export const secondsToValidFormattedDuration = (inputSeconds: number): string => {
    const duration = secondsToMinutes(inputSeconds);
    return `${duration.minutes}m ${duration.seconds.substr(-2)}s`;
};

// With shareReplay, for long-lived hot observables, we need to manually set refCount to true
// otherwise the inner ReplaySubject will stay subscribed to the source observable forever
// the generics typing makes it so that it infers/pass down the data type automatically
// that's how it's done in rxjs source code
// https://github.com/ReactiveX/rxjs/blob/master/src/internal/operators/shareReplay.ts#L34
export function shareReplayRefCount<T>(): MonoTypeOperatorFunction<T> {
    return shareReplay({ bufferSize: 1, refCount: true });
}

export function deepDistinctUntilChanged<T>(): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged((od, nd) => _isEqual(od, nd));
}

/**
 * Can be used to transform a component input into an observable that can be subscribed to for changes
 * Code reference: https://craftsmen.nl/angular-lifehack-reactive-component-input-properties/
 * @param target
 * @param key
 */
export const observeProperty = <T, K extends keyof T>(target: T, key: K): Observable<T[K]> => {
    const subject = new BehaviorSubject<T[K]>(target[key]);

    Object.defineProperty(target, key, {
        get(): T[K] {
            return subject.getValue();
        },
        set(newValue: T[K]): void {
            if (newValue !== subject.getValue()) {
                subject.next(newValue);
            }
        },
    });

    return subject.asObservable();
};

export const getPositionPluralString = (position: PlayerPosition): string => {
    return positionPluralDic[position];
};

export const getPositionFromPositionLetter = (positionLetter: string): PlayerPosition => {
    return positionLetterDic[positionLetter] ?? null;
};

export const getTimezoneDate = (game: GameAPI): string => {
    return game.scheduledTime ? game.scheduledTime : game.date;
};

export const gameIsToday = (date: string): boolean => {
    const today = new Date();
    const gameDate = getTodayTimezoneDate(date);
    return compareDateWithoutTime(gameDate, today);
};

export const isDateLaterThanNow = (timezonedDate: Date): boolean => {
    const today = new Date();
    return _gte(timezonedDate, today);
};

export const isDateTodayOrYesterday = (date: Date): boolean => {
    const today = new Date(new Date().toDateString());
    const yesterday = _cloneDeep(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return _gte(date, yesterday) && _lte(date, today);
};

export const getTodayTimezoneDate = (d): Date => {
    const timezoned = new Date(d);
    return new Date(timezoned.valueOf() + timezoned.getTimezoneOffset() * 60000);
};

export const isDaterangeInsideTargetDaterange = (daterange: [Date, Date], targetDaterange: [Date, Date], fullyWithin = true): boolean => {
    if (fullyWithin) {
        return _gte(daterange[0], targetDaterange[0]) && _lte(daterange[1], targetDaterange[1]);
    } else {
        return _gte(daterange[0], targetDaterange[0]) || _lte(daterange[1], targetDaterange[1]);
    }
};

export const encodeAndIsoDate = (date: Date): string => {
    return encodeURI(date?.toISOString());
};

/**
 * Abbreviation for the first name only.
 *
 * @param firstName
 * @param lastName
 */
export const abbrevFirstName = (firstName: string, lastName: string): string => {
    // firstName has no whitespace nor dash, but dots
    if (!/\s|-/.test(firstName) && firstName.includes('.')) {
        return `${firstName} ${lastName}`;
    }
    // firstName abbreviation
    const abbreviatedFirstName = firstName
        .split(/\s|-/) // split on space or dash
        .filter(name => name) // make sure there's no empty items
        .map(name => name.slice(0, 1).toUpperCase()) // uppercase first letter
        .join('.') // join as string adding a dot to indicate the abbreviation
        .concat('.'); // adds the last dot as abbreviation
    return `${abbreviatedFirstName} ${lastName}`;
};

export const normalizeValue = (input: string): string => {
    return _deburr(input).toLowerCase();
};

export const subtractDaysFromDate = (date: Date, days: number): Date => {
    const d = _cloneDeep(date);
    d.setDate(d.getDate() - days);
    return d;
};

export function isOneActionOngoing$<T extends { type: string; }>(actions$: Actions, actionlist: T[]): Observable<boolean> {
    const allActions$ = actionlist.map(action => {
        const loading$ = actions$.pipe(ofActionDispatched(action), mapTo(true));
        const notLoading$ = actions$.pipe(
            ofActionCompleted(action),
            filter((a: ActionCompletion<T>) => !a.result.canceled),
            mapTo(false)
        );
        return race(loading$, notLoading$).pipe(take(1), repeat(), startWith(false), shareReplayRefCount());
    });

    return combineLatest([...allActions$]).pipe(
        map(values => _some(values)),
        distinctUntilChanged()
    );
}

export const getStatMetricDefinition = (item: AdvancedStatsColumnType): string => {
    if (!item) {
        return new Date().getTime().toString();
    }

    return item.metricKey || `${(item as Metric).baseMetric || item.label || ''}__${(item as Metric).type || ''}`;
};

export const getStatIdentityValue = (element: AdvancedStatDataForTable): string => {
    return (element.identity as PlayerModelAPI)?.lastName || (element.identity as Team).displayName;
};

export const getTeamFullname = (location: string, name: string): string => {
    return `${location} ${name}`;
};

export const calculateStickyHeadersHeight = (): number => {
    return _reduce(
        document.querySelectorAll('[data-sticky-header]'),
        (acc, header) => {
            acc += header.clientHeight;
            return acc;
        },
        0
    );
};

export const getGameAwareTimer$ = (timer$: Observable<number>, game: Game): Observable<number> => {
    if (!game.inProgress) {
        return timer$.pipe(take(1));
    } else {
        return timer$;
    }
};

export function activeElementIsTypeText(): boolean {
    const tagName = document.activeElement?.tagName?.toLowerCase();
    if (tagName) {
        return ['input', 'textarea'].includes(tagName);
    }

    return false;
}

export function getPlayerForNoteFromPlayer(player: Player): NoteTagPlayer | undefined {
    return player ? {
        id: player.id,
        name: `${player.firstName} ${player.lastName}`,
        picturesrc: player.pictureSrc
    } : undefined;
}
