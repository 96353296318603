/* eslint-disable @typescript-eslint/no-explicit-any */
import { throwError as observableThrowError, Observable, timer, Subject, fromEvent } from 'rxjs';
import { catchError, map, filter, mergeMap, retryWhen, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../environments/constants';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpResponse, HttpSentEvent, HttpParams, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { NukeService } from './nuke.service';
import { cloneDeep as _cloneDeep, isUndefined as _isUndefined } from 'lodash';
import { Store } from '@ngxs/store';
import { UserState } from './user/user.state';
import { AppRoute, VALID_UNAUTHENTICATED_ROUTES } from '@sportlogiq/app-routing.models';

export interface RequestOptions {
    body?: string;
    headers?: Record<string, string>;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
    url?: string;
    method?: RequestMethod;
    observe?: 'response';
}

export enum RequestMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Options = 'OPTIONS',
    Patch = 'PATCH',
    Head = 'HEAD',
}

export interface ICEError {
    name: string;
    status: number;
    message: string;
}

@Injectable()
export class CustomHttp {
    private requestOptions: RequestOptions;
    private headers: Record<string, string>;

    private _lastRequest$ = new Subject<void>();
    public token: string;
    private _userExists: boolean;

    constructor(
        private _store: Store,
        private http: HttpClient,
        private _nukeService: NukeService,
        private router: Router,
        private _matDialog: MatDialog
    ) {
        this.requestOptions = {
            withCredentials: true,
        };

        this._store.select(UserState.user).subscribe(user => (this._userExists = !!user));

        this._lastRequest$
            .pipe(
                switchMap(() => {
                    // if it's been more than this time since the last request was made to the api, use the nuke service to clear the cache and reload some data
                    // I've set it higher than the current cookie expiration because we don't want this mechanism to force the user being logged in forever
                    // Once SSO is pushed, this won't really be an issue
                    const delay = 1000 * 60 * 60 * 3;
                    return timer(delay);
                }),
                switchMap(() => {
                    // After the delay has passed, we want to wait until the user is active to actually refresh the data
                    return fromEvent(document, 'mousemove').pipe(take(1));
                })
            )
            .subscribe(() => {
                this._nukeService.nuke$.next({ reloadData: true });
            });
    }

    private addEndpointRoot(options: RequestOptions, apiRoot: string) {
        // const endpointRoot = Constants.api_root;
        options.url = options.url.replace(/^/, apiRoot);

        return options;
    }

    // eslint-disable-next-line complexity
    private requestHelper(requestArgs: RequestOptions, apiRoot: string, additionalOptions?: RequestOptions): Observable<HttpResponse<any>> {
        const options = requestArgs;
        this.requestOptions = Object.assign(this.requestOptions, options);
        const unProtectedUrls = [`/login`, '/logout', `/forgotpassword`, '/confirmforgotpassword', '/account', '/users/authorization'];
        const parsedUrl = requestArgs.url.split('?')[0];

        if (!unProtectedUrls.includes(parsedUrl)) {
            if (!this._userExists) {
                if (this._matDialog.openDialogs.length > 0) {
                    this._matDialog.closeAll();
                    return this._matDialog.afterAllClosed.pipe(
                        mergeMap(noop => {
                            this._nukeService.nuke$.next({ notify: true });
                            this.router.navigate([`/${AppRoute.LOGIN}`]);
                            return observableThrowError(new Response('An error occured, please try again.'));
                        })
                    );
                }
                this._nukeService.nuke$.next({ notify: true });
                this.router.navigate([`/${AppRoute.LOGIN}`]);
                return observableThrowError(new Response('An error occured, please try again.'));
            }
        }

        if (additionalOptions) {
            this.requestOptions = Object.assign(this.requestOptions, additionalOptions);
        }

        this.addEndpointRoot(this.requestOptions, apiRoot);
        return this._request(_cloneDeep(this.requestOptions), apiRoot);
    }

    private responseHandler(response: HttpResponse<any> | HttpSentEvent): HttpResponse<any> {
        const resp: HttpResponse<any> = response as any;

        this.requestOptions = {
            withCredentials: true,
            headers: this.headers,
        };
        return resp;
    }

    private isPlainText(error: HttpErrorResponse): boolean {
        return error.headers.get('Content-Type')?.toLowerCase() === 'text/plain';
    }

    // eslint-disable-next-line complexity
    private handleError(error: HttpErrorResponse) {
        const errMsg = error.message || 'Server error';
        console.error(errMsg); // log to console instead

        const message = this.isPlainText(error) && typeof error?.error === 'string' ? error.error : errMsg;
        // normalize error using iCE error type
        const normalizedError: ICEError = {
            name: error?.statusText,
            status: error.status,
            message,
        };

        switch (error.status) {
            case 403:
            case 503:
            case 0:
            case 400:
                return observableThrowError(() => error);
            case 401:
                if (!this.routeIsValidForNonAuthenticatedUser(this.router.url)) {
                    this._matDialog.closeAll();
                    this._nukeService.nuke$.next({ notify: true });
                    this.router.navigate([`/${AppRoute.LOGIN}`]);
                }
                return observableThrowError(() => '');
            case 409:
                return observableThrowError(() => 'data integrity error: updateOn does not match - refresh your browser');
            case 410:
                return observableThrowError(() => normalizedError);
            default:
                return observableThrowError(() => errMsg);
        }
    }

    private routeIsValidForNonAuthenticatedUser(url: string): boolean {
        return VALID_UNAUTHENTICATED_ROUTES.includes(url);
    }

    private _request(requestOptions: RequestOptions, apiRoot: string): Observable<HttpResponse<any> | any> {
        const maxAttempts = 2;
        const errorStatusesToRetry = [0, 503];
        this._lastRequest$.next();
        /**
         * Need to handle new V3 endpoints which require content type json when performing a POST method
         */
        if (_isUndefined(requestOptions.headers)) {
            requestOptions.headers = {};
        }
        if ((apiRoot === Constants.api_root_v3 || apiRoot === Constants.user_api_root) && requestOptions.method !== RequestMethod.Get) {
            requestOptions.headers = Object.assign(requestOptions.headers, { 'Content-Type': 'application/json' });
        } else {
            requestOptions.headers = Object.assign(requestOptions.headers, { 'Content-Type': 'text/plain' });
        }

        return this.http.request(requestOptions.method, requestOptions.url, requestOptions).pipe(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            filter(res => !res || (<HttpSentEvent>res).type !== HttpEventType.Sent),
            map(this.responseHandler.bind(this)),
            catchError(err => this.handleError(err)),
            retryWhen(attempts => {
                return attempts.pipe(
                    mergeMap((error, i) => {
                        if (!errorStatusesToRetry.includes(error.status)) {
                            throw error;
                        }

                        const retryAttempt = i + 1;

                        if (
                            errorStatusesToRetry.includes(error.status) &&
                            requestOptions.method === RequestMethod.Get &&
                            retryAttempt === maxAttempts
                        ) {
                            throw error;
                        }

                        return timer(retryAttempt * 2000);
                    })
                );
            })
        );
    }

    public get(url: string, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: '', method: RequestMethod.Get, url: url }, apiRoot, options);
    }

    public post(url: string, body: any, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: body, method: RequestMethod.Post, url: url }, apiRoot, options);
    }

    public put(url: string, body: any, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: body, method: RequestMethod.Put, url: url }, apiRoot, options);
    }

    public delete(url: string, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: '', method: RequestMethod.Delete, url: url }, apiRoot, options);
    }

    public patch(url: string, body: any, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: body, method: RequestMethod.Patch, url: url }, apiRoot, options);
    }

    public head(url: string, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: '', method: RequestMethod.Head, url: url }, apiRoot, options);
    }

    public options(url: string, options?: RequestOptions, apiRoot = Constants.api_root): Observable<any> {
        return this.requestHelper({ body: '', method: RequestMethod.Options, url: url }, apiRoot, options);
    }
}
