import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, tap, map, take } from 'rxjs/operators';
import { Empty } from '@sportlogiq/main/shared/sl-types';
import { CustomHttp } from '@sportlogiq/core';
import {
    head as _head,
    last as _last,
    size as _size,
    keyBy as _keyBy,
    merge as _merge,
    map as _map,
    isEmpty as _isEmpty,
    pullAt as _pullAt,
    findIndex as _findIndex,
    find as _find
} from 'lodash';

/**
 * The shape of an individual glossary item.
 *
 * Glossary items will be used to help demystify terms and behaviour with
 * a heavy reliance on acronyms and sport-specific jargon.
 *
 * @export
 * @interface GlossaryItem
 */
export interface GlossaryItem {
    /**
     * The unique identifier of the glossary item.
     *
     * Normally this will be the id returned from the backend
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    id: string;
    /**
     * The title of the glossary item.
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    title: string;
    /**
     * The slightly more verbose version of the title.
     *
     * This will generally contain broken down acronyms and be
     * used as a sub-title underneath the `title` property.
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    descriptiveTitle: string;
    /**
     * The full-text description of the glossary item.
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    description: string;
}

/**
 * LinkedGlossaryItems augment a GlossaryItem with leftTitle and rightTitle properties.
 * These are used to build a "linked list" of sorts so that correct next and previous values
 * can be built in the view.
 *
 * They reference the titles because of an API limitations which can cause ID collisions.
 * The title property of a GlossaryItem should be unique and safe to use as a lookup reference.
 *
 * @export
 * @interface LinkedGlossaryItem
 * @extends {GlossaryItem}
 */
export interface LinkedGlossaryItem extends GlossaryItem {
    /**
     * Title of the item to the left (previous) of this one
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    leftTitle: string | undefined;
    /**
     * Title of the item to the right (next) of this one
     *
     * @type {string}
     * @memberof GlossaryItem
     */
    rightTitle: string | undefined;
}

@Injectable()
export class GlossaryService implements OnDestroy {
    private _relevantGlossaryItems: LinkedGlossaryItem[] = [];
    private _activeGlossaryItem$ = new BehaviorSubject<LinkedGlossaryItem>(undefined);
    private _relevantGlossaryItems$ = new BehaviorSubject<LinkedGlossaryItem[]>([]);
    private _glossaryActive$ = new BehaviorSubject<boolean>(false);
    private _glossaryCanBeActive$ = new BehaviorSubject<boolean>(false);
    private _glossaryActiveState = false;
    private _glossaryDefinitions = {};
    private _killSubs$ = new Subject<boolean>();

    constructor(private _http: CustomHttp) {
        this.glossaryActive$
            .pipe(
                takeUntil(this._killSubs$),
                // As a side-effect, update the internal glossaryActiveState
                tap(value => (this._glossaryActiveState = value)),
                tap(value => (!value ? this._activeGlossaryItem$.next({} as Empty<LinkedGlossaryItem>) : undefined))
            )
            .subscribe();

        // Make sure the _glossaryActive$ replay subject has a buffered value of value (closed)
        this._glossaryActive$.next(false);

        // Get all definitions when ther service is first instantiated
        this.getGlossaryDefinitions()
            .pipe(take(1))
            .subscribe();
    }

    /**
     * Observable which contains the currently active glossary item
     *
     * @readonly
     * @memberof GlossaryService
     */
    get activeGlossaryItem$() {
        return this._activeGlossaryItem$.asObservable();
    }

    /**
     * Observable which is used to check whether the glossary is active or not
     *
     * @readonly
     * @memberof GlossaryService
     */
    get glossaryActive$() {
        return this._glossaryActive$.asObservable();
    }

    /**
     * Observable which you can use to determine if the glossary _should_ be visible
     *
     * @readonly
     * @memberof GlossaryService
     */
    get glossaryCanBeActive$() {
        // TODO: Include check for having the definitions loaded
        return this._glossaryCanBeActive$.asObservable();
    }

    /**
     * All cached glossary definitions keyed by their 'title' property
     *
     * @readonly
     * @memberof GlossaryService
     */
    get glossaryDefinitions() {
        return this._glossaryDefinitions;
    }

    /**
     * An array of all relevant glossary items.
     * Relevant glossary items are built based on the `glossaryItem` directive in the current view
     *
     * @readonly
     * @memberof GlossaryService
     */
    get relevantGlossaryItems$() {
        return this._relevantGlossaryItems$;
    }

    ngOnDestroy() {
        this._killSubs$.next(true);
    }

    /**
     * Activates the glossary panel
     *
     * @memberof GlossaryService
     */
    activate() {
        this._glossaryActive$.next(true);
    }

    /**
     * Adds the GlossaryItem to the relevantGlossaryItems$ collection
     * This converts a GlossaryItem -> LinkedGlossaryItem
     *
     * @param {GlossaryItem} item
     * @returns
     * @memberof GlossaryService
     */
    addToRelevantItems(item: GlossaryItem) {
        if (!item) {
            return;
        }

        const relevantGlossaryItemsSize = _size(this._relevantGlossaryItems);

        if (relevantGlossaryItemsSize === 0) {
            const relevantItem = {
                ...item,
                leftTitle: undefined,
                rightTitle: undefined,
            };
            this._relevantGlossaryItems.push(relevantItem);
        }

        if (relevantGlossaryItemsSize > 0) {
            const firstItem = _head(this._relevantGlossaryItems);
            const previousItem = _last(this._relevantGlossaryItems);

            const newRelevantItem = {
                ...item,
                leftTitle: previousItem.title,
                rightTitle: firstItem.title,
            };

            const updatedFirstItem = {
                ...firstItem,
                leftTitle: newRelevantItem.title,
            };

            const updatedPreviousItem = {
                ...previousItem,
                rightTitle: newRelevantItem.title,
            };

            this._relevantGlossaryItems[0] = updatedFirstItem;
            this._relevantGlossaryItems[relevantGlossaryItemsSize - 1] = updatedPreviousItem;
            this._relevantGlossaryItems.push(newRelevantItem);
        }

        this._relevantGlossaryItems$.next(this._relevantGlossaryItems);
    }

    /**
     * Adds a collection of GlossaryItems to the cached glossaryDefinitions object.
     *
     * @param {GlossaryItem[]} items
     * @memberof GlossaryService
     */
    addToGlossaryDefinitions(items: GlossaryItem[]) {
        this._glossaryDefinitions = _merge(this._glossaryDefinitions, _keyBy(items, 'title'));
    }

    /**
     * Builds GlossaryItem[] out of definitions returned from the API
     *
     * @param {any[]} definitions
     * @returns {GlossaryItem[]}
     * @memberof GlossaryService
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildGlossaryItems(definitions: any[]): GlossaryItem[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const glossaryItems = _map<any, GlossaryItem>(definitions, (metric, index, arr) => {
            return {
                id: metric.id,
                description: metric.definition,
                descriptiveTitle: metric.title,
                title: metric.label,
            };
        });
        this.addToGlossaryDefinitions(glossaryItems);
        return glossaryItems || [];
    }

    /**
     * Method to update the glossaryCanBeActive$ stream
     *
     * @param {boolean} [state=true]
     * @memberof GlossaryService
     */
    canOpenGlossary(state = true) {
        // If the glossary is active and the new state is false
        // be sure to minimize the window before hiding the button
        if (this._glossaryActiveState && !state) {
            this.minimize();
        }

        this._glossaryCanBeActive$.next(state);
    }

    /**
     * Gets the glossary definitions from the API and caches them in the glossaryDefinitions object.
     *
     * @returns
     * @memberof GlossaryService
     */
    getGlossaryDefinitions() {
        return this._http.get('/glossary').pipe(map(this.buildGlossaryItems.bind(this)));
    }

    /**
     * Changes the active glossary item to the one requests
     * Lookup is done by the `title` property on a LinkedGlossaryItem
     *
     * @param {string} itemLabel
     * @returns
     * @memberof GlossaryService
     */
    goToItem(itemLabel: string) {
        if (!this._glossaryActive$.value) {
            console.warn('Attempted to go to an undefined glossary item');
            return;
        }

        const item = _find(this._relevantGlossaryItems$.value, { title: itemLabel });
        this._activeGlossaryItem$.next(item);
    }

    /**
     * Minimizes the glossary panel
     *
     * @memberof GlossaryService
     */
    minimize() {
        this._glossaryActive$.next(false);
    }

    /**
     * Removes the LinkedGlossaryItem from the relevantGlossaryItems collection
     * This gets called when the glossaryItem directive is removed from the view
     *
     * When a glossary item is removed from the view, this method properly updates the other
     * relevant glossary items `leftTitle` & `rightTitle` properties with their new references.
     *
     * @param {GlossaryItem} item
     * @returns
     * @memberof GlossaryService
     */
    // eslint-disable-next-line complexity
    removeFromRelevantItems(item: GlossaryItem) {
        if (!item || _isEmpty(this._relevantGlossaryItems)) {
            return;
        }

        const sizeOfRelevantItems = _size(this._relevantGlossaryItems);

        if (sizeOfRelevantItems === 1 && this._relevantGlossaryItems[0].title === item.title) {
            _pullAt(this._relevantGlossaryItems, 0);
            this._relevantGlossaryItems$.next(this._relevantGlossaryItems);
            return;
        }

        const indexOfItem = _findIndex(this._relevantGlossaryItems, i => i.title === item.title);

        if (indexOfItem < 0) {
            return;
        }

        if (indexOfItem === 0) {
            const nextGlossaryItem = this._relevantGlossaryItems[1];
            const lastItem = _last(this._relevantGlossaryItems);

            const newLastItem = {
                ...lastItem,
                rightTitle: nextGlossaryItem.title,
            };

            const newNextGlossaryItem = {
                ...nextGlossaryItem,
                leftTitle: lastItem.title,
            };

            this._relevantGlossaryItems[1] = newNextGlossaryItem;
            this._relevantGlossaryItems[sizeOfRelevantItems - 1] = newLastItem;
            _pullAt(this._relevantGlossaryItems, 0);
            this._relevantGlossaryItems$.next(this._relevantGlossaryItems);
            return;
        }

        if (indexOfItem === sizeOfRelevantItems - 1) {
            const previousGlossaryItem = this._relevantGlossaryItems[sizeOfRelevantItems - 2];
            const firstItem = _head(this._relevantGlossaryItems);

            const newFirstItem = {
                ...firstItem,
                leftTitle: previousGlossaryItem.title,
            };

            const newPreviousGlossaryItem = {
                ...previousGlossaryItem,
                rightTitle: firstItem.title,
            };

            this._relevantGlossaryItems[sizeOfRelevantItems - 2] = newPreviousGlossaryItem;
            this._relevantGlossaryItems[0] = newFirstItem;
            _pullAt(this._relevantGlossaryItems, sizeOfRelevantItems - 1);
            this._relevantGlossaryItems$.next(this._relevantGlossaryItems);
            return;
        }

        const previousItem = this._relevantGlossaryItems[indexOfItem - 1];
        const nextItem = this._relevantGlossaryItems[indexOfItem + 1];

        const newPreviousItem = {
            ...previousItem,
            rightTitle: nextItem.title,
        };

        const newNextItem = {
            ...nextItem,
            leftTitle: previousItem.title,
        };

        this._relevantGlossaryItems[indexOfItem - 1] = newPreviousItem;
        this._relevantGlossaryItems[indexOfItem + 1] = newNextItem;
        _pullAt(this._relevantGlossaryItems, indexOfItem);

        this._relevantGlossaryItems$.next(this._relevantGlossaryItems);
    }

    /**
     * Toggles the state of the glossary panel
     *
     * @memberof GlossaryService
     */
    toggleGlossary() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this._glossaryActiveState ? this.minimize() : this.activate();
    }
}
