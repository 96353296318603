import { EventSearchesBodyRequest, SortingOrder } from './event-searches.types';

export class FetchEventSearchesFilters {
    public static readonly type = '[Event Searches] Fetch Event Searches Filters';
}

export class FetchEventSearches {
    public static readonly type = '[Event Searches] Fetch Event Searches';
}

export class DeleteEventSearches {
    public static readonly type = '[Event Searches] Delete Event Searches';
    constructor(public ids: string[]) {}
}

export class SaveEventSearch {
    public static readonly type = '[Event Searches] Save Saved Search';
    constructor(public payload: EventSearchesBodyRequest) {}
}

export class EditEventSearch {
    public static readonly type = '[Event Searches] Edit Saved Search';
    constructor(public id: string, public payload: EventSearchesBodyRequest) {}
}

export class EventSearchesSorting {
    public static readonly type = '[Event Searches] Set Sorting';
    constructor(public order: SortingOrder) {}
}
export class FilterEventSearches {
    public static readonly type = '[Event Searches] Searching For Event Searches';
    constructor(public searchValue: string) {}
}
