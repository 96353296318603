import { Injectable } from '@angular/core';
import { CanLoad, UrlSegment, Route } from '@angular/router';
import { UserPermissions } from '@sportlogiq/shared';
import { UserService } from '@sportlogiq/core/user.service';

@Injectable()
export class CustomReportsGuard implements CanLoad {
    constructor(private _userService: UserService) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        if (
            this._userService.checkForPermission(UserPermissions.canViewNewFeatures) &&
            this._userService.checkForPermission(UserPermissions.canViewCustomReports)
        ) {
            return true;
        }

        return false;
    }
}
