import { filter, takeUntil } from 'rxjs/operators';
import { MessageHandlerService } from 'app/core';
import { Observable, of, Subject } from 'rxjs';
import { OnDestroy, OnInit, Component } from '@angular/core';
import { Router, NavigationCancel, ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { Store } from '@ngxs/store';
import { UserState } from '@sportlogiq/core/user/user.state';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { shareReplayRefCount } from '@sportlogiq/shared';
import { isUndefined as _isUndefined } from 'lodash';
import { ContextualMessage, ForgotPasswordNavigationState } from './login.model';
import { AppRoute } from '@sportlogiq/app-routing.models';

export enum LoginPageSectionsEnum {
    Login = 'Login',
    ForgotPassword = 'ForgotPassword',
    CreateNewPassword = 'CreateNewPassword',
    ForceResetEmailSent = 'ForceResetEmailSent',
}

@Component({
    selector: 'sl-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
    public animatePage = true;
    public activatedSection: LoginPageSectionsEnum;
    public loginPageSections = LoginPageSectionsEnum;

    public errorMessage: string | number;
    public videoMessage: boolean;
    public appRoute = AppRoute;

    private _destroy$: Subject<boolean>;

    public loading: boolean;

    public contextualMessage: ContextualMessage;

    public loginForm = new FormGroup({});

    public emailControl = new FormControl('', [Validators.required, Validators.email]);
    public forgotPasswordForm = new FormGroup({
        email: this.emailControl,
    });

    private _currentNavigationState$: Observable<ForgotPasswordNavigationState>;

    constructor(
        public router: Router,
        public authService: AuthService,
        private _activatedRoute: ActivatedRoute,
        private _messageHandler: MessageHandlerService,
        private _store: Store
    ) {
        this._destroy$ = new Subject();

        this._currentNavigationState$ = of(this.router.getCurrentNavigation()?.extras.state as ForgotPasswordNavigationState).pipe(
            shareReplayRefCount()
        );

        this._activatedRoute.data.pipe(takeUntil(this._destroy$)).subscribe(d => {
            this.activatedSection = d.section;
        });

        if (this._store.selectSnapshot(UserState.user)) {
            this.router.navigate(['/']);
        }

        this.router.events.pipe(takeUntil(this._destroy$)).subscribe(e => {
            if (e instanceof NavigationCancel) {
                this.authService.redirectUrl = e.url;
                if (e.url.indexOf('/videos/') >= 0) {
                    this.videoMessage = true;
                }
            }
        });
    }

    ngOnInit() {
        // Delete loading spinner
        const loader = document.getElementsByClassName('app-loader')[0];
        if (loader) {
            loader.remove();
        }

        this._currentNavigationState$
            .pipe(
                filter(state => !_isUndefined(state)),
                takeUntil(this._destroy$)
            )
            .subscribe(state => {
                this.errorMessage = state.message;
                this.contextualMessage = {
                    type: 'error',
                    message: state.message,
                    status: state.status,
                };
                if (this.activatedSection === this.loginPageSections.ForgotPassword) {
                    this.emailControl.setValue(state.email);
                    this.emailControl.updateValueAndValidity();
                }
            });
    }

    public login(event: Event, username: string, password: string) {
        this.loading = true;
        this.authService.login(event, username, password).subscribe(
            response => {
                const r = response;
                if (r?.changePassword) {
                    this.loading = false;
                    this.contextualMessage = {
                        type: 'success',
                        message: `Your password must be changed. Instructions has been sent to ${r.email}.`,
                    };
                    this.activatedSection = LoginPageSectionsEnum.ForceResetEmailSent;
                }
            },
            err => {
                const e = err ? err.error : err;
                this.loading = false;
                if (e?.changePassword) {
                    this.contextualMessage = {
                        type: 'success',
                        message: `Your password must be changed. Instructions has been sent to ${e.email}.`,
                    };
                    this.activatedSection = LoginPageSectionsEnum.ForceResetEmailSent;
                } else {
                    this.contextualMessage = {
                        type: 'error',
                        message: 'An error occured. Please try again.',
                    };
                }
            }
        );
    }

    public forgotPassword(event: Event, email: string) {
        event.preventDefault();
        this.loading = true;
        this.authService.forgotPassword(email).subscribe(
            r => {
                this.contextualMessage = {
                    type: 'success',
                    message: `An email has been sent to ${email} to reset your password.`,
                };
                this.loading = false;
            },
            e => {
                this.contextualMessage = {
                    type: 'error',
                    message: 'An error occured. Please try again.',
                };
                this._messageHandler.emitError('An error occured. Please try again later.');
                this.loading = false;
            }
        );
    }

    public passwordChangedSuccessfully(data) {
        this.authService.redirectUrl = '/';
        this.login(undefined, data.user, data.password);
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }
}
