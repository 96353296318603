/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpRequest } from '@angular/common/http';
import { CustomHttp } from '@sportlogiq/core';
import { faceoffLocationsForGame } from '@sportlogiq/main/core/faceoffs/faceoff.types';
import { PlayerEvent, Game } from '@sportlogiq/main';
import { of } from 'rxjs';
import {
    filter as _filter,
    forEach as _forEach,
    capitalize as _capitalize,
    groupBy as _groupBy,
    size as _size,
    last as _last,
    maxBy as _maxBy,
    get as _get
} from 'lodash';

function isWithinGameTime(gameTime, timePassedSec): boolean {
    return gameTime <= timePassedSec;
}

function getElapsedTime(clockByID: Record<string, number>, gameId: string) {
    const time = clockByID[gameId];
    if (!time) {
        clockByID[gameId] = new Date().valueOf();
        return 0;
    }
    return Math.round((new Date().valueOf() - time) / 1000) * 30;
}

export const simulateLiveGame = (game: Game, memory: Record<string, any>, preRequestData: any) => {
    const timeElapsed = getElapsedTime(memory, game.id);

    const events = memory[`playerevents-${game.id}`];
    const filteredEvents: PlayerEvent[] = _filter(events, e => isWithinGameTime(e.gameTime, timeElapsed));
    const maxPeriod = _get(
        _maxBy(filteredEvents, ev => ev.period),
        'period',
        1
    );

    game.inProgress = true;
    game.date = new Date().toISOString().split('T')[0];
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    game.periods = (<any>game).periods.slice(0, maxPeriod);
    game.periodScore = Object.keys(game.periodScore).reduce((acc, id: string) => {
        if (game.periodScore[id]) {
            acc[id] = game.periodScore[id].slice(0, maxPeriod);
        } else {
            acc[id] = [0];
        }
        return acc;
    }, {} as any);
};

export const simulateLivePlayerEvents = (data, memory: Record<string, number>) => {
    if (!memory[`playerevents-${data.gameId}`]) {
        memory[`playerevents-${data.gameId}`] = data.events;
    }
    const events = memory[`playerevents-${data.gameId}`] || data.events;
    const timeElapsed = getElapsedTime(memory, data.gameId);

    data.events = events.filter(e => isWithinGameTime(e.gameTime, timeElapsed));
    return data;
};

export const makeLastGameLive = data => {
    const passGames = data.games.filter(x => !x.isUpcoming).reverse();
    const games = [passGames[0], passGames[1]];
    games.forEach((game: Game, index) => {
        game.inProgress = true;
        game.periods = 1;
        game.date = new Date().toISOString().split('T')[0];
        game.periodScore = Object.keys(game.periodScore).reduce(
            (acc, id: string) => {
                if (game.periodScore[id]) {
                    acc[id] = game.periodScore[id].slice(0, game.periods);
                } else {
                    acc[id] = [0];
                }
                return acc;
            },
            {} as {
                [id: number]: number[];
            }[]
        );

        data.games[index] = game;
    });
    return data;
};

export const makeFaceoffsLiveResponse = (data, memory: Record<string, number>, preRequestData: any) => {
    const timeElapsed = getElapsedTime(memory, data.gameId);
    const faceoffEvents = memory[`faceoff-${data.gameId}`] || preRequestData.events;
    const events: PlayerEvent[] = _filter(faceoffEvents, e => isWithinGameTime(e.gameTime, timeElapsed));
    const faceoffTypes = faceoffLocationsForGame;

    _forEach(data, datum => {
        const teamPlayerFilteredEvents = events.filter(e => datum.playerid === e.playerId || datum.teamid === e.teamId);
        // eslint-disable-next-line complexity
        _forEach(faceoffTypes, fo => {
            let eventsOnThisLocation = [] as PlayerEvent[];
            let northSouthValue = '';
            const eastWestValue = fo.key
                .toLowerCase()
                .replace('oz', '')
                .replace('dz', '')
                .replace('nz', '')
                .replace('north', '')
                .replace('south', '');

            eventsOnThisLocation = teamPlayerFilteredEvents.filter(
                e => fo.key.includes(e.zone) && e.flags.some(fl => fl.includes(eastWestValue))
            );
            if (fo.key.includes('nz')) {
                northSouthValue = fo.name.split(' ')[1].toLowerCase();
                eventsOnThisLocation = eventsOnThisLocation.filter(e => e.playSection.includes(northSouthValue));
            }

            const outcome = [
                eventsOnThisLocation.filter(e => e.outcome === 'successful').length,
                eventsOnThisLocation.filter(e => e.outcome === 'failed').length,
            ];

            const zoneName = fo.name.split(' ')[0];
            if (zoneName.toLowerCase().includes('total')) {
                return;
            }

            const key = `${zoneName}${northSouthValue ? ' ' + _capitalize(northSouthValue) : ''} ${_capitalize(eastWestValue)} Face Off`;
            datum[`${key} Won`] = outcome[0];
            datum[`${key} Taken`] = outcome[0] + outcome[1];
            datum[`${key} Win Percentage`] = outcome[0] && outcome[1] ? outcome[0] / outcome[1] : 0;
        });
        const zoneSeparatedEvents = _groupBy(teamPlayerFilteredEvents, ev => ev.zone) as Record<'nz' | 'oz' | 'dz', PlayerEvent[]>;

        const _getSuccessful = (evs: PlayerEvent[]) => _filter(evs, ev => ev.outcome === 'successful').length;
        const nzWonCount = _getSuccessful(zoneSeparatedEvents.nz);
        datum['NZ Face-Off Wins'] = nzWonCount;
        datum['NZ Face Off Win Percentage'] = nzWonCount ? nzWonCount / _size(zoneSeparatedEvents.nz) : 0;
        datum['Total NZ Face-Off Taken'] = _size(zoneSeparatedEvents.nz);

        const ozWonCount = _getSuccessful(zoneSeparatedEvents.oz);
        datum['OZ Face-Off Wins'] = ozWonCount;
        datum['OZ Face Off Win Percentage'] = ozWonCount ? ozWonCount / _size(zoneSeparatedEvents.oz) : 0;
        datum['Total OZ Face-Off Taken'] = _size(zoneSeparatedEvents.oz);

        const dzWonCount = _getSuccessful(zoneSeparatedEvents.dz);
        datum['DZ Face-Off Wins'] = dzWonCount;
        datum['DZ Face Off Win Percentage'] = dzWonCount ? dzWonCount / _size(zoneSeparatedEvents.dz) : 0;
        datum['Total DZ Face-Off Taken'] = _size(zoneSeparatedEvents.dz);

        const totalWonCount = _getSuccessful(teamPlayerFilteredEvents);
        datum['Total Face-Off Wins'] = totalWonCount;
        datum['Total Face Off Win Percentage'] = totalWonCount ? totalWonCount / _size(teamPlayerFilteredEvents) : 0;
        datum['Total Face-Off Taken'] = _size(teamPlayerFilteredEvents);
    });
    return data;
};
/**
 * Request all player events corresponding to faceoffs in order to simulate a live game
 *
 * @param {HttpRequest<any>} req
 * @param {CustomHttp} customHttp
 * @returns
 */
export const makeFaceoffsLivePreRequest = (req: HttpRequest<any>, customHttp: CustomHttp) => {
    const url = new URL(req.url);
    // eslint-disable-next-line complexity
    const newUrl = url.pathname.split('/').reduce((acc, urlPath) => {
        if ([...acc, urlPath].indexOf('leagues') === -1) {
            return acc;
        }
        if (urlPath === 'live') {
            urlPath = '';
        }
        if (urlPath === 'player') {
            urlPath = 'team';
        } else if (urlPath === 'values') {
            urlPath = 'metrics';
        } else if (urlPath === 'total') {
            urlPath = urlPath.replace('total', 'Total Face-Off Taken');
        }
        acc.push(urlPath);
        return acc;
    }, [] as string[]);
    newUrl.push('events');
    const preRequest = customHttp.get(`/${newUrl.filter(u => !!u).join('/')}${url.search}`);

    return preRequest;
};

export const getGamePlayerEvents = (req: HttpRequest<any>, customHttp: CustomHttp, memory: Record<string, number>) => {
    const url = new URL(req.url);
    const id = _last(url.pathname.split('/'));
    if (memory[`playerevents-${id}`]) {
        // cache
        return of(null);
    }
    const preRequest = customHttp.get(`/games/${id}/playerevents?interceptor=true`);

    return preRequest;
};

export const interceptTopPerformers = (data: any, clock: Record<string, number>, preRequestData: any) => {
    // const timeElapsed = getElapsedTime(memory, data.gameId);
};
