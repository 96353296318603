import { PlayerEvent } from '@sportlogiq/main/core/games/player-event.model';
import { Injectable } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Game } from 'app/main/core';
import { Player } from 'app/main/core/players/player.model';
import { Team } from 'app/main/core/teams/team';
import { cloneDeep as _cloneDeep, every as _every } from 'lodash';
import { MixpanelGlobalState, MixpanelLiveGamesInfo } from './mixpanel.types';
import { CompanyAPIModel, UserModel } from './user/user.types';
import { Store } from '@ngxs/store';
import { UserState } from './user/user.state';
import { filter, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { UserService } from './user.service';
import { ZendeskWidgetSettings } from './zendesk-web-widget-types';
import { CurrentTeam } from '@sportlogiq/main/core/players/player.types';
import { PartnerService } from './partner/partner.service';
import { IcePartner } from './partner/partner.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FS: any;

@Injectable()
export class MixpanelService {
    private _globalState: MixpanelGlobalState;
    private _liveGamesInfo: MixpanelLiveGamesInfo;

    constructor(
        private _angulartics2Mixpanel: Angulartics2Mixpanel,
        private _userService: UserService,
        private _partnerService: PartnerService,
        private _store: Store
    ) {
        combineLatest([
            this._store.select(UserState.user),
            this._store.select(UserState.userCompany),
            this._store.select(UserState.userAuthorization),
        ])
            .pipe(
                filter(a => _every(a)),
                take(1)
            )
            .subscribe(([user, userCompany]) => {
                const userModel = this._userService.getCurrentUser();
                this._setMixpanelUserProperties(userModel, userCompany);
                this._initializeFullStory(userModel, userCompany);
                this._initializeZendesk();
            });
    }

    public eventTrack(name: string, data = {}) {
        const decoratedData = _cloneDeep(data);
        const user = this._userService.getCurrentUser();
        const userCompany = this._store.selectSnapshot(UserState.userCompany);
        if (user) {
            const companyId = user?.companyId;
            const companyName = userCompany?.name;
            const impersonatedBy = user?.impersonatedBy;
            const affiliatedTeam = user?.teamAffiliations;
            const globalProperties = {
                companyId,
                companyName,
                impersonatedBy,
                affiliatedTeam,
                globalState: this._globalState || undefined,
                liveGamesInfo: this._liveGamesInfo || undefined,
            };

            Object.assign(decoratedData, globalProperties);
        }

        this._angulartics2Mixpanel.eventTrack(name, decoratedData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
    decorateWithGameSummaryInfo(obj: any, userTeam: Team, opponentTeam: Team): {} {
        const userTeamContext = {
            userTeam_id: userTeam.id,
            userTeam_teamName: userTeam.name,
        };
        const opponentTeamContext = {
            opponentTeam_id: opponentTeam.id,
            opponentTeam_teamName: opponentTeam.name,
        };
        const newObj = Object.assign(obj, userTeamContext, opponentTeamContext);
        return newObj;
    }

    public decorateWithGameInfo(obj, game: Game) {
        obj.gameId = game.id;
        obj.gameDate = game.date;
        obj.homeTeam = game.homeTeam.fullName;
        obj.awayTeam = game.awayTeam.fullName;

        return obj;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public decorateWithPlayerInfos(obj: any, player: Player) {
        obj.playerId = player.id;
        obj.playerName = player.name;
        obj.playerPosition = player.primaryPosition;
        obj.playerTeam = player.currentTeam.fullName;
        obj.playerJerseyNumber = player.currentTeam.jerseyNum;

        return obj;

        // This is a model for the future implementation of this class
        // Always return a reference so that you can chain methods in this class
        // return { eventTrack: (name) => this.eventTrack(name, obj), obj };
    }

    public decorateWithTeamInfos(obj, team: Team | CurrentTeam) {
        obj.teamId = team.id;
        obj.teamName = team.fullName;

        return obj;
    }

    public decorateWithPlayerEventInfos(obj, e: PlayerEvent) {
        obj.eventPeriod = e.period;
        obj.eventPeriodTime = e.formattedPeriodTime;
        obj.eventManpowerSituation = e.manpowerSituation;
        obj.eventSkatersOnIceSituation = e.skatersOnIceSituation;
        obj.eventCoordinates = e.xTemplateRelative + ', ' + e.yTemplateRelative;
        obj.eventPlayZone = e.playZone;
        obj.eventPlaySection = e.playSection;
        obj.eventName = e.name;
        obj.eventShorthand = e.shorthand;
        obj.eventId = e.id;
        return obj;
    }

    public setGlobalState(state: MixpanelGlobalState): void {
        this._globalState = state;
    }

    public setLiveGamesInfo(info: MixpanelLiveGamesInfo): void {
        this._liveGamesInfo = info;
    }

    private _setMixpanelUserProperties(user: UserModel, userCompany: CompanyAPIModel) {
        this._angulartics2Mixpanel.setUsername(user.id);
        const mixpanelUser = {
            ...user,
            $name: user.fullName,
            $email: user.email,
            $companyId: user.companyId,
            $companyName: userCompany.name,
            $impersonatedBy: user.impersonatedBy,
        };

        this._angulartics2Mixpanel.setUserProperties(mixpanelUser);
    }

    private _initializeFullStory(userModel: UserModel, userCompany: CompanyAPIModel) {
        // Fullstory analytics - set user properties if the user is not impersonating and is not a superuser
        const isSuperUser = this._store.selectSnapshot(UserState.isSuperUser);
        const isImpersonating = this._store.selectSnapshot(UserState.isImpersonating);
        if (!isSuperUser && !isImpersonating) {
            FS.restart();
            FS.identify(userModel.username, {
                email: userModel.email,
                company_str: userCompany.name,
                displayName: userModel.fullName,
                impersonatedBy: userModel.impersonatedBy,
            });
        }
    }

    private _initializeZendesk() {
        const isImpersonating = this._store.selectSnapshot(UserState.isImpersonating);
        if (!window.zE || isImpersonating) {
            return;
        }
        const settings: ZendeskWidgetSettings = {
            webWidget: {
                // https://support.zendesk.com/hc/en-us/articles/360022185594#topic_n3q_cvq_4fb
                // https://support.zendesk.com/hc/en-us/community/posts/360029599893/comments/360014759553
                authenticate: {
                    chat: {
                        jwtFn: callback => {
                            this._partnerService
                                .getAuthenticationForPartner(IcePartner.ZENDESK_CHAT)
                                .subscribe(tokenData => callback(tokenData.token));
                        },
                    },
                },
                contactForm: {
                    suppress: false,
                },
                chat: {
                    suppress: false,
                    hideWhenOffline: false,
                },
                helpCenter: {
                    suppress: false,
                },
            },
        };
        window.zE('webWidget', 'updateSettings', settings);
        // eslint-disable-next-line no-console
        window.zE('webWidget:on', 'chat:start', () => console.debug('Zendesk Chat started'));
        // eslint-disable-next-line no-console
        window.zE('webWidget:on', 'chat:end', () => console.debug('Zendesk Chat ended'));
    }
}
