import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playerAge' })
export class PlayerAgePipe implements PipeTransform {
    transform(birthdate: string): number {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

@Pipe({ name: 'playerFullName' })
export class PlayerFullNamePipe implements PipeTransform {
    transform<T extends { firstName: string; lastName: string; }>(player: T): string {
        return `${player?.firstName} ${player?.lastName}`;
    }
}

@Pipe({ name: 'playerHandedness' })
export class PlayerHandednessPipe implements PipeTransform {
    transform(handedness: string): string {
        if (handedness) {
            return handedness.toLowerCase() === 'l' ? 'Left Handed' : 'Right Handed';
        } else {
            return '';
        }
    }
}
