import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CanvasOptionsService {
    private canvasExpandedSubject = new Subject<boolean>();

    canvasExpanded$ = this.canvasExpandedSubject.asObservable();

    canvasOptions(expanded: boolean) {
        this.canvasExpandedSubject.next(expanded);
    }
}
