import { SLIdentTokenData, UserAPIModel } from './user.types';

export class FetchUserAccount {
    public static readonly type = '[UserState] FetchUserAccount';
}

export class FetchUserAuthorization {
    public static readonly type = '[UserState] FetchUserAuthorization';
}

export class FetchUserCompany {
    public static readonly type = '[UserState] FetchUserCompany';
    constructor(public companyId: string) {}
}

export class SetUser {
    public static readonly type = '[UserState] SetUser';
    constructor(public user: UserAPIModel, public impersonatedBy?: string) {}
}

export class ResetUser {
    public static readonly type = '[UserState] ResetUser';
}

export class SetSLIdentTokenData {
    public static readonly type = '[UserState] SetSLIdentTokenData';
    constructor(public tokenData: SLIdentTokenData) {}
}
