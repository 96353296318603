import { filter } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserPermissions } from '@sportlogiq/shared/types';
import { NukeService } from './nuke.service';
import { Store } from '@ngxs/store';
import { UserState } from './user/user.state';
import { ResetUser } from './user/user.actions';
import { UserModel } from './user/user.types';
import { has as _has } from 'lodash';

export enum UserSettings {
    SectionsMetricsVisibility = 'SectionsMetricsVisibility',
    MetricsVisibility = 'MetricsVisibility',
    HighlightTeamKey = 'HighlightTeamKey',
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zE: any;
    }
}

@Injectable()
export class UserService {
    uovo$ = new ReplaySubject<string>();

    constructor(private _store: Store, private _nukeService: NukeService) {
        this._nukeService.nuke$.pipe(filter(obj => !obj?.reloadData)).subscribe(() => {
            this._resetUser();
        });
    }

    private _resetUser(): void {
        this._store.dispatch(new ResetUser());
    }

    /**
     * @deprecated Use or implement this method in the User.State
     * Get user settings by key
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUserSettings(key: UserSettings): any {
        return JSON.parse(localStorage.getItem(key)) || {};
    }

    /**
     * deprecating the old keys SectionsMetricsForPrint and MetricsForPrint
     * If a user is using the old keys, get all the value and save it with the new keys
     * otherwise set user settings by key as before. Fetch stored settings first and assign or modify properties
     * at some point we could remove the checking
     * @deprecated Use or implement this method in the User.State
     */
    setUserSettings(key: UserSettings, settings) {
        const hasOldKeyOfSectionPrint = _has(localStorage, 'SectionsMetricsForPrint');
        const hasOldKeyOfMetricsPrint = _has(localStorage, 'MetricsForPrint');
        if (hasOldKeyOfSectionPrint) {
            this.replaceKeyForLocalStorage('SectionsMetricsForPrint', 'SectionsMetricsVisibility');
        }

        if (hasOldKeyOfMetricsPrint) {
            this.replaceKeyForLocalStorage('MetricsForPrint', 'MetricsVisibility');
        }

        const currentSettings = this.getUserSettings(key);
        const newSettings = Object.assign(currentSettings, settings);
        localStorage.setItem(key, JSON.stringify(newSettings));
    }

    /**
     * get all the values in the old key, store into new key and delete old key with the values
     */
    replaceKeyForLocalStorage(oldKey, newKey) {
        const currentSettings = this.getUserSettings(oldKey);
        localStorage.setItem(newKey, JSON.stringify(currentSettings));
        localStorage.removeItem(oldKey);
    }

    public checkForPermission(permission: UserPermissions | string) {
        return this._store.selectSnapshot(UserState.hasPermission)(permission);
    }

    public getCurrentUser() {
        return new UserModel(
            this._store.selectSnapshot(UserState.user),
            this._store.selectSnapshot(UserState.userAuthorization),
            this._store.selectSnapshot(UserState.impersonatedBy)
        );
    }

    public canDownloadCSV() {
        return this._store.selectSnapshot(UserState.canDownloadCSV);
    }

    public isImpersonating() {
        return this._store.selectSnapshot(UserState.isImpersonating);
    }

    public isSuperUser() {
        return this._store.selectSnapshot(UserState.isSuperUser);
    }

    public isPlayerUser() {
        return this._store.selectSnapshot(UserState.isPlayerUser);
    }

    public isScoutingOnlyUser() {
        return this._store.selectSnapshot(UserState.isScoutingOnlyUser);
    }
}
