import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChangePasswordComponent } from './change-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { MatInputModule } from '@angular/material/input';
import { DirectiveModule } from '@sportlogiq/main/shared/directive/sl-directive.module';

@NgModule({
    imports: [CommonModule, MatFormFieldModule, MatInputModule, FormsModule, LoadingModule, DirectiveModule],
    declarations: [ChangePasswordComponent],
    exports: [ChangePasswordComponent],
})
export class ChangePasswordModule {}
