import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackBar } from '../../shared/types';

/**
 *
 *
 * @export
 * @class CustomSnackbarComponent
 */
@Component({
    selector: 'custom-snackbar',
    templateUrl: './custom-snackbar.component.html',
    styleUrls: ['./custom-snackbar.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class]': 'theme',
    },
})
export class CustomSnackbarComponent implements CustomSnackBar<CustomSnackbarComponent> {
    message: string;
    action: string;
    theme: string;
    /**
     *
     *
     * @type {MatSnackBarRef<CustomSnackbarComponent>}
     * @memberOf CustomSnackbarComponent
     */
    snackBarRef: MatSnackBarRef<CustomSnackbarComponent>;

    get hasAction(): boolean {
        return !!this.action;
    }

    dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
