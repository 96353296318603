import { NewPlayerEvent } from '@sportlogiq/main';

export class FetchGameEventDefinitions {
    public static readonly type = '[API] FetchGameEventDefinitions';
}

export class FetchPlayerEventDefinitions {
    public static readonly type = '[API] FetchPlayerEventDefinitions';
}

export class SetContextPlayerEvents {
    public static readonly type = '[API] SetContextPlayerEvents';

    constructor(public playerEvents: NewPlayerEvent[]) {}
}

export class ClearContextPlayerEvents {
    public static readonly type = '[API] ClearContextPlayerEvents';
}

export class FetchFlagEventDefinitions {
    public static readonly type = '[API] FetchFlagEventDefinitions';
}
