import { EventSearchesBodyPayload, SortingOrder } from '../core/store/event-searches/event-searches.types';
import {
    PlaylistEventsTabs,
    RinkViewOptions,
} from '../shared/utils/filter-video/filter-video.types';

export class GameVideoFetchGameContext {
    public static readonly type = '[GameVideo] Fetch Game Context';
    constructor(public gameId: string) {}
}

export class GameVideoFetchPlayerEvents {
    public static readonly type = '[GameVideo] Fetch Player Events';
    constructor(public gameId: string) {}
}

export class GameVideoResetInvalidFields {
    public static readonly type = '[GameVideo] Reset Invalid Fields';
}

export class GameVideoSetGameId {
    public static readonly type = '[GameVideo] Set Game Id';
    constructor(public gameId: string) {}
}

export class GameVideoFetchGamePlayers {
    public static readonly type = '[GameVideo] Fetch Game Players';
    constructor(public gameId: string) {}
}

export class GameVideoSelectEventType {
    public static readonly type = '[GameVideo] Select Event Type';
    constructor(public eventType: string) {}
}

export class GameVideoSelectPeriod {
    public static readonly type = '[GameVideo] Select Period';
    constructor(public period: number) {}
}

export class GameVideoSelectManpowerTeam {
    public static readonly type = '[GameVideo] Select Manpower Team';
    constructor(public teamId: string) {}
}

export class GameVideoSelectManpowerSituation {
    public static readonly type = '[GameVideo] Select Manpower Situation';
    constructor(public manpowerSituation: string) {}
}

export class GameVideoSelectPlayers {
    public static readonly type = '[GameVideo] Select Players';
    constructor(public playerIds: string[]) {}
}

export class GameVideoSetEventFilters {
    public static readonly type = '[GameVideo] Set Event Filters';
    constructor(public filters: string[], public eventType: string) {}
}

export class GameVideoToggleWhenOnIce {
    public static readonly type = '[GameVideo] Set When On Ice';
    constructor(public whenOnIce: boolean) {}
}

export class GameVideoHideEventsForPlayers {
    public static readonly type = '[GameVideo] Hide Events For Players';
    constructor(public playerIds: string[]) {}
}

export class GameVideoShowEventsForPlayers {
    public static readonly type = '[GameVideo] Show Events For Players';
    constructor(public playerIds: string[]) {}
}

export class GameVideoSelectPlayerEvents {
    public static readonly type = '[GameVideo] Select Player Events';
    constructor(public playerEventIds: string[]) {}
}

export class GameVideoDeselectPlayerEvents {
    public static readonly type = '[GameVideo] Deselect Player Events';
    constructor(public playerEventIds: string[]) {}
}

export class GameVideoClearSelectedPlayerEvents {
    public static readonly type = '[GameVideo] Clear Selected Player Events';
}

export class GameVideoSetEventPlaylistOrder {
    public static readonly type = '[GameVideo] Set Event Playlist Order';
    constructor(public order: SortingOrder) {}
}

export class GameVideoSetSelectedEventSearchLoading {
    public static readonly type = '[GameVideo] Set Selected Event Search Loading';
    constructor(public loading: boolean) {}
}
export class GameVideoResetState {
    public static readonly type = '[GameVideo] Reset State';
}

export class GameVideoResetGameState {
    public static readonly type = '[GameVideo] Reset Game State';
}

export class GameVideoResetFiltersState {
    public static readonly type = '[GameVideo] Reset Filters State';
}

export class GameVideoPlaylistSelectEventTab {
    public static readonly type = '[GameVideo] Select Playlist Event Tab';
    constructor(public playlistEventTab: PlaylistEventsTabs) {}
}

export class GameVideoFetchShootoutClips {
    public static readonly type = '[GameVideo] Fetch Shootout Clips';
    constructor(public gameId: string) {}
}

export class GameVideoSearchPlayerEvent {
    public static readonly type = '[GameVideo] Seach Player Event';
    constructor(public searchValue: string) {}
}

export class GameVideoSearchPlayerEventClear {
    public static readonly type = '[GameVideo] Clear Search Player Events';
}

export class GameVideoSetLoadedEventSearches {
    public static readonly type = '[GameVideo] Set Loaded Event Searches';
    constructor(public eventSearches: EventSearchesBodyPayload[]) {}
}

export class GameVideoRefreshLoadedEventSearches {
    public static readonly type = '[GameVideo] Refresh Loaded Event Searches';
    constructor(public eventSearches: EventSearchesBodyPayload[]) {}
}

export class GameVideoSetSelectedEventSearchIndex {
    public static readonly type = '[GameVideo] Set Selected Event Search index';
    constructor(public selectedEventSearchIndex: number) {}
}

export class GameVideoPatchSelectedEventSearch {
    public static readonly type = '[GameVideo] Patch Selected Event Search';
    constructor(public eventSearch: EventSearchesBodyPayload) {}
}
export class GameVideoAddEmptyEventSearchTab {
    public static readonly type = '[GameVideo] Add Event Search tab';
}

export class GameVideoCloseEventSearchTab {
    public static readonly type = '[GameVideo] Close Event Search Tab';
    constructor(public index: number) {}
}

export class GameVideoDuplicateEventSearchTab {
    public static readonly type = '[GameVideo] Duplicate Event Search Tab';
    constructor(public index: number) {}
}

export class GameVideoRinkViewChange {
    public static readonly type = '[GameVideo] Set Selected Rink View Value';
    constructor(public selectedRinkView: RinkViewOptions) {}
}

export class GameVideoSetTeamToggles {
    public static readonly type = '[GameVideo] Set Selected Team Toggles';
    constructor(public teamToggles: [boolean, boolean]) {}
}

export class GameVideoSetEventsUnalignedWithFilters {
    public static readonly type = '[GameVideo] Set Events Unaligned With Filters';
    constructor(public eventsUnalignedWithFilters: boolean) {}
}

export class GameVideoSetShowAwayTeamEvents {
    public static readonly type = '[GameVideo] Set Show Away Team Events';
    constructor(public showAwayTeamEvents: boolean) {}
}

export class GameVideoSetShowHomeTeamEvents {
    public static readonly type = '[GameVideo] Set Show Home Team Events';
    constructor(public showHomeTeamEvents: boolean) {}
}
