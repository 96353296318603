import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class AppLoaderService {
    constructor(@Inject(DOCUMENT) private _document) {}

    hide() {
        const loader: HTMLElement = this._document.getElementsByClassName('app-loader')[0];
        if (loader) {
            loader.remove();
        }
    }
}
