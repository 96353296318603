/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanLoad,
    Route,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    UrlTree,
    RouterEvent,
} from '@angular/router';
import { UserService } from './user.service';
import { NukeService } from './nuke.service';
import { Constants } from 'environments/constants';
import { Store } from '@ngxs/store';
import { UserState } from './user/user.state';
import { UserPermissions } from '@sportlogiq/shared';
import {
    forIn as _forIn,
    get as _get,
    includes as _includes
} from 'lodash';
import { AppRoute } from '@sportlogiq/app-routing.models';
import { SCOUTING_MAIN_ROUTE } from '@sportlogiq/main/scouting/scouting.module';

const PROTECTED_ROUTES = {
    canLoginOnAgentApp: ['/agent', '/metric', '/profile', '/notes'],
    canLoginOnPlayerApp: ['/player', '/profile', '/playlists'],
    canLoginOnPlayerWithVideoApp: ['/player', '/profile', '/games', '/teams'],
    canLoginOnScoutingApp: [`/${SCOUTING_MAIN_ROUTE}`],
    canLoginOnTeamApp: ['/', '/games'],
    canLoginOnLiveApp: ['/live', '/profile'],
};

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    private _currentRoute: UrlTree;
    private readonly _redirectLoginUrl = `/${AppRoute.LOGIN}`;

    constructor(private _router: Router, private _userService: UserService, private _nukeService: NukeService, private _store: Store) {
        this._router.events.subscribe(e => {
            if (e instanceof RouteConfigLoadEnd || e instanceof RouteConfigLoadStart) {
                return;
            } else if ((<RouterEvent>e).url) {
                this._currentRoute = this._router.parseUrl((<RouterEvent>e).url);
            }
        });
    }

    canLoad(route: Route): boolean {
        // see https://github.com/angular/angular/issues/10584
        const url = this._currentRoute || this._router.parseUrl(window.location.pathname + window.location.search);
        return this.checkLogin(url);
    }

    // eslint-disable-next-line complexity
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const url: UrlTree = this._router.parseUrl(state.url);
        let canContinue;
        let userPerm;

        const currentUser = this._store.selectSnapshot(UserState.user);
        const returnTo = _get(route.queryParams, Constants.return_to_zendesk);
        if (currentUser && returnTo) {
            return this._router.parseUrl(`/zendesk?${Constants.return_to_zendesk}=${returnTo}`);
        }

        if (url.toString() === this._redirectLoginUrl) {
            if (currentUser) {
                this._router.navigate(['/']);
            } else {
                return true;
            }
        }

        if (this._store.selectSnapshot(UserState.isSuperUser)) {
            return this.checkLogin(url);
        }


        _forIn(PROTECTED_ROUTES, (v, k: UserPermissions) => {
            if (this._userService.checkForPermission(k)) {
                userPerm = k;
                // HACK: Temporary way to allow anyone with team app access to view nested pages
                if (userPerm === 'canLoginOnTeamApp') {
                    canContinue = true;
                    return;
                }

                if (userPerm === 'canLoginOnPlayerWithVideoApp') {
                    // allow the PlayerWithVideo role access to view the nested pages.
                    canContinue = true;
                    return;
                }

                // If the route the user is requesting is valid, and it contains
                // the first route in protected_routes for that permission, allow them through.
                // This allows nested valid routes under a propected root route to function.
                if (_includes(url.toString(), PROTECTED_ROUTES[userPerm][0])) {
                    canContinue = true;
                    return;
                }
                canContinue = _includes(v, url.toString());
            }
        });

        if (!canContinue) {
            const firstAllowedRoute = PROTECTED_ROUTES[userPerm][0];
            const routeRequested = this._currentRoute.toString();
            const newUrl = _includes(routeRequested, 'videos') ? `${firstAllowedRoute}${routeRequested}` : firstAllowedRoute;
            this._router.navigate([newUrl]);
        }

        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.canActivate(route, state);
    }

    checkLogin(url: UrlTree): boolean {
        if (this._store.selectSnapshot(UserState.user)) {
            return true;
        }

        // Trigger the nuke in order to clear app instances
        this._nukeService.nuke$.next({ notify: true });
        this._router.navigate([this._redirectLoginUrl]);
        return false;
    }
}
