import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackBar } from '../../../shared/types';

/**
 *
 *
 * @export
 * @class NotesSnackbarComponent
 */
@Component({
    selector: 'notes-snackbar',
    templateUrl: './notes-snackbar.component.html',
    styleUrls: ['./notes-snackbar.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class]': 'theme',
    },
})
export class NotesSnackbarComponent implements CustomSnackBar<NotesSnackbarComponent> {
    message: string;
    action: string;
    theme: string;
    /**
     * @type {MatSnackBarRef<NotesSnackbarComponent>}
     * @memberOf NotesSnackbarComponent
     */
    snackBarRef: MatSnackBarRef<NotesSnackbarComponent>;

    dismiss(): void {
        this.snackBarRef.dismiss();
    }

    get hasAction(): boolean {
        return !!this.action;
    }
}
