import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import { loginRoutes } from './login.routing';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SlLogoModule } from '@sportlogiq/shared/sl-logo/sl-logo.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '@sportlogiq/shared/loading/loading.module';
import { ChangePasswordModule } from '../shared/change-password/change-password.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule.forChild(loginRoutes),
        SlLogoModule,
        TranslateModule,
        LoadingModule,
        ChangePasswordModule,
    ],
    declarations: [LoginComponent],
})
export class LoginModule {}
