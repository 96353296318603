import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, interval, merge, Observable, ReplaySubject } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { delay, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WarningSnackbarComponent } from '@sportlogiq/shared/warning-snackbar/warning-snackbar.component';
import { MixpanelService } from './mixpanel.service';

const VERSION_CHECK_INTERVAL = 1000 * 60;

export interface VersionManifest {
    dirty: boolean;
    raw: string;
    hash: string;
    distance: null;
    tag: null;
    semver: null;
    suffix: string;
    semverString: null;
}

@Injectable()
export class VersionCheckService {
    private _loadedVersionManifest$ = new ReplaySubject<VersionManifest>();
    private _manifestFilePath = '/assets/manifest.json';
    private _remindAfter$ = new ReplaySubject<number>(1);
    private _snackbarOpened = false;

    constructor(private _snackbar: MatSnackBar, private _mixpanel: MixpanelService) {}

    public init(): void {
        this._remindAfter$.next(new Date().getTime());

        const getVersionManifest$: Observable<VersionManifest> = fromFetch(this._manifestFilePath, { credentials: 'include' }).pipe(
            switchMap(response => {
                if (response.ok) {
                    return response.json();
                }
                this._mixpanel.eventTrack('AppVersionUpdateManifestError', {
                    errorMessage: `Couldn't fetch the version manifest: ${response.statusText}`,
                });
                return EMPTY;
            })
        );

        getVersionManifest$.pipe(take(1)).subscribe(val => {
            this._loadedVersionManifest$.next(val);
        });

        // check manifest every minute unless a "remindAfter" date is set
        combineLatest([interval(VERSION_CHECK_INTERVAL), this._remindAfter$])
            .pipe(
                // only show snackbar if the current date is after the remindAfter value
                filter(([i, remindAfter]) => {
                    return new Date().getTime() > remindAfter && !this._snackbarOpened;
                }),
                switchMap(() => {
                    return getVersionManifest$;
                }),
                withLatestFrom(this._loadedVersionManifest$),
                switchMap(([latest, current]) => {
                    if (latest.hash !== current.hash) {
                        return this.showSnackbar();
                    } else {
                        return EMPTY;
                    }
                })
            )
            .subscribe(({ reloadApp }) => {
                this._snackbarOpened = false;
                if (reloadApp) {
                    window.location.reload();
                } else {
                    const remindAfter = new Date();
                    // set the reminder to 1h later
                    remindAfter.setHours(remindAfter.getHours() + 1);
                    // eslint-disable-next-line no-console
                    console.info('App Update:: Remind after ', remindAfter.toLocaleString());
                    this._remindAfter$.next(remindAfter.getTime());
                }
            });
    }

    showSnackbar(): Observable<{ reloadApp: boolean; }> {
        const snackbar = this._snackbar.openFromComponent<WarningSnackbarComponent>(WarningSnackbarComponent, {
            panelClass: ['sliq-snackbar', 'has-secondary-action'],
        });
        snackbar.instance.message = 'A new version of the application is available.';
        snackbar.instance.action = 'Refresh now!';
        snackbar.instance.secondaryActionLabel = 'Remind me later';
        this._snackbarOpened = true;
        const onRefreshClick$ = snackbar.afterDismissed().pipe(
            filter(({ dismissedByAction }) => dismissedByAction),
            map(() => {
                this._mixpanel.eventTrack('AppVersionUpdateReloadApp');
                return { reloadApp: true };
            }),
            delay(300) // give some time to mixpanel to send the event, not 100% guaranteed
        );
        const onRemindLater$ = snackbar.afterDismissed().pipe(
            filter(({ dismissedByAction }) => !dismissedByAction),
            map(() => {
                this._mixpanel.eventTrack('AppVersionUpdateDismissed');
                return { reloadApp: false };
            })
        );
        this._mixpanel.eventTrack('AppVersionUpdateReceived');
        return merge(onRefreshClick$, onRemindLater$).pipe(take(1));
    }
}
