/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Injector,
    InjectionToken,
    Injectable,
    ApplicationRef,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    ComponentRef,
    Component,
    Inject,
} from '@angular/core';
import { ComponentType, PortalInjector } from '@angular/cdk/portal';
import Popper, { ReferenceObject } from 'popper.js';
import Tooltip from 'tooltip.js';

// eslint-disable-next-line @typescript-eslint/ban-types
export const TOOLTIP_DATA = new InjectionToken<{}>('TOOLTIP_DATA');
@Component({
    selector: 'sl-simple-tooltip',
    template: '<div class=\'simple-tooltip sl-tooltip\'>{{text}}</div>',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SimpleTooltip {
    constructor(@Inject(TOOLTIP_DATA) public text: string) {}
}

export type Placement =
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
export interface TooltipOptions {
    title: HTMLElement | string;
    html?: boolean;
    popperOptions?: Popper.PopperOptions;
    trigger: 'click' | 'focus' | 'hover' | string;
    template?: string;
    placement?: Placement;
    container?: HTMLElement | string;
    delay?: number;
    offset: string | number;
    classNames?: string[];
}

export enum TooltipPosition {
    top = 'top',
    right = 'right',
    left = 'left',
    bottom = 'bottom',
    auto = 'auto',
}

export interface TooltipJS {
    show: () => void;
    hide: () => void;
    dispose: () => void;
    updateTitleContent: (title: HTMLElement | string) => void;
    reference?: Element;
    popperInstance?: Popper & { popper: HTMLElement; reference: HTMLElement; };
}

@Injectable()
export class TooltipService {
    private weakReferences: WeakMap<any, ComponentRef<any>> = new WeakMap();

    constructor(private _injector: Injector, private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef) {}

    attachTooltip(
        element: HTMLElement,
        component: ComponentType<any>,
        componentData: any,
        tooltipOptions?: Partial<TooltipOptions>,
        referenceKey?: any
    ): TooltipJS {
        const tooltipContentEl = this._appendComponentToBody(element, component, componentData, referenceKey);
        let classNames = [];
        if (!tooltipOptions || !tooltipOptions.classNames) {
            classNames.push('sl-callout');
        } else {
            classNames = tooltipOptions.classNames;
        }
        const defaultOptions: Partial<TooltipOptions> = {
            title: tooltipContentEl,
            html: true,
            trigger: 'hover',
            template: `<div class="tooltip ${classNames.join(
                ' '
            )}" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`,
            delay: 300,
            offset: '0, 15',
        };

        tooltipOptions = Object.assign(defaultOptions, tooltipOptions || ({} as any));
        const tooltip: TooltipJS = new Tooltip(element, tooltipOptions);
        return tooltip;
    }

    detachTooltip(tooltip: TooltipJS, referenceKey?: any): void {
        this._destroy(referenceKey || tooltip.popperInstance.popper);
        tooltip.dispose();
    }

    private _createInjector(data: any): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(TOOLTIP_DATA, data);
        return new PortalInjector(this._injector, injectorTokens);
    }

    private _appendComponentToBody(
        element: Element | ReferenceObject,
        component: ComponentType<any>,
        data,
        referenceKey?: any
    ): HTMLElement {
        // https://medium.com/@caroso1222/angular-pro-tip-how-to-dynamically-create-components-in-body-ba200cc289e6
        // 1. Create a component reference from the component
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this._createInjector(data));

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
        // 3. Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        // 4. Append DOM element to the body
        // document.body.appendChild(domElem);

        const key = referenceKey || (element as HTMLElement);
        this.weakReferences.set(key, componentRef);

        return domElem;
    }

    private _destroy(element: HTMLElement | any) {
        const componentRef = this.weakReferences.get(element);
        if (componentRef) {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();

            this.weakReferences.delete(element);
        }
    }
}
