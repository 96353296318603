import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth-guard.service';

import { loginRoutes } from './login/login.routing';

const mainRoutes: Routes = [{ path: '', loadChildren: () => import('app/main/main.module').then(m => m.MainModule), canLoad: [AuthGuard] }];

export const routes: Routes = [
    {
        path: 'videos/:directory/:filename',
        data: {
            title: 'ROUTE_TITLES.LeagueDashboard',
        },
        loadChildren: () => import('app/main/main.module').then(m => m.MainModule),
        canLoad: [AuthGuard],
    },
    { path: 'zendesk', loadChildren: () => import('app/main/zendesk/zendesk.module').then(m => m.ZendeskModule) },
    ...loginRoutes,
    ...mainRoutes,
    // otherwise redirect to home
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
