import { UserSetting } from './user-settings.types';

export class UserSettingsFetchAll {
    public static readonly type = '[UserSettings] Fetch All User Settings';
}

export class UserSettingsSave {
    public static readonly type = '[UserSettings] Save User Setting';
    constructor(public userSettings: UserSetting[]) {}
}

export class UserSettingsDelete {
    public static readonly type = '[UserSettings] Delete User Setting';
    constructor(public userSettingId: string) {}
}
