import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

/**
 *
 *
 * @export
 * @class SuccessSnackbarComponent
 */
@Component({
    selector: 'success-snackbar',
    templateUrl: './success-snackbar.component.html',
    styleUrls: ['./success-snackbar.component.scss'],
})
export class SuccessSnackbarComponent {
    message: string;
    action: string;
    /**
     *
     *
     * @type {MatSnackBarRef<SuccessSnackbarComponent>}
     * @memberOf SuccessSnackbarComponent
     */
    snackBarRef: MatSnackBarRef<SuccessSnackbarComponent>;

    get hasAction(): boolean {
        return !!this.action;
    }

    dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
