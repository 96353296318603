/* eslint-disable @typescript-eslint/no-explicit-any, complexity */
import { Team } from 'app/main/core/teams/team';
import { SeasonStage } from 'app/main/core/seasons/season-stage.model';
import { getTeamFullname, secondsToMinuteString } from '@sportlogiq/shared/utils';
import { CurrentTeam, SeasonSummary, RefMetric } from './player.types';
import { getTOIForSeasonSummaries, maybe, buildSalary, buildFreeAgency, buildAge } from './player.utils';
import {
    cloneDeep as _cloneDeep,
    some as _some,
    map as _map,
    capitalize as _capitalize,
    reduce as _reduce,
    sum as _sum
} from 'lodash';
import { TeamModelAPI } from '../store/team/team.models';

export class Player {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    initials: string;
    primaryPosition: string;
    currentTeam: CurrentTeam;
    pictureSrc: string;
    handedness: string;
    freeAgencyStatus: string;
    freeAgencyYear: string;
    futureFreeAgencyStatus: string;
    freeAgencyPlayerCard: string;
    injuryStatus: string;
    currentSalary: string;
    salary: number;
    status: string;
    birthdate: string | Date;
    age: string;
    hiddenCompare?: boolean;
    seasonsSummary: SeasonSummary[];
    selectedSeasonSummary: SeasonSummary;
    timeOnIce: { [mp: string]: string; };
    esToi: string;
    ppToi: string;
    shToi: string;
    esToiRaw: number;
    ppToiRaw: number;
    shToiRaw: number;
    metrics: {
        rawMetricValue: number;
        metricValueFormatted: string;
    };

    quickRefMetrics?: RefMetric;

    constructor(player: Player, teamReference: Team | TeamModelAPI, season?: SeasonStage) {
        this.id = player?.id;
        this.age = maybe(buildAge, player);
        this.firstName = player?.firstName;
        this.lastName = player?.lastName;
        // First name may be an empty string. Remove eventual leading spaces
        this.name = player && `${this.firstName} ${this.lastName}`.trim();
        this.initials = player && this._getInitials(player);
        this.primaryPosition = player?.primaryPosition;
        this.currentTeam = {
            id: teamReference?.id,
            logosrc: teamReference?.logosrc,
            fullName:
                teamReference &&
                (teamReference instanceof Team ? teamReference.fullName : getTeamFullname(teamReference.location, teamReference.name)),
            jerseyNum: player?.currentTeam && player.currentTeam.jerseyNum,
            shorthand: teamReference?.shorthand,
        } as CurrentTeam;
        this.currentSalary = player?.currentSalary;
        this.pictureSrc = player?.pictureSrc;
        this.handedness = player?.handedness;
        this.freeAgencyStatus = player?.freeAgencyStatus;
        this.freeAgencyPlayerCard = maybe(buildFreeAgency, player);
        this.status = player?.status;
        this.salary = maybe(buildSalary, player);
        this.injuryStatus = player && _capitalize(player.injuryStatus);
        this.birthdate = player?.birthdate;

        if (player?.seasonsSummary) {
            this.seasonsSummary = _map(player.seasonsSummary, stageSummary => {
                stageSummary.teams = _map(stageSummary.teams, (v: any) => this.formatRefMetrics(v));
                if (stageSummary.overall) {
                    stageSummary.overall = this.formatRefMetrics(stageSummary.overall);
                }
                return stageSummary;
            });

            this.seasonsSummary =
                player.seasonsSummary &&
                _map(player.seasonsSummary, (value, i) => {
                    // Loop over each team to calculate TOI
                    value.teams = _map(value.teams, team => {
                        // Setup TOI by raw (number) and computed (string "mmm:ss")
                        team.toiSecondsRaw = team.toiSeconds;
                        team.toiSeconds = secondsToMinuteString(team.toiSecondsRaw);
                        team.toiSecondsESRaw = team.toiSecondsES;
                        team.toiSecondsES = secondsToMinuteString(team.toiSecondsESRaw);
                        team.toiSecondsPPRaw = team.toiSecondsPP;
                        team.toiSecondsPP = secondsToMinuteString(team.toiSecondsPPRaw);
                        team.toiSecondsSHRaw = team.toiSecondsSH;
                        team.toiSecondsSH = secondsToMinuteString(team.toiSecondsSHRaw);
                        return team;
                    });
                    return value;
                });
            this.selectedSeasonSummary = season && this.getSeasonSummaryForSelectedSeasonStage(season);
            const seasonSummaries = season ? [this.selectedSeasonSummary] : player.seasonsSummary;

            // Number fortmat
            if (!_some(seasonSummaries)) {
                // eslint-disable-next-line no-console
                console.warn('Missing player season summary for', player);
            }
            this.esToiRaw = getTOIForSeasonSummaries(seasonSummaries, 'ES');
            this.ppToiRaw = getTOIForSeasonSummaries(seasonSummaries, 'PP');
            this.shToiRaw = getTOIForSeasonSummaries(seasonSummaries, 'SH');

            // String format mm:ss
            this.esToi = secondsToMinuteString(this.esToiRaw);
            this.ppToi = secondsToMinuteString(this.ppToiRaw);
            this.shToi = secondsToMinuteString(this.shToiRaw);
        } else if (player?.timeOnIce) {
            // Number fortmat
            this.esToiRaw = parseFloat(player.timeOnIce.evenStrength);
            this.ppToiRaw = parseFloat(player.timeOnIce.powerPlay);
            this.shToiRaw = parseFloat(player.timeOnIce.shortHanded);

            // String format mm:ss
            this.esToi = secondsToMinuteString(this.esToiRaw);
            this.ppToi = secondsToMinuteString(this.ppToiRaw);
            this.shToi = secondsToMinuteString(this.shToiRaw);
        }

        this.metrics = { rawMetricValue: null, metricValueFormatted: null };

        if (player.quickRefMetrics) {
            this.quickRefMetrics = this.formatRefMetrics(player.quickRefMetrics);
        } else {
            this.quickRefMetrics = {} as any;
        }
    }

    get totalTOI() {
        return _sum([this.esToiRaw, this.ppToiRaw, this.shToiRaw]).toFixed();
    }

    get totalTOIMin() {
        return secondsToMinuteString(_sum([this.esToiRaw, this.ppToiRaw, this.shToiRaw]));
    }

    get totalGamesPlayed() {
        return _reduce<any[], number>(this.selectedSeasonSummary.teams, (acc, t: any) => (acc += t.gamesPlayed), 0).toFixed();
    }

    private _getInitials(player: Player) {
        let initialFirstName = player.firstName.substr(0, 1);
        if (player.firstName.includes('.')) {
            initialFirstName = player.firstName.split('.').join('');
        }

        const initialLastName = player.lastName.substr(0, 1);
        return `${initialFirstName}${initialLastName}`;
    }

    private formatRefMetrics(v: RefMetric): RefMetric {
        const clonedV = _cloneDeep(v);
        clonedV.xgfratioComputed = (v.xgfratio * 100).toFixed();
        clonedV.ozstartComputed = (v.ozstart * 100).toFixed();
        clonedV.sooComputed = (+v.soo).toFixed(1);
        clonedV.soodiffComputed = (+v.soodiff).toFixed(1);
        return clonedV;
    }

    public getSeasonSummaryForSelectedSeasonStage(seasonStage: SeasonStage): SeasonSummary {
        if (this.seasonsSummary) {
            return this.seasonsSummary.find(
                ss => ss.leagueId === seasonStage.leagueId && ss.seasonId === seasonStage.seasonid && ss.name === seasonStage.stage
            );
        } else {
            return null;
        }
    }
}

export interface PlayerLookup {
    [playerId: string]: boolean;
}
