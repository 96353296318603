import { Component, Input } from '@angular/core';

@Component({
    selector: 'sl-logo',
    templateUrl: './sl-logo.component.html',
    styleUrls: ['./sl-logo.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SlLogo {
    @Input() beta: boolean;
    @Input() mini: boolean;
    @Input() logoStyle: string;
}
