import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabItem } from '@sportlogiq/main/shared/sl-types';
import { isEmpty as _isEmpty } from 'lodash';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable()
export class TabsService {
    private _tabs$ = new BehaviorSubject<TabItem[]>([]);
    public tabs$ = this._tabs$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
    private _showPageHeader$ = new BehaviorSubject(false);
    public showPageHeader$ = this._showPageHeader$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

    private _hideTeamSelector$ = new BehaviorSubject(false);
    public hideTeamSelector$ = this._hideTeamSelector$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

    public setTabs(tabs: TabItem[]): void {
        if (!_isEmpty(tabs)) {
            this._showPageHeader$.next(true);
        }
        this._tabs$.next(tabs);
    }

    public hidePageHeader(): void {
        this._showPageHeader$.next(false);
    }

    public hideTeamSelector(value: boolean): void {
        this._hideTeamSelector$.next(value);
    }
}
