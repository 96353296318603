import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

const hostname = window?.location?.hostname;
const protocol = window?.location?.protocol;
const path = window?.location?.pathname;

if (protocol !== 'https:' && (hostname.includes('staging') || hostname.includes('beta'))) {
    window.location.replace('https://' + hostname + path);
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
