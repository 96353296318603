import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Angulartics2, Angulartics2Module } from 'angulartics2';
import { NgxsModule } from '@ngxs/store';
import { PermissionGuard } from './permission-guard.service';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { MixpanelService } from 'app/core/mixpanel.service';
import { MessageHandlerService } from 'app/core/message-handler.service';
import { CustomHttp } from 'app/core/custom-http.service';
import { UserService } from 'app/core/user.service';
import { AuthGuard } from 'app/core/auth-guard.service';
import { AuthService } from 'app/core/auth.service';
import { CommonModule } from '@angular/common';
import { SlTitleService } from './sl-title.service';
import { NukeService } from './nuke.service';
import { NotesSnackbarComponent } from '@sportlogiq/main/notes/snackbar/notes-snackbar.component';
import { ErrorSnackbarComponent } from '@sportlogiq/shared/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from '@sportlogiq/shared/success-snackbar/success-snackbar.component';
import { CustomSnackbarComponent } from '@sportlogiq/shared/custom-snackbar/custom-snackbar.component';
import { InsideEdgeGuard } from '@sportlogiq/main/inside-edge/inside-edge.guard';
import { UserSettingsState } from './user-settings.state';
import { EventState } from 'app/core/event/event.state';
import { EventService } from './event/event.service';
import { UserState } from './user/user.state';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { Cookie } from 'ng2-cookies';
import { CustomReportsGuard } from '@sportlogiq/main/custom-reports/custom-reports.guard';
import { PartnerService } from './partner/partner.service';

export function jwtOptionsFactory() {
    return {
        tokenGetter: () => {
            return Cookie.get('SL_IDENT_TOKEN');
        },
    };
}

const options = {
    jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [],
    },
};

@NgModule({
    imports: [
        CommonModule,
        Angulartics2Module.forRoot([Angulartics2Mixpanel], {
            developerMode: true,
            pageTracking: {
                autoTrackVirtualPages: true,
                clearIds: true,
            },
        }),
        NgxsModule.forFeature([UserSettingsState, EventState, UserState]),
        JwtModule.forRoot(options),
    ],
    providers: [
        AuthService,
        AuthGuard,
        InsideEdgeGuard,
        CustomReportsGuard,
        PermissionGuard,
        SlTitleService,
        UserService,
        NukeService,
        CustomHttp,
        MessageHandlerService,
        Angulartics2,
        Angulartics2Mixpanel,
        MixpanelService,
        EventService,
        PartnerService,
    ],
    declarations: [ErrorSnackbarComponent, SuccessSnackbarComponent, CustomSnackbarComponent, NotesSnackbarComponent],
    exports: [ErrorSnackbarComponent, SuccessSnackbarComponent, CustomSnackbarComponent, NotesSnackbarComponent],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the RootImportModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
        };
    }
}
