import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CustomHttp } from './custom-http.service';
import { MessageHandlerService } from './message-handler.service';
import { MixpanelService } from './mixpanel.service';
import { NukeService } from './nuke.service';
import { Constants } from 'environments/constants';
import { Store } from '@ngxs/store';
import { UserSettingsFetchAll } from './user-settings.actions';
import { AccountAPIModel, CompanyAPIModel, LoginAPIModel, SLAuthorization, SLIdentTokenData } from './user/user.types';
import { FetchUserAccount, FetchUserAuthorization, FetchUserCompany, ResetUser, SetSLIdentTokenData, SetUser } from './user/user.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppRoute } from '@sportlogiq/app-routing.models';

@Injectable()
export class AuthService {
    private apiLoginUrl = `/${AppRoute.LOGIN}`;
    private apiLogoutUrl = '/logout';
    // variable to store the URL so we can redirect after logging in
    public redirectUrl: string | UrlTree;

    constructor(
        private _router: Router,
        private _customHttp: CustomHttp,
        private _nukeService: NukeService,
        private _messageHandler: MessageHandlerService,
        private _mixpanelService: MixpanelService,
        private _store: Store,
        private _jwtService: JwtHelperService
    ) {
        // HACK: Refresh window if you get a 403 on a chunk file
        window.addEventListener(
            'error',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e: any) => {
                const UNAUTHORIZED = 403;

                e.preventDefault();

                const isScript = e.target.tagName === 'SCRIPT' || e.target.tagName === 'script';
                const isModuleFile = isScript && e.target.src && e.target.src.includes('module');

                if (isModuleFile) {
                    const scriptSrc = e.target.src;
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', scriptSrc, false);
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === UNAUTHORIZED) {
                            // If the chunk file is missing
                            // reload the page to load new chunkfiles
                            location.reload();
                        }
                    };
                    xhr.send();
                }
            },
            true
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public login(event: Event, username: string, password: string): Observable<any> {
        if (event) {
            event.preventDefault();
        }

        const body = JSON.stringify({ username: username.trim().toLowerCase(), password });

        return this._customHttp.post(this.apiLoginUrl, body, {}, Constants.user_api_root).pipe(
            switchMap((login: LoginAPIModel) => {
                this._store.dispatch(new SetUser(login));
                return forkJoin([
                    this._store.dispatch(new FetchUserAccount()),
                    this._store.dispatch(new FetchUserAuthorization()),
                    this._store.dispatch(new FetchUserCompany(login.companyId)),
                    this._store.dispatch(new UserSettingsFetchAll()),
                    of(this.getImpersonationTokenData()),
                ]);
            }),
            switchMap(([account, authorization, userCompany, settings, impersonationToken]) => {
                return this._store.dispatch(new SetSLIdentTokenData(impersonationToken));
            }),
            map(() => {
                // mixpanel analytics - track login event
                this._mixpanelService.eventTrack('login', {});
                // redirect to homepage when successful

                if (this.redirectUrl) {
                    this._router.navigateByUrl(this.redirectUrl);
                } else {
                    this._router.navigate(['/']);
                }
                return;
            })
        );
    }

    logout(event?: Event): void {
        if (event) {
            event.preventDefault();
        }
        this._customHttp.post(this.apiLogoutUrl, {}, {}, Constants.user_api_root).subscribe(
            response => {
                // destroying the cache all over the app.
                this._nukeService.nuke$.next({ notify: true });
                // redirect to sign in when successful
                this._router.navigate([`/${AppRoute.LOGIN}`]).then(nope => location.reload());

                // clear current user info from local storage
                this._store.dispatch(new ResetUser());

                // mixpanel analytics
                this._mixpanelService.eventTrack('logout', {});
            },
            error => {
                this._messageHandler.emitError(error);
            }
        );
    }

    forgotPassword(email: string) {
        const data = {
            email: email.trim(),
            url: `${window.location.protocol}//${window.location.host}/${AppRoute.CREATE_NEW_PASSWORD}/:uniqueToken`,
        };
        // mixpanel analytics
        this._mixpanelService.eventTrack('ForgotPassword', data);

        return this._customHttp.post('/forgotpassword', data, {}, Constants.user_api_root).pipe(
            map(r => {
                return r;
            })
        );
    }

    /**
     * @deprecated Refactor this to split in two different actions...
     * @param passwordData
     * @param token - The token is passed when going through the forgot password flow, otherwise, in the changePassword flow, it's undefined
     */
    createNewPassword(
        passwordData: { password: string; confirmPassword: string; oldPassword?: string; },
        token?: string
    ): Observable<string> {
        const endpoint = token ? '/confirmforgotpassword' : '/changepassword';
        // unique token has the form <CODE>||<EMAIL>
        const username = token?.split('||')[1];
        const code = token?.split('||')[0];
        const data = token
            ? {
                username,
                password: passwordData.password,
                code,
            }
            : {
                oldpassword: passwordData.oldPassword,
                newpassword: passwordData.password,
            };

        return this._customHttp.post(endpoint, data, {}, Constants.user_api_root).pipe(
            map(r => {
                return r;
            })
        );
    }

    public getImpersonationTokenData(): SLIdentTokenData | null {
        try {
            // try to decode as a JWT
            return this._jwtService.decodeToken(this._jwtService.tokenGetter());
        } catch (e) {
            return null;
        }
    }

    public getAuthorization(): Observable<SLAuthorization> {
        // leagueaffiliationsasobject=true means that the league affiliations will come back as objects
        // the param is hardcoded so we don't have to support two models for league affiliations
        // when making this call
        return this._customHttp.get('/users/authorization?leagueaffiliationsasobject=true', {}, Constants.api_root_v3);
    }

    public getUserAccount(): Observable<AccountAPIModel> {
        return this._customHttp.get('/account', {}, Constants.user_api_root);
    }

    public getCompany(companyId: string): Observable<CompanyAPIModel> {
        return this._customHttp.get(`/companies?companyid=${companyId}`, {}, Constants.user_api_root).pipe(map(r => r[0]));
    }
}
