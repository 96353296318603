import { Directive, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[click-stop-propagation]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ClickStopPropagation {
    @HostListener('click', ['$event'])
    public onClick(event): void {
        event.stopPropagation();
    }
}
