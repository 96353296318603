import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthService } from './auth.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UserSettingsFetchAll } from './user-settings.actions';
import { FetchUserAccount, FetchUserAuthorization, FetchUserCompany, SetSLIdentTokenData } from './user/user.actions';
import { UserState } from './user/user.state';

@Injectable()
export class UserInitializer {
    constructor(private _authService: AuthService, private _location: Location, private _store: Store) {}

    /* APP_INITIALIZER only supports Promise for now... there is a feature request opened on github
     * https://github.com/angular/angular/issues/15088
     */
    public initializeUser(): Promise<boolean> {
        const currentPath = this._location.path();
        if (!currentPath.includes('login')) {
            this._authService.redirectUrl = this._location.path();
        }

        return forkJoin([
            this._store.dispatch(new FetchUserAccount()),
            this._store.dispatch(new FetchUserAuthorization())
        ])
            .pipe(
                switchMap(() => this._store.select(UserState.user)),
                switchMap(user => {
                    return forkJoin([
                        this._store.dispatch(new FetchUserCompany(user.companyId)),
                        this._store.dispatch(new UserSettingsFetchAll()),
                    ]);
                }),
                take(1),
                map(() => {
                    // checking the impersonation token in synchronous
                    // it's not super important if it fails to retrieve it so it's not mandatory in the user initializer flow
                    const impersonationToken = this._authService.getImpersonationTokenData();
                    if (impersonationToken) {
                        this._store.dispatch(new SetSLIdentTokenData(impersonationToken));
                    }
                    return true;
                }),
                catchError(err => of(true))
            )
            .toPromise();
    }
}
