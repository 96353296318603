import { SeasonSummary, ManpowerPlayerLabel, PlayerSeasonSummaryV3Api, PlayerJerseyNumber } from './player.types';
import { Player } from './player.model';
import {
    isUndefined as _isUndefined,
    isNull as _isNull,
    isNaN as _isNaN,
    flatten as _flatten,
    isEmpty as _isEmpty,
    sortBy as _sortBy
} from 'lodash';
import { ListSelectionOptionData } from '@sportlogiq/shared/list-selection/list-selection-overlay.types';
import { PlayerModelAPI } from '../store/player/player.models';
import { abbrevFirstName } from '@sportlogiq/shared';
import { TeamModelAPI } from '../store/team/team.models';

export const getTOIForSeasonSummaries = (seasonSummaries: SeasonSummary[], manpower: ManpowerPlayerLabel): number => {
    return seasonSummaries.reduce((acc, value) => {
        acc += _calculateRegularSeasonTOI(acc, value, manpower);
        return acc;
    }, 0);
};

const _calculateRegularSeasonTOI = (acc: number, value: SeasonSummary, manpowerSituation: ManpowerPlayerLabel) => {
    const TOI = 0;

    if (!value) {
        return TOI;
    }

    if (!value.teams) {
        // eslint-disable-next-line no-console
        console.debug('player with no teams', this);
    }
    acc = value.teams.reduce((num, val) => {
        return num + parseFloat(val[`toiSeconds${manpowerSituation}Raw`]);
    }, TOI);
    return acc;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export const maybe = (fn: Function, thingToCheck: any, defaultValue?: any) => {
    const invalid = item => _isUndefined(item) || _isNull(item) || _isNaN(item);
    if (invalid(thingToCheck)) {
        return defaultValue || '';
    }
    const retVal = fn(thingToCheck);
    return invalid(retVal) ? defaultValue : retVal;
};

export const buildFreeAgency = (player: Player) => {
    const { freeAgencyStatus, freeAgencyYear, futureFreeAgencyStatus } = player;

    if (freeAgencyStatus !== 'contract') {
        return freeAgencyStatus;
    } else {
        return `${futureFreeAgencyStatus} ${freeAgencyYear}`;
    }
};

export const NUMBER_SUFFIXES = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

export const buildSalary = (player: Player) => {
    const salary = typeof player.currentSalary === 'string' ? +player.currentSalary : player.currentSalary;
    /* eslint-disable no-bitwise */
    const suffixPosition = salary > 0 ? (Math.log10(salary) / 3) | 0 : 0;
    /* eslint-enable no-bitwise */

    if (suffixPosition === 0) {
        return salary;
    }
    const suffix = NUMBER_SUFFIXES[suffixPosition];
    const scale = Math.pow(10, suffixPosition * 3);
    return '$' + (salary / scale).toFixed(1) + suffix;
};

export const buildAge = (player: Player) => {
    const today = new Date();
    const playerBirthDate = new Date(player.birthdate as string);
    const birthYear = playerBirthDate.getFullYear();
    const thisYear = today.getFullYear();
    const age = thisYear - birthYear;
    const monthDelta = today.getMonth() - playerBirthDate.getMonth();

    if (monthDelta < 0 || (monthDelta === 0 && today.getDate() < playerBirthDate.getDate())) {
        return age - 1;
    }
    return age;
};

export const playerListSelectionData = (players: PlayerModelAPI[], teamsByIds: Record<string, TeamModelAPI>): ListSelectionOptionData[] => {
    return players.map(player => {
        return {
            label: `${player.firstName} ${player.lastName}`,
            shorthand: abbrevFirstName(player.firstName, player.lastName),
            id: player.id,
            image: teamsByIds[player.currentTeam.id].logosrc,
        };
    });
};

/**
 * Returns the season summary that corresponds to the passed date
 * @param date
 * @param summaries
 * @param leagueId
 * @param seasonId
 * @param stage
 * @returns
 */
export const getPlayerSeasonSummaryForDate = (
    date: Date,
    summaries: PlayerSeasonSummaryV3Api[],
    leagueId: string,
    seasonId: string,
    stage: string
): PlayerSeasonSummaryV3Api | undefined => {
    // get an array of filtered summaries. If a player had multiple teams in a season stage
    // there will be multiple summary objects for the selected league/season/stage
    const filteredSummaries = summaries.filter(s => {
        return s.leagueId === leagueId && s.seasonId === seasonId && s.seasonStage === stage;
    });

    if (filteredSummaries.length === 0) {
        console.warn('Attempting to access player summary that does not exist');
        return;
    } else if (filteredSummaries.length === 1) {
        return filteredSummaries[0];
    }

    const sortedSummaries = _sortBy(filteredSummaries, s => new Date(s.mostRecentGameDate).getTime());

    const summaryToUse = sortedSummaries.find((summary, i) => {
        const mostRecentGameDateForSummary = new Date(summary.mostRecentGameDate);
        const previousSummaryExists = !!sortedSummaries[i - 1];
        const dawnOfTime = new Date();
        dawnOfTime.setTime(0);
        const mostRecentGameDateForPreviousSummary = previousSummaryExists
            ? new Date(sortedSummaries[i - 1]?.mostRecentGameDate)
            : dawnOfTime;


        return date > mostRecentGameDateForPreviousSummary && date <= mostRecentGameDateForSummary;
    });

    if (!summaryToUse) {
        console.warn('Attempting to access player summary that does not exist');
        return;
    }

    return summaryToUse;
};

/**
 * Returns a jersey number from the season summary that corresponds to the league/season/stage/team
 * passed to the method. Know that this method will return the latest jersey number for the team passed.
 * If you want to get the jersey number at a specific point in time then use getPlayerSeasonSummaryForDate
 * to find the season summary for a player on a specific date
 * @param summaries
 * @param leagueId
 * @param seasonId
 * @param stage
 * @param teamId
 * @returns
 */
export const getJerseyNumberFromSummaries = (
    summaries: PlayerSeasonSummaryV3Api[],
    leagueId: string,
    seasonId: string,
    stage: string,
    teamId: string
): string => {
    return summaries?.find(s =>
        s.leagueId ===leagueId
        && s.seasonId === seasonId
        && s.seasonStage === stage
        && s.teamId === teamId
    )?.jerseyNum;
};

export const getAbbreviatedNameWithCurrentJerseyNumber = (
    player: Player,
    date: string,
    seasonId: string,
    seasonStage: string,
    teamId: string): string => {
    if (!teamId) {
        return `#${player.currentTeam.jerseyNum} | ${abbrevFirstName(player.firstName, player.lastName)}`;
    }
    const convertedDate = new Date(date);

    const selectedSeasonsSummary = player.seasonsSummary.filter((season) =>
        seasonId === season.seasonId &&
        seasonStage === season.name
    );

    const seasonSummaryWithTeam = selectedSeasonsSummary.find((seasonSummary) => seasonSummary.teams.find(team => team.id === teamId));
    const currentTeam = seasonSummaryWithTeam.teams.find((team) => team.id === teamId);

    const jerseyNumberDetails = currentTeam.playerJerseyNum.find(playerJerseyNum => {
        const dateStart = new Date(playerJerseyNum.start);
        const dateEnd = new Date(playerJerseyNum.end);

        return convertedDate >= dateStart && convertedDate <= dateEnd;
    });

    if (!_isEmpty(jerseyNumberDetails)) {
        return `#${jerseyNumberDetails.jerseynumber} | ${abbrevFirstName(player.firstName, player.lastName)}`;
    }

    if (player.currentTeam?.jerseyNum) {
        return `#${player.currentTeam.jerseyNum} | ${abbrevFirstName(player.firstName, player.lastName)}`;
    }

    return abbrevFirstName(player.firstName, player.lastName);
};

/**
 * Returns an array of object representing jersey numbers that a player has had for a specific team ID
 * from the season summaries for that player
 * @param seasonSummaries V2 season summary
 * @param teamId
 * @returns
 */
export const getJerseyNumbersForTeamFromSeasonSummariesV2 = (
    seasonSummaries: SeasonSummary[],
    teamId: string
): PlayerJerseyNumber[] => {
    const jerseyNumObjectsForSelectedTeam = seasonSummaries.reduce((result, summary) => {
        const teamObjects = summary.teams.find(t => t.id === teamId);
        if (teamObjects) {
            result.push(teamObjects.playerJerseyNum);
        }
        return result;
    }, []);
    return _flatten(jerseyNumObjectsForSelectedTeam);
};
