import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        CoreModule.forRoot()
    ],
    exports: [],
    providers: []
})
export class RootImportModule { }
