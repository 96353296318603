/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import {
    simulateLivePlayerEvents,
    makeLastGameLive,
    makeFaceoffsLiveResponse,
    makeFaceoffsLivePreRequest,
    simulateLiveGame,
    getGamePlayerEvents,
    interceptTopPerformers,
} from './InterceptorFunctions';
import { switchMap } from 'rxjs/operators';
import { CustomHttp } from '@sportlogiq/core';
import {
    includes as _includes,
    isEmpty as _isEmpty
} from 'lodash';

const clockByID: Record<string, number> = {};

const rules: {
    url: string[];
    exact?: boolean;
    preRequest?: (request: HttpRequest<unknown>, http: CustomHttp) => Observable<any>;
    responseInterceptor: (data: any, clock: Record<string, number>, preRequestData: any) => any;
}[] = [
    {
        url: ['games'],
        exact: true,
        responseInterceptor: simulateLiveGame,
        preRequest: (req, http) => getGamePlayerEvents(req, http, clockByID),
    },
    {
        url: ['games', 'playerevents'],
        responseInterceptor: simulateLivePlayerEvents,
    },
    {
        url: ['leagues', 'seasons', 'games', 'includeupcoming'],
        responseInterceptor: makeLastGameLive,
    },
    {
        url: ['metricsetcollections', 'faceoffs', 'values', 'gameid'],
        responseInterceptor: makeFaceoffsLiveResponse,
        preRequest: (req, http) => makeFaceoffsLivePreRequest(req, http),
    },
    {
        url: ['gameTopPlayers'],
        responseInterceptor: interceptTopPerformers,
    },
];

const shouldBeIntercepted = (url: string) => {
    const lowercaseUrl = url.toLowerCase();
    const matchingRules = rules.filter(u => u.url.every(urlToMatch => lowercaseUrl.includes(urlToMatch)));
    const exactRules = matchingRules.filter(r => r.exact);
    const nonExactRules = matchingRules.filter(r => !r.exact);

    let matchingRule;
    if (!_isEmpty(exactRules)) {
        const urlParts = new URL(lowercaseUrl).pathname.split('/').slice(3);
        const urlPartsWithoutIds = urlParts.filter(part => isNaN(parseInt(part, 10)));

        const matchAllUrlParts = rules.filter(
            u => u.url.length === urlPartsWithoutIds.length && urlPartsWithoutIds.every(urlToMatch => _includes(u.url, urlToMatch))
        );
        matchingRule = matchAllUrlParts[0];
    }

    if (_isEmpty(exactRules) || !matchingRule) {
        matchingRule = nonExactRules[0];
    }

    return matchingRule;
};

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private customHttp: CustomHttp) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const interceptor = shouldBeIntercepted(request.url);
        if (!interceptor) {
            return next.handle(request);
        }

        if (!interceptor.preRequest) {
            interceptor.preRequest = req => of(null);
        }

        return interceptor.preRequest(request, this.customHttp).pipe(
            switchMap(preRequestData => forkJoin(next.handle(request), of(preRequestData))),
            switchMap((values: any) => {
                // eslint-disable-next-line prefer-const
                let [response, preRequestData] = values;
                if (response instanceof HttpResponse) {
                    // const interceptor = shouldBeIntercepted(event.url);
                    // eslint-disable-next-line no-console
                    console.debug('Request intercepted', request);
                    response = response.clone({ body: interceptor.responseInterceptor(response.body, clockByID, preRequestData) });
                }
                return of(response);
            })
        );
    }
}
