import { Routes } from '@angular/router';
import { AppRoute } from '@sportlogiq/app-routing.models';
import { AuthGuard } from '../core/auth-guard.service';
import { LoginComponent, LoginPageSectionsEnum } from './login.component';

export const loginRoutes: Routes = [
    {
        path: AppRoute.LOGIN,
        component: LoginComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'ROUTE_TITLES.Login',
            section: LoginPageSectionsEnum.Login,
        },
    },
    {
        path: AppRoute.FORGOT_PASSWORD,
        component: LoginComponent,
        data: {
            title: 'ROUTE_TITLES.ForgotPassword',
            section: LoginPageSectionsEnum.ForgotPassword,
        },
    },
    {
        path: `${AppRoute.CREATE_NEW_PASSWORD}/:uniqueToken`,
        component: LoginComponent,
        data: {
            title: 'ROUTE_TITLES.CreateNewPassword',
            section: LoginPageSectionsEnum.CreateNewPassword,
        },
    },
];
