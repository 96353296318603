/* eslint-disable @typescript-eslint/no-explicit-any */
import { OnInit, EventEmitter, Output, Component, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { MessageHandlerService } from '../../core/message-handler.service';
import { ErrorSnackbarComponent } from '../error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from '../success-snackbar/success-snackbar.component';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { CustomSnackBarOptions, CustomSnackBar } from '@sportlogiq/shared/types';
import { AppRoute } from '@sportlogiq/app-routing.models';

/**
 *
 *
 * @export
 * @class ContextMessageComponent
 */
@Component({
    selector: 'context-message',
    templateUrl: './context-message.component.html',
    styleUrls: ['./context-message.component.scss'],
    providers: [MatSnackBar],
})
export class ContextMessageComponent implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() error = new EventEmitter<string | number>();
    errorMessage: string | number;

    private _errors$: EventEmitter<any[]>;
    private _errorSub: EventEmitter<any[]>;
    private _success$: EventEmitter<string>;
    private _successSub: EventEmitter<string>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    private _contextSensitive: Boolean;
    private _contextRoutes: string[];

    private _custom$: EventEmitter<any>;

    constructor(
        private _messageHandler: MessageHandlerService,
        private _location: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _viewContainerRef: ViewContainerRef
    ) {
        this._errors$ = _messageHandler.errors;
        this._success$ = _messageHandler.success;
        this._custom$ = _messageHandler.custom;

        this._contextSensitive = false;

        // location.url.subscribe(route => {
        //     // TODO: Loop through an array of context sensitive routes
        //     if (this.isContextSensitiveRoute(route)) this.contextSensitive = true;
        // })
    }

    /**
     *
     * Displays error message returned from server
     * in custom snackbar
     *
     * If the route is context sensitive, display in
     * component on screen
     *
     * @param {*} errors
     * @returns
     *
     * @memberOf ContextMessageComponent
     */
    displayError(errors: any) {
        // TODO: Wrap EventEmitter and snackBar in Observable for debouncing
        // If there are multiple messges
        // let errorMessage = this.setProperMessage(errors);
        // If the message to be displayed is context sensitive,
        // display it inline.
        // TODO: Make proper fix for context sensitive messages
        // if (!this.contextSensitive) {
        //     this.errorMessage = errorMessage;
        //     this.onError.emit(this.errorMessage);
        //     // Reset the message after a couple of seconds
        //     window.setTimeout(_ => {
        //         this.errorMessage = undefined;
        //         this.onError.emit(this.errorMessage);
        //     }, 5000)
        //     return;
        // }

        // snackBar.open() returns a referrence to the currently open
        // snackBar instance. API MAY CHANGE
        const snackBarRef = this._snackBar.openFromComponent<ErrorSnackbarComponent>(ErrorSnackbarComponent, {
            panelClass: ['sliq-snackbar'],
        });
        snackBarRef.instance.message = this.setProperMessage(errors);
        if (!snackBarRef.instance.hasAction) {
            window.setTimeout(snackBarRef.dismiss.bind(snackBarRef), 2500);
        }
    }

    /**
     *
     * Displays a sucess snackbar on screen with custom message
     *
     * @param {string} msg
     *
     * @memberOf ContextMessageComponent
     */
    displaySuccess(msg: string) {
        const snackBarRef = this._snackBar.openFromComponent<SuccessSnackbarComponent>(SuccessSnackbarComponent, {
            panelClass: ['sliq-snackbar'],
        });

        snackBarRef.instance.message = msg;

        if (!snackBarRef.instance.hasAction) {
            window.setTimeout(snackBarRef.dismiss.bind(snackBarRef), 2500);
        }
    }

    displayCustom<T extends CustomSnackBar<T>>(obj: CustomSnackBarOptions<T>) {
        const component: any = obj.componentType ? obj.componentType : CustomSnackbarComponent;
        const snackBarRef = this._snackBar.openFromComponent<T>(component, obj.snackBarConfig);

        snackBarRef.instance.message = obj.message;
        snackBarRef.instance.theme = obj.theme;
        snackBarRef.instance.action = obj.actionMessage;
        snackBarRef.instance.snackBarRef = snackBarRef;
    }

    ngOnInit() {
        this._errors$.subscribe(values => this.displayError(values));
        this._success$.subscribe(value => this.displaySuccess(value));
        this._custom$.subscribe(value => this.displayCustom(value));
    }

    /**
     *
     * Checks to see if the route is context sensitive
     * based on routes defined in the method
     *
     * @private
     * @param {UrlSegment[]} route
     * @returns {boolean}
     *
     * @memberOf ContextMessageComponent
     */
    private isContextSensitiveRoute(route: UrlSegment[]): boolean {
        const routes = [`/${AppRoute.LOGIN}`];

        return !!routes.filter(sensitiveRoute => {
            return sensitiveRoute === route[0].path;
        });
    }

    /**
     *
     * Returns a message based on status
     *
     * @private
     * @param {*} request
     * @returns {string}
     *
     * @memberOf ContextMessageComponent
     */
    private setProperMessage(message = 'An Error Ocurred. Try Again Later') {
        // Return _extremely_ generic message for everything
        return message;

        // Leave this out for now.
        // switch (request.status) {
        //     case 500:
        //         return "Something went wrong!";

        //     default:
        //         return request._body ;
        // }
    }
}
