<div class="link-container"
    *ngIf="!menu && !tabs">
    <a class="link-item"
        *ngFor="let link of links"
        [ngClass]="link.classlist || []"
        [attr.title]="link.title || ''"
        (click)="link.clickCallback &&  validTabClick($event) && link.clickCallback(link, $event)"
        [attr.disabled]="link.disabled"
        [attr.href]="link.href || ''">
        <i [ngClass]="link.icon"
            *ngIf="link.icon"></i>
        <span class="link-label">{{link.label}}</span>
        <span *ngIf="link.tooltipText"
            class="fa fa-info-circle"
            [ngClass]="tooltipIconClass"
            #tooltipIcon></span>
    </a>
</div>

<nav class="rendered-tabs"
    mat-tab-nav-bar [disablePagination]="true"
    *ngIf="!menu && tabs">
    <a class="mat-tab"
        *ngFor="let link of links"
        mat-tab-link
        [routerLink]="link.href"
        [queryParams]="link.queryParams || {}"
        [ngClass]="link.classlist || []"
        [class.active]="(link.href && link.isActive) || link.isActive"
        routerLinkActive
        #rla="routerLinkActive"
        [routerLinkActiveOptions]="{exact: true}"
        [disabled]="link.disabled"
        [active]="(link.href && rla.isActive) || link.isActive"
        (click)="link.clickCallback && validTabClick($event) && link.clickCallback(link, $event)">
        <i [ngClass]="link.icon"
            *ngIf="link.icon"></i>
        <span class="link-label">{{link.label}}</span>
        <span *ngIf="link.tooltipText"
            class="fa fa-info-circle"
            [ngClass]="tooltipIconClass"
            #tooltipIcon></span>
    </a>
</nav>

<div class="link-container"
    *ngIf="menu">
    <a class="link-item"
        *ngFor="let link of links"
        mat-menu-item
        [routerLink]="link?.href"
        [ngClass]="link.classlist || []"
        [attr.disabled]="link.disabled"
        [attr.title]="link.title || ''"
        (click)="link.clickCallback  && validTabClick($event) && link.clickCallback(link, $event)">
        <i [ngClass]="link.icon"
            *ngIf="link.icon"></i>
        <span class="link-label">{{link.label}}</span>
        <span *ngIf="link.tooltipText"
            class="fa fa-info-circle"
            [ngClass]="tooltipIconClass"
            #tooltipIcon></span>
    </a>
</div>
