import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[animOnChange]',
})
export class AnimOnChangeDirective {
    constructor(elem: ElementRef, renderer: Renderer2, private router$: Router, routerOutlet: RouterOutlet) {
        router$.events.subscribe(event => {
            /* if (event instanceof NavigationEnd && event.url.indexOf('login') === -1) {
                renderer.setElementStyle(elem.nativeElement.firstElementChild, 'display', 'initial');
                renderer.setElementStyle(elem.nativeElement.nextSibling.firstElementChild, 'opacity', '0');
                window.setTimeout(_ => {
                    renderer.setElementStyle(elem.nativeElement.firstElementChild, 'display', 'none');
                    renderer.setElementStyle(elem.nativeElement.nextSibling.firstElementChild, 'opacity', '1');
                }, 1000)
            }*/
        });
    }
}
