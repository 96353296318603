import { ActivatedRoute, Router } from '@angular/router';
import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UserService, MessageHandlerService, AuthService } from 'app/core';
import { Store } from '@ngxs/store';
import { UserState } from '@sportlogiq/core/user/user.state';
import { ForgotPasswordNavigationState } from '@sportlogiq/login/login.model';
import { AppRoute } from '@sportlogiq/app-routing.models';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent {
    @ViewChild('createNewPasswordForm') createNewPasswordForm;

    @Output() changePasswordSuccessful: EventEmitter<unknown>;

    public success: boolean;
    public serverError: string;
    public userLoggedIn = this._store.selectSnapshot(UserState.user);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public _formModel: any;
    public _submitted: boolean;
    public _loading: boolean;
    private _uniqueToken: string;

    constructor(
        private _userService: UserService,
        private _authService: AuthService,
        private _messageHandler: MessageHandlerService,
        private _activatedRoute: ActivatedRoute,
        private _cd: ChangeDetectorRef,
        private _store: Store,
        private _router: Router
    ) {
        this._formModel = {};
        this.changePasswordSuccessful = new EventEmitter<unknown>();
        // this unique token only has a value when changing the password through the "Forgot Password" functionality.
        this._uniqueToken = this._activatedRoute.snapshot.params.uniqueToken;
    }

    public createNewPassword() {
        this._submitted = true;
        this.success = false;
        this.serverError = undefined;
        if (this.createNewPasswordForm.valid) {
            this._loading = true;
            const username = this._uniqueToken?.split('||')[1];
            this._authService.createNewPassword(this._formModel, this._uniqueToken).subscribe(
                r => {
                    this.success = true;
                    this._loading = false;
                    this.changePasswordSuccessful.emit({ user: username, password: this._formModel.password });
                    this._messageHandler.emitSuccess('Password changed successfully!');
                    this._cd.markForCheck();
                },
                e => {
                    this._loading = false;

                    // redirect to forgot-password if status code is 410
                    if (e?.status === 410) {
                        const forgotPasswordNavigationState: ForgotPasswordNavigationState = {
                            status: e.status,
                            message: e.message,
                            email: username,
                        };
                        this._router.navigateByUrl(`/${AppRoute.FORGOT_PASSWORD}`, {
                            state: forgotPasswordNavigationState,
                        });
                        return;
                    }

                    // todo, make changes to backend so that error are returned in json...
                    this.serverError = e._body; // this _body does not exist in the json response
                    this._messageHandler.emitError(e.error);
                    this._cd.markForCheck();
                }
            );
        }
    }
}
