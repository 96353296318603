import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ValueProvider, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { AnimOnChangeDirective } from './anim-on-change.directive';
import { BreadcrumbService } from './core/breadcrumb.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routing } from './app.routing';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RootImportModule } from './root-import.module';
import { environment } from '../environments/environment';
import { RouterOutletStubComponent } from '../../testing/router.stubs';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleDefaultOptions } from '@angular/material/button-toggle';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsErrorHandlerPluginModule } from '@sportlogiq/main/core/ngxs-plugins/error/error-handler-plugin.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HttpMockRequestInterceptor } from './interceptors/HttpMockRequestInterceptor';
import { NoopHttpRequestInterceptor } from './interceptors/NoopHttpRequestInterceptor';
import { ContextMessageComponent } from './shared/context-message/context-message.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { UserInitializer } from './core/user.initializer';
import { AppLoaderService } from '@sportlogiq/core/app-loader.service';
import { VersionCheckService } from './core/version-check.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-var
declare var FS: any;

if (!environment.production) {
    // Disable FullStory console overwriting when in development mode
    // eslint-disable-next-line @typescript-eslint/dot-notation, brace-style
    window['_fs_ready'] = () => {
        FS.disableConsole();
    };
}
const WINDOW_PROVIDER: ValueProvider = {
    provide: 'Window',
    useValue: window,
};

export function initUserFactory(userInitializer: UserInitializer) {
    return () => userInitializer.initializeUser();
}

@NgModule({
    imports: [
        RootImportModule,
        BrowserModule,
        BrowserAnimationsModule,
        routing,
        LoginModule,
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: environment.production,
            },
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: true }),
        NgxsRouterPluginModule.forRoot(),
        NgxsErrorHandlerPluginModule.forRoot(),
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule,
        MatNativeDateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: exportStaticLoader,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [RouterOutletStubComponent, AppComponent, AnimOnChangeDirective, ContextMessageComponent],
    providers: [
        BreadcrumbService,
        AppLoaderService,
        WINDOW_PROVIDER,
        UserInitializer,
        { provide: APP_INITIALIZER, useFactory: initUserFactory, deps: [UserInitializer], multi: true },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { appearance: 'legacy' } as MatButtonToggleDefaultOptions },
        environment.simulateLive
            ? {
                provide: HTTP_INTERCEPTORS,
                useClass: HttpMockRequestInterceptor,
                multi: true,
            }
            : {
                provide: HTTP_INTERCEPTORS,
                useClass: NoopHttpRequestInterceptor,
                multi: true,
            },
        VersionCheckService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function exportStaticLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
