import { map } from 'rxjs/operators';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    ClearContextPlayerEvents,
    FetchGameEventDefinitions,
    FetchPlayerEventDefinitions,
    SetContextPlayerEvents,
    FetchFlagEventDefinitions,
} from './event.actions';
import { EventService } from './event.service';
import { eventStateDefaults, EventStateModel, FlagEventDefinition } from './event.models';
import { isUndefined as _isUndefined, keyBy as _keyBy, reduce as _reduce } from 'lodash';

@State<EventStateModel>({
    name: 'eventState',
    defaults: eventStateDefaults,
})
@Injectable({
    providedIn: 'root',
})
export class EventState {
    constructor(private _eventService: EventService) {}

    @Action(FetchGameEventDefinitions)
    private _fetchGameEventDefinitions(ctx: StateContext<EventStateModel>) {
        if (!_isUndefined(ctx.getState().gameEventDefinitions)) {
            return;
        }
        return this._eventService.getGameEventDefinitionsAPI().pipe(
            map(events => {
                ctx.patchState({
                    gameEventDefinitions: _keyBy(events, e => e.id),
                });
            })
        );
    }

    @Action(FetchPlayerEventDefinitions)
    private _fetchPlayerEventDefinitions(ctx: StateContext<EventStateModel>) {
        if (!_isUndefined(ctx.getState().playerEventDefinitions)) {
            return;
        }
        return this._eventService.getPlayerEventDefinitionsAPI().pipe(
            map(events => {
                ctx.patchState({
                    playerEventDefinitions: _keyBy(events, e => e.id),
                });
            })
        );
    }

    @Action(SetContextPlayerEvents)
    private _setContextPlayerEvents({ patchState, getState }: StateContext<EventStateModel>, { playerEvents }: SetContextPlayerEvents) {
        const playerEventDictionary = { ...(getState().contextPlayerEvents || {}) };

        patchState({
            contextPlayerEvents: _reduce(
                playerEvents,
                (acc, pl) => {
                    acc[pl.id] = pl;
                    return acc;
                },
                playerEventDictionary
            ),
        });
    }

    @Action(ClearContextPlayerEvents)
    private _clearContextPlayerEvents({ patchState }: StateContext<EventStateModel>) {
        patchState({
            contextPlayerEvents: {},
        });
    }

    @Action(FetchFlagEventDefinitions)
    private _fetchFlagEventDefinitions(ctx: StateContext<EventStateModel>) {
        if (!_isUndefined(ctx.getState().flagEventDefinitions)) {
            return;
        }
        return this._eventService.getFlagEventDefinitionsAPI().pipe(
            map(events => {
                ctx.patchState({
                    flagEventDefinitions: _keyBy(events, e => e.id),
                });
            })
        );
    }

    @Selector()
    public static gameEventDefinitions(eventState: EventStateModel) {
        return eventState.gameEventDefinitions;
    }

    @Selector()
    public static playerEventDefinitions(eventState: EventStateModel) {
        return eventState.playerEventDefinitions;
    }

    @Selector()
    public static contextPlayerEvents(eventState: EventStateModel) {
        return eventState.contextPlayerEvents;
    }

    @Selector()
    public static flagEventDefinitions(eventState: EventStateModel) {
        return eventState.flagEventDefinitions;
    }

    @Selector()
    public static flagEventDefinitionsByName(eventState: EventStateModel) {
        return _reduce(eventState.flagEventDefinitions, (acc, f) => {
            acc[f.name] = f;
            return acc;
        }, {} as Record<string, FlagEventDefinition>);
    }
}
