export interface SlPartnerAuthentication {
    token: string;
}

export enum IcePartner {
    TABLEAU = 'tableau',
    ZENDESK_CHAT = 'zendeskChat',
    ZENDESK_HELP_CENTER = 'zendeskHelpCenter',
}

export interface PartnerAuthenticationTimeAndToken {
    token: string;
    time: Date;
}

export interface PartnerStateModel {
    timeAndTokenByPartner: Record<IcePartner, PartnerAuthenticationTimeAndToken>;
}

export const partnerStateDefaults: PartnerStateModel = {
    timeAndTokenByPartner: {
        [IcePartner.TABLEAU]: undefined,
        [IcePartner.ZENDESK_CHAT]: undefined,
        [IcePartner.ZENDESK_HELP_CENTER]: undefined,
    },
};
