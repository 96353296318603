import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerNameById, DateFormat, GameStatusPipe, GetSeasonLabelPipe, PlayerQuickRefPipe, GetMinDateWithPipe, IOSDatePipe, TransformFaceoffNamePipe, FilterAttributePipe, PlayerNamesPipe, PlayerIdToPlayerName, TeamIdToTeamShorthand, FormatPeriodPipe } from './sl-pypes';
import { SlFormatPlayerNamePipeModule } from './sl-format-player-name-pipe.module';
import { PlayerFullNamePipe } from '@sportlogiq/main/core/store/player/pipes';
@NgModule({
    imports: [
        CommonModule,
        SlFormatPlayerNamePipeModule
    ],
    declarations: [
        PlayerFullNamePipe,
        DateFormat,
        GameStatusPipe,
        GetSeasonLabelPipe,
        PlayerQuickRefPipe,
        GetMinDateWithPipe,
        IOSDatePipe,
        TransformFaceoffNamePipe,
        FilterAttributePipe,
        PlayerNamesPipe,
        PlayerIdToPlayerName,
        TeamIdToTeamShorthand,
        FormatPeriodPipe,
        PlayerNameById
    ],
    exports: [
        PlayerFullNamePipe,
        DateFormat,
        GameStatusPipe,
        GetSeasonLabelPipe,
        PlayerQuickRefPipe,
        GetMinDateWithPipe,
        IOSDatePipe,
        TransformFaceoffNamePipe,
        FilterAttributePipe,
        PlayerNamesPipe,
        PlayerIdToPlayerName,
        TeamIdToTeamShorthand,
        FormatPeriodPipe,
        PlayerNameById
    ],
    providers: [
        PlayerFullNamePipe
    ]
})
export class PipeModule { }
