import { Injectable } from '@angular/core';
import { Constants, stringifyParamFilters } from '@sportlogiq/shared';
import { map, Observable } from 'rxjs';
import { CustomHttp } from '../custom-http.service';
import { EventSequences, FlagEventDefinition, GameEventDefinition, PlayerEventDefinition, PlayerEventV3, PlayerEventV3Params, TrackingData } from './event.models';

@Injectable()
export class EventService {
    constructor(private _http: CustomHttp) {}

    public getGameEventDefinitionsAPI(): Observable<GameEventDefinition[]> {
        return this._http.get(`/gameeventdefinitions`, undefined, Constants.api_root_v3);
    }

    public getPlayerEventDefinitionsAPI(): Observable<PlayerEventDefinition[]> {
        return this._http.get(`/playereventdefinitions`, undefined, Constants.api_root_v3);
    }

    public getFlagEventDefinitionsAPI(): Observable<FlagEventDefinition[]> {
        return this._http.get(`/flagdefinitions`, undefined, Constants.api_root_v3);
    }

    public getPlayerEventsAPI(params: PlayerEventV3Params): Observable<PlayerEventV3[]> {
        return this._http.get(`/playerevents${stringifyParamFilters(params, true)}`, undefined, Constants.api_root_v3);
    }

    // eslint-disable-next-line max-len
    public getTrackingDataAPI(gameid: string, period: number, startframe: number, endframe: number ): Observable<Record<string, TrackingData[]>> {
        return this._http.get(`/externaltracking/tracks?gameid=${gameid}&period=${period}&sourceid=1&from=${startframe}&to=${endframe}`, undefined, Constants.api_root_v3).pipe(
            map(d => d.frames)
        );
    }

    public getPlayerEventSequencesAPI(gameid: string): Observable<EventSequences> {
        /* return this.getPlayerEventsAPI({ gameid: [gameid], period: ['1'] }).pipe(
            map(events => {
                return {
                    shotsequences: [
                        [
                            events[0],
                            events[5],
                            events[10],
                        ],
                        [
                            events[100],
                            events[105],
                            events[110],
                        ],
                        [
                            events[135],
                            events[160],
                            events[180],
                        ]
                    ],
                    faceoffsequences: [
                        [
                            events[200],
                            events[205],
                            events[210],
                        ],
                        [
                            events[300],
                            events[305],
                            events[310],
                        ]
                    ]
                };
            })
        ); */
        return this._http.get(`/playerevents/${gameid}/sequences`, undefined, Constants.api_root_v3).pipe(
            map(r => r.sequences)
        );
    }
}
