import { NewPlayerEvent, PlayerEventName, PlayerEventType } from '@sportlogiq/main';

export interface GameEventDefinition {
    id: string;
    label: string;
    name: string;
    type: string;
    clockImpact: string;
    playImpact: string;
}

export interface FlagEventDefinition {
    id: string;
    label: string;
    name: string;
}

export interface PlayerEventDefinition {
    id: string;
    label: string;
    name: PlayerEventName;
    type: PlayerEventType;
    outcome: string;
    zone: string;
}
export interface PlayerEventV3 {
    id:                string;
    gameId:            string;
    teamId:            string;
    playerId:          string;
    secondaryPlayerId: null | string;
    period:            number;
    frame:             number;
    vidId:             string;
    defId:             string;
    periodTime:        number;
    gameTime:          number;
    xCoord:            number;
    yCoord:            number;
    xAdjCoord:         number;
    yAdjCoord:         number;
    flags:             string[] | null;
    updatedOn:         null;
}

export interface PlayerEventV3Params {
    gameid: string[];
    period: string[];
}
export interface EventStateModel {
    gameEventDefinitions: Record<string, GameEventDefinition>;
    playerEventDefinitions: Record<string, PlayerEventDefinition>;
    contextPlayerEvents: Record<string, NewPlayerEvent>;
    flagEventDefinitions: Record<string, FlagEventDefinition>;
}

export const eventStateDefaults: EventStateModel = Object.freeze({
    gameEventDefinitions: undefined,
    playerEventDefinitions: undefined,
    contextPlayerEvents: undefined,
    flagEventDefinitions: undefined,
});

export type EventSequences = Record<string, PlayerEventV3[][]>;

// Generated by https://quicktype.io

export interface TrackingData {
    frame:       number;
    teamId:      null | string;
    itemClassId: string;
    jerseyNum:   string;
    coords:      TrackingDataCoords;
    playerId:    string;
}

export interface TrackingDataCoords {
    x: number;
    y: number;
}
