import { SeasonStageType } from '@sportlogiq/main/shared/sl-types';
import { EventContext } from '@sportlogiq/shared';
import { ScoreDifferentialTypes } from '@sportlogiq/shared/score-differential-selection/score-differential-selection.types';
import { FilterOperators } from '@sportlogiq/shared/toi-filter/toi-filter-selection.types';
import { ArenaOptions } from '../statistics.types';
import { AdvancedStatsQueryParams, ProratingOptionValue, StatsTypeForUI } from './advanced-stats.types';

export class StatsTypeAction {
    public static readonly type = '[AdvancedStats] Stats Type Tab';
    constructor(public selectedTab: StatsTypeForUI) {}
}

export class FetchAdvancedStatMetricTopics {
    public static readonly type = '[AdvancedStats] Fetch Metric Topics';
}

export class FetchAdvancedStatMetrics {
    public static readonly type = '[AdvancedStats] Fetch Metrics';
}

export class FetchAdvancedStatData {
    public static readonly type = '[AdvancedStats] Fetch Data';
    constructor(public leagueId: string, public seasonId: string, public seasonStage: SeasonStageType) {}
}

export class FetchAdvancedStatEvents {
    public static readonly type = '[AdvancedStats] Fetch events';
    constructor(
        public leagueId: string,
        public seasonId: string,
        public seasonStage: SeasonStageType,
        public identityId: string,
        public metricName: string,
        public max: number
    ) {}
}

export class SelectTopicMetrics {
    public static readonly type = '[AdvancedStats] Select Metric Topic id';
    constructor(public metricTopicId: string) {}
}

export class SelectTopicCategory {
    public static readonly type = '[AdvancedStats] Select Metric category';
    constructor(public metricCategory: string) {}
}

export class AdvancedStatsResetState {
    public static readonly type = '[AdvancedStats] Reset State';
}

export class AdvancedStatsUpdateState {
    public static readonly type = '[AdvancedStats] Update State';
    constructor(public params: Partial<AdvancedStatsQueryParams>) {}
}

export class AdvancedStatsEventContextAction {
    public static readonly type = '[AdvancedStats] Event Context';
    constructor(public eventContext: EventContext) {}
}

export class SelectTeams {
    public static readonly type = '[AdvancedStats] Select Teams';
    constructor(public selectedTeamIds: string[]) {}
}

export class SelectGoalieAction {
    public static readonly type = '[AdvancedStats] Select Goalies';
    constructor(public selectedGoalies: Set<string>) {}
}

export class SetDefaultGoalieAction {
    public static readonly type = '[AdvancedStats] Set Default Selected Goalies';
    constructor(public selectedGoalies: string[]) {}
}

export class FetchContextStatsData {
    public static readonly type = '[AdvancedStats] Fetch Context Stats Data';
    constructor(public leagueId: string, public seasonId: string, public seasonStage: string) {}
}
export class SelectArena {
    public static readonly type = '[AdvancedStats] Select Arena';
    constructor(public arena: ArenaOptions) {}
}

export class SelectProrating {
    public static readonly type = '[AdvancedStats] Select Prorating';
    constructor(public prorating: ProratingOptionValue) {}
}

export class SelectManpower {
    public static readonly type = '[AdvancedStats] Select Manpower';
    constructor(public selectedManpowerIds: string[]) {}
}

export class SelectPeriod {
    public static readonly type = '[AdvancedStats] Select Period';
    constructor(public selectedPeriods: number[]) {}
}

export class SelectScoreDifferential {
    public static readonly type = '[AdvancedStats] Select ScoreDifferential';
    constructor(public scoreDifferentialType: ScoreDifferentialTypes, public scoreDifferentialValue: string[]) {}
}

export class SelectToiFilter {
    public static readonly type = '[AdvancedStats] Select TOI Filter';
    constructor(public toiFilterType: FilterOperators, public toiFilterValue: number) {}
}

export class SelectPositionAction {
    public static readonly type = '[AdvancedStats] Select Positions Filter';
    constructor(public positions: Set<string>) {}
}

export class SelectAdvancedDaterange {
    public static readonly type = '[AdvancedStats] Set Selected Advanced Daterange';
    constructor(public optionId: string, public start?: Date, public end?: Date) {}
}

export class ClearAdvancedStatsFilters {
    public static readonly type = '[AdvancedStats] Clear All Filters';
}

export class SearchPlayerNameAction {
    public static readonly type = '[AdvancedStats] Search Player Name Filter';
    constructor(public searchedPlayerName: string) {}
}

export class SetPageAction {
    public static readonly type = '[AdvancedStats] Set Page';
    constructor(public page: number) {}
}
