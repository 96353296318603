import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class BreadcrumbService {
    public previousRoute: string;
    public currentRoute: string;

    constructor(private _router: Router, private _location: Location) {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.previousRoute = location.pathname;
                this.currentRoute = this.previousRoute;
            }

            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
        });
    }

    get history() {
        return window.history;
    }

    back() {
        this._location.back();
    }
}
