import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { CanvasOptionsService } from './core/canvasoptions.service';
import { TranslateService } from '@ngx-translate/core';
import { SlTitleService } from './core/sl-title.service';
import { VersionCheckService } from './core/version-check.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [CanvasOptionsService],
})
export class AppComponent {
    public canvasControlsExpanded;

    constructor(
        private _translate: TranslateService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _canvasControls: CanvasOptionsService,
        private _titleService: SlTitleService,
        private _versionCheckService: VersionCheckService
    ) {
        this._versionCheckService.init();

        this.canvasControlsExpanded = false;
        this._translate.addLangs(['en', 'fr']);
        this._translate.setDefaultLang('en');

        const browserLang = this._translate.getBrowserLang();
        this._translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

        this._canvasControls.canvasExpanded$.subscribe(event => {
            this.canvasControlsExpanded = event;
        });

        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._titleService.setTitle();
                // Reset scroll position on route change
                const mainContainer = document.querySelector('.main-content-container');
                if (mainContainer && !this._route.snapshot.fragment) {
                    mainContainer.scrollTo(0, 0);
                }
            }
        });
    }
}
