export enum UserSettingDefinitionTypes {
    expectedGoals = 'expectedGoals',
    goalieSituation = 'goalieSituationxGValues',
}

export interface UserSettingDefinition {
    id: string;
    name: string;
    type: UserSettingDefinitionTypes;
}

export interface UserSetting {
    id: string;
    value: string;
    updatedOn: string;
    source?: string;
}

export interface SelectedShadowLineage {
    requested: string;
    userSettings: UserSettings;
    default: ShadowType;
}

export interface UserSettings {
    cookie: string;
    company: string;
    user: string;
}

export interface Shadow {
    shadowName: string;
    label: string;
}

export enum ShadowType {
    aaxg = 'AAxG',
    onxg = 'ONxG',
    includeEmptyNetSituations = 'Include Empty Net Situations',
    excludeEmptyNetSituations = 'Exclude empty net Situations',
}

export interface VirtualMetric {
    selectedShadow: ShadowType;
    selectedShadowLineage: SelectedShadowLineage;
    shadows: Shadow[];
}

export interface VirtualMetrics {
    expectedGoals: VirtualMetric;
    goalieSituationxGValues: VirtualMetric;
}

export interface VirtualMetricsAPIModel {
    virtualMetricsEnums: VirtualMetrics;
}

export interface UserSettingsStateModel {
    virtualMetrics: VirtualMetricsAPIModel;
    userSettingDefinitions: UserSettingDefinition[];
    userSettings: UserSetting[];
}

export const userSettingsStateDefault: UserSettingsStateModel = {
    virtualMetrics: undefined,
    userSettingDefinitions: undefined,
    userSettings: undefined,
};
