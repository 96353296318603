import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from './user/user.state';
import { UserService } from './user.service';
import {
    some as _some
} from 'lodash';

@Injectable()
export class PermissionGuard implements CanActivate {
    constructor(private _router: Router, private _store: Store, private _userService: UserService) {}

    // eslint-disable-next-line complexity
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const CAN_ACCESS_ALL_CHILDREN = route.data.canAccessAllChildren;
        const CAN_ACCESS_ROUTE = route.data.canAccess ? route.data.canAccess : route.firstChild?.data.canAccess;
        const REDIRECT_TO = route.data.redirectToOnFail
            ? route.data.redirectToOnFail
            : route.firstChild?.data.redirectToOnFail;
        const checkPerm = perm => this._userService.checkForPermission(perm); // function to keep scope of `this`

        // If they're a super user, let them through.
        if (this._store.selectSnapshot(UserState.isSuperUser)) {
            return true;
        }

        // If there's no permissions on the route, it's unprotected.
        // Let the user through.
        if (!CAN_ACCESS_ALL_CHILDREN && !CAN_ACCESS_ROUTE) {
            return true;
        }

        // If a route has a specific permission and the user has it,
        // let them through.
        if (_some(CAN_ACCESS_ROUTE, checkPerm)) {
            return true;
        }

        // If they have the magic permission which allows the user to view
        // all children, let them through.
        if (_some(CAN_ACCESS_ALL_CHILDREN, checkPerm)) {
            return true;
        }

        // If the route defines a redirect to if failed permission check
        // navigate to the route specified
        if (REDIRECT_TO) {
            this._router.navigateByUrl(REDIRECT_TO);
            return;
        }

        // Send them home if they make it this far.
        this._router.navigateByUrl('/');
        return false;
    }
}
