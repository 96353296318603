/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, EventEmitter } from '@angular/core';
import { CustomSnackBarOptions, CustomSnackBar } from '../shared/types';

@Injectable()
export class MessageHandlerService {
    public errors: EventEmitter<any[]>;
    public success: EventEmitter<string>;
    public custom: EventEmitter<any>;

    constructor() {
        this.errors = new EventEmitter<any[]>();
        this.success = new EventEmitter<string>();
        this.custom = new EventEmitter<any>();
    }

    /**
     * Emits an the error passed in
     * to whatever handlers are .subscribed()
     *
     * To be passed as a function, you'll need to .bind()
     * it correctly in the constructor:
     *  this.messageHandler.emitError.bind(errorHandler)
     *
     * @param {*} error - The error you'd like to pass in
     * @emits {*} error
     *
     * @memberOf MessageHandlerService
     */
    emitError(error: any) {
        if (typeof error === 'string' || error instanceof String) {
            error = error.toUpperCase();
        }
        this.errors.emit(error);
    }

    /**
     *
     * Emits the success message of your choice
     * to handlers that have .subscribed()
     *
     * To be passed as a function, you'll need to .bind()
     * it correctly in the constructor:
     *  this.messageHandler.emitSuccess.bind(errorHandler)
     *
     * @param {string} [message="Success!"]
     * @emits {string} message
     *
     * @memberOf MessageHandlerService
     */
    emitSuccess(message = 'Success!') {
        const successMessage = message;
        this.success.emit(successMessage);
    }

    emitCustom<T extends CustomSnackBar<T>>(obj: CustomSnackBarOptions<T>) {
        this.custom.emit(obj);
    }
}
