import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

/**
 *
 * A custom variation of the MatSnackBar class
 * All properties are required to make it function
 *
 * @export
 * @class ErrorSnackbarComponent
 */
@Component({
    selector: 'error-snackbar',
    templateUrl: './error-snackbar.component.html',
    styleUrls: ['./error-snackbar.component.scss'],
})
export class ErrorSnackbarComponent {
    message: string;
    action: string;
    /**
     * @type {MatSnackBarRef<ErrorSnackbarComponent>}
     * @memberOf ErrorSnackbarComponent
     */
    snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>;

    get hasAction(): boolean {
        return !!this.action;
    }

    dismiss(): void {
        this.snackBarRef.dismissWithAction();
    }
}
