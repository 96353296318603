<img *ngIf="!beta && !mini"
    class="logo"
    src="/assets/iCE_SL.svg"
    alt="SPORTLOGiQ" />

<img *ngIf="beta && logoStyle !== 'dark' && !mini"
    class="logo"
    src="/assets/ice-short.svg"
    alt="SPORTLOGiQ ">
<img *ngIf="beta && logoStyle === 'dark' && !mini"
    class="logo"
    src="/assets/ice-short-black.svg"
    alt="SPORTLOGiQ">

<div class="logo"
    *ngIf="mini"
    [inlineSVG]="'/assets/ice-short.svg'"></div>