import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { PermissionGuard } from '../../core/permission-guard.service';
import { CommonModule } from '@angular/common';
import { LinkRendererModule } from '../shared/link-renderer/link-renderer.module';
import { PipeModule } from '../shared/pipes/sl-types.module';
import { ScoutingComponent } from './scouting.component';
import { AppFooterModule } from '../shared/app-footer/app-footer.module';
import { UserPermissions } from '@sportlogiq/shared';

export const SCOUTING_MAIN_ROUTE = 'scouting';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        AppFooterModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        LinkRendererModule,
        PipeModule,
        RouterModule.forChild([
            {
                path: '',
                canActivate: [PermissionGuard],
                component: ScoutingComponent,
                data: {
                    canAccess: [
                        UserPermissions.canViewScoutingPlayerVideo,
                        UserPermissions.canViewScoutingPlayerProfile,
                        UserPermissions.canViewScoutingPlayerComparison,
                    ]
                },
                children: [
                    {
                        path: 'player-video',
                        loadChildren: () =>
                            import('@sportlogiq/main/scouting/player-video/player-video.module').then((m) => m.PlayerVideoModule),
                        canActivate: [PermissionGuard],
                        data: {
                            canAccess: [
                                UserPermissions.canViewScoutingPlayerVideo
                            ],
                            redirectToOnFail: `/${SCOUTING_MAIN_ROUTE}/player-comparison`,
                        },
                    },
                    {
                        path: 'player-profile',
                        loadChildren: () => import(
                            '@sportlogiq/main/scouting/player-profile/player-profile.module'
                        ).then((m) => m.PlayerProfileModule),
                        canActivate: [PermissionGuard],
                        data: {
                            canAccess: [
                                UserPermissions.canViewScoutingPlayerProfile
                            ],
                            redirectToOnFail: '/',
                        },
                    },
                    {
                        // what was previously called Scouting is now Player Profile so we redirect
                        // any old URLs
                        path: 'scouting',
                        redirectTo: 'player-profile',
                    },
                    {
                        path: 'player-comparison',
                        loadChildren: () =>
                            import('@sportlogiq/main/scouting/player-comparison/player-comparison.module').then(
                                (m) => m.PlayerComparisonModule
                            ),
                        canActivate: [PermissionGuard],
                        data: {
                            canAccess: [
                                UserPermissions.canViewScoutingPlayerComparison,
                            ],
                            redirectToOnFail: `/${SCOUTING_MAIN_ROUTE}/scouting`,
                        },
                    },
                    {
                        path: '',
                        redirectTo: 'player-video',
                        pathMatch: 'full',
                    },
                ],
            },
        ]),
    ],
    declarations: [ScoutingComponent],
})
export class ScoutingModule {}
