import { getContrastYIQ, getTeamFullname } from '@sportlogiq/shared';

export class Team {
    id: string;
    awayprimarycolor: string;
    awaysecondarycolor: string;
    defaulthomeozonright: string;
    homeprimarycolor: string;
    homesecondarycolor: string;
    backgroundColor: string;
    textColor: string;
    leagueid: string;
    location: string;
    logosrc: string;
    name: string;
    fullName: string;
    shorthand: string;
    displayName: string;

    // eslint-disable-next-line complexity
    constructor(obj) {
        this.id = (obj?.id) || null;
        this.awayprimarycolor = (obj?.awayprimarycolor) || null;
        this.awaysecondarycolor = (obj?.awaysecondarycolor) || null;
        this.defaulthomeozonright = obj.defaulthomeozonright || null;
        this.homeprimarycolor = obj?.homeprimarycolor !== '' ? obj.homeprimarycolor : null;
        this.homesecondarycolor = (obj?.homesecondarycolor) || null;
        this.backgroundColor = this.homeprimarycolor || 'ffffff';
        this.textColor = getContrastYIQ(this.backgroundColor);
        this.leagueid = (obj?.leagueid) || '';
        this.location = (obj?.location) || '';
        this.logosrc = (obj && (obj.logoSrc || obj.logosrc)) || '';
        this.name = (obj?.name) || '';
        this.fullName = getTeamFullname(this.location, this.name) || obj.fullName;
        this.shorthand = (obj?.shorthand) || '';
        this.displayName = (obj?.displayName) || '';
    }
}
