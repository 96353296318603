import { SeasonContextQueryParams } from '@sportlogiq/main/core/context-data/context-data.types';
import { EventSearchesBodyPayload, SortingOrder } from '@sportlogiq/main/core/store/event-searches/event-searches.types';
import { AdvancedDaterangeData } from '@sportlogiq/main/shared/sl-advanced-daterange/sl-advanced-daterange.types';
import { PlaylistEventsTabs, RinkViewOptions } from '@sportlogiq/main/shared/utils/filter-video/filter-video.types';
import { EventContext } from '@sportlogiq/shared/types';

export class SeasonVideoFetchContext {
    public static readonly type = '[SeasonVideo] Fetch Season Context';
    constructor(public params: SeasonContextQueryParams) {}
}

export class SeasonVideoSelectEventType {
    public static readonly type = '[SeasonVideo] Select Event Type';
    constructor(public eventType: string) {}
}

export class SeasonVideoResetFiltersState {
    public static readonly type = '[SeasonVideo] Reset Filters State';
}

export class SeasonVideoSetEventFilters {
    public static readonly type = '[SeasonVideo] Set Event Filters';
    constructor(public filters: string[], public eventType: string) {}
}
export class SeasonVideoAddEmptyEventSearchTab {
    public static readonly type = '[SeasonVideo] Add Event Search tab';
}

export class SeasonVideoSetLoadedEventSearches {
    public static readonly type = '[SeasonVideo] Set Loaded Event Searches';
    constructor(public eventSearches: EventSearchesBodyPayload[], public teamId: string, public gameDates: Date[]) {}
}

export class SeasonVideoRefreshLoadedEventSearches {
    public static readonly type = '[SeasonVideo] Refresh Loaded Event Searches';
    constructor(public eventSearches: EventSearchesBodyPayload[], public teamId: string, public gameDates: Date[]) {}
}

export class SeasonVideoCloseEventSearchTab {
    public static readonly type = '[SeasonVideo] Close Event Search Tab';
    constructor(public index: number) {}
}

export class SeasonVideoDuplicateEventSearchTab {
    public static readonly type = '[SeasonVideo] Duplicate Event Search Tab';
    constructor(public index: number) {}
}

export class SeasonVideoSetSelectedEventSearchIndex {
    public static readonly type = '[SeasonVideo] Set Selected Event Search index';
    constructor(public selectedEventSearchIndex: number) {}
}

export class SeasonVideoResetState {
    public static readonly type = '[SeasonVideo] Reset State';
}

export class SelectOpposingTeams {
    public static readonly type = '[SeasonVideo] Select Opposing Teams';
    constructor(public opposingTeamIds: Set<string>) {}
}

export class SeasonVideoSelectPlayers {
    public static readonly type = '[SeasonVideo] Select Players';
    constructor(public playerIds: string[]) {}
}

export class SeasonVideoToggleWhenOnIce {
    public static readonly type = '[SeasonVideo] Set When On Ice';
    constructor(public whenOnIce: boolean) {}
}

export class SeasonVideoSelectPeriod {
    public static readonly type = '[SeasonVideo] Select Period';
    constructor(public period: number[]) {}
}

export class SeasonVideoSelectManpowerSituation {
    public static readonly type = '[SeasonVideo] Select Manpower Situation';
    constructor(public manpowerSituation: string) {}
}

export class SeasonVideoSelectAdvancedDaterange {
    public static readonly type = '[SeasonVideo] Select Advanced Daterange';
    constructor(
        public daterange: AdvancedDaterangeData,
        public defaultDateranges?: { default: AdvancedDaterangeData; allSeason: AdvancedDaterangeData; }
    ) {}
}

export class SeasonVideoSetDefaultAdvancedDaterange {
    public static readonly type = '[SeasonVideo] Set Default Advanced Daterange';
    constructor(public defaultDateranges?: { default: AdvancedDaterangeData; allSeason: AdvancedDaterangeData; }) {}
}

export class SeasonVideoSetSelectedEventSearchLoading {
    public static readonly type = '[SeasonVideo] Set Selected Event Search Loading';
    constructor(public loading: boolean) {}
}

export class SeasonVideoPatchSelectedEventSearch {
    public static readonly type = '[SeasonVideo] Patch Selected Event Search';
    constructor(public eventSearch: EventSearchesBodyPayload, public teamId: string, public gameDates: Date[]) {}
}

export class SeasonVideoRinkViewChange {
    public static readonly type = '[SeasonVideo] Set Selected Rink View Value';
    constructor(public selectedRinkView: RinkViewOptions) {}
}

export class SeasonVideoSelectPlayerEvents {
    public static readonly type = '[SeasonVideo] Select Player Events';
    constructor(public playerEventIds: string[]) {}
}

export class SeasonVideoDeselectPlayerEvents {
    public static readonly type = '[SeasonVideo] Deselect Player Events';
    constructor(public playerEventIds: string[]) {}
}

export class SeasonVideoClearSelectedPlayerEvents {
    public static readonly type = '[SeasonVideo] Clear Selected Player Events';
}

export class SeasonVideoShowEventsForPlayers {
    public static readonly type = '[SeasonVideo] Show Events For Players';
    constructor(public playerIds: string[]) {}
}
export class SeasonVideoFetchPlayerEvents {
    public static readonly type = '[SeasonVideo] Fetch Season Player Events';
    constructor(public leagueId: string, public seasonId: string, public teamId: string, public seasonStage: string) {}
}

export class SeasonVideoPlaylistSelectEventTab {
    public static readonly type = '[SeasonVideo] Select Playlist Event Tab';
    constructor(public playlistEventTab: PlaylistEventsTabs) {}
}

export class SeasonVideoSetEventPlaylistOrder {
    public static readonly type = '[SeasonVideo] Set Event Playlist Order';
    constructor(public order: SortingOrder) {}
}

export class SeasonVideoSearchPlayerEventClear {
    public static readonly type = '[SeasonVideo] Clear Search Player Events';
}

export class SeasonVideoSearchPlayerEvent {
    public static readonly type = '[SeasonVideo] Seach Player Event';
    constructor(public searchValue: string) {}
}

export class SeasonVideoResetInvalidFields {
    public static readonly type = '[SeasonVideo] Reset Invalid Fields';
}

export class SeasonVideoSelectEventContext {
    public static readonly type = '[SeasonVideo] Select Event Context';
    constructor(public selectedEventContext: EventContext) {}
}
