import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@sportlogiq/app-routing.models';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
/**
 * NukeData Interface
 * reloadData is used to determine whether the nuke should reload data or not (it shouldn't reload any data when the user is logged out for example)
 * notify is used to determine whether the nukeService should notify other windows/tabs using the local storage mechanism
 */
export interface NukeData {
    reloadData?: boolean;
    notify?: boolean;
}
@Injectable()
export class NukeService {
    public nuke$ = new Subject<NukeData | undefined>();
    public lastNuke$ = this.nuke$.pipe(
        startWith(new Date().getTime()),
        map(() => new Date().getTime())
    );

    constructor(private _router: Router) {
        this.nuke$.pipe(filter(obj => obj?.notify)).subscribe(() => {
            // Nuke everyone else, other chrome tabs and windows
            localStorage.setItem('lastNuke', new Date().getTime().toString());
        });

        // Subscribe to changes to local storage
        fromEvent(window, 'storage')
            .pipe(
                debounceTime(500),
                filter(() => !!localStorage.getItem('lastNuke')),
                map(() => parseInt(localStorage.getItem('lastNuke'), 10)),
                withLatestFrom(this.lastNuke$),
                filter(([nukeFromStorage, lastNuke]) => nukeFromStorage > lastNuke)
            )
            .subscribe(() => {
                // If the last nuke in the storage is more recent than the "local" nuke, NUKE and redirect to login!
                this.nuke$.next(undefined);
                this._router.navigate([`/${AppRoute.LOGIN}`]);
            });
    }
}
