
import { from as observableFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef, ElementRef, QueryList, ViewChildren, NgZone } from '@angular/core';
import { LinkItem } from '@sportlogiq/main/shared/sl-types';
import { MatTabNav } from '@angular/material/tabs';
import { TooltipService, SimpleTooltip, TooltipOptions, TooltipPosition } from 'app/main/shared/sl-tooltip/tooltip.service';

@Component({
    selector: 'link-renderer',
    templateUrl: './link-renderer.component.html',
    styleUrls: ['./link-renderer.component.scss']
})

export class LinkRendererComponent implements OnInit, OnDestroy, OnChanges {
    @Input() links: LinkItem[];
    @Input() tabs: boolean;
    @Input() menu: boolean;
    @Input() tabNavExactMatch: boolean;

    @ViewChild(MatTabNav) navBar: MatTabNav;
    @ViewChildren('tooltipIcon') tooltipIcon: QueryList<ElementRef>;

    private tooltipDefaultOptions: Partial<TooltipOptions> = {};
    public tooltipIconClass = 'sl-tooltip-icon';
    private destroy$ = new Subject<boolean>();
    constructor(
        private elementRef: ElementRef,
        private _cd: ChangeDetectorRef,
        private _tooltipService: TooltipService,
        private _ngZone: NgZone) { }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
        observableFrom((<any>document).fonts.ready).pipe(
            takeUntil(this.destroy$))
            .subscribe(() =>
                this.updateInkBarSize()
            );
    }

    ngOnDestroy() {
        // Unsubscribe from streams using takeUntil()
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    // mis alignment issue
    // https://github.com/angular/material2/issues/3469
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.links?.currentValue) {
            this.links = this.links.filter(x => x.visibility ? x.visibility() : true);
        }
        this.tabNavExactMatch = !!this.tabNavExactMatch;

        this._cd.markForCheck();
        if (this.links.some(l => !!l.tooltipText)) {
            this._ngZone.runOutsideAngular(() => setTimeout(() => this.setTooltips()));
        }
    }

    updateInkBarSize() {
        if (this.navBar) {
            this.navBar.updateActiveLink();
        }
    }

    validTabClick(event: MouseEvent): boolean {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return !(<HTMLElement>event.target).classList.contains(this.tooltipIconClass);
    }

    setTooltips(): void {
        this.tooltipDefaultOptions.container = this.elementRef.nativeElement;
        this.tooltipDefaultOptions.placement = TooltipPosition.auto;

        const tooltipsContent = this.links.filter(l => l.tooltipText);

        this.tooltipIcon.forEach((icon, i) =>
            this._tooltipService.attachTooltip(
                icon.nativeElement,
                SimpleTooltip,
                tooltipsContent[i].tooltipText,
                Object.assign(this.tooltipDefaultOptions, tooltipsContent[i].tooltipOptions)));
    }
}
