import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getActionTypeFromInstance, NgxsPlugin } from '@ngxs/store';
import { GameVideoFetchGameContext, GameVideoFetchPlayerEvents } from '@sportlogiq/main/game-video/game-video.actions';
import { FetchAdvancedStatEvents } from '@sportlogiq/main/statistics/store/advanced-stats.actions';
import { SeasonVideoFetchContext, SeasonVideoFetchPlayerEvents } from '@sportlogiq/main/teams/season-video/store/season-video.actions';
import { ErrorSnackbarComponent } from '@sportlogiq/shared/error-snackbar/error-snackbar.component';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    DeleteEventSearches,
    EditEventSearch,
    FetchEventSearches,
    FetchEventSearchesFilters,
    SaveEventSearch,
} from '../../store/event-searches/event-searches.actions';
import { FetchSingleGame } from '../../store/game/game.actions';

@Injectable()
export class ErrorHandlerPlugin implements NgxsPlugin {
    constructor(private _snackBar: MatSnackBar) {}

    handle(state, action, next) {
        const actionTypesForErrorSnackbar = {
            [SaveEventSearch.type]: 'An error occurred while trying to save.',
            [DeleteEventSearches.type]: 'An error occurred while trying to delete.',
            [EditEventSearch.type]: 'An error occurred while trying to edit.',
            [FetchEventSearches.type]: 'An error occurred while trying to load searches.',
            [GameVideoFetchGameContext.type]: 'Unable to load game details. Please try again.',
            [FetchEventSearchesFilters.type]: 'An error occurred',
            [FetchSingleGame.type]: 'Unable to get current game details. Please try again.',
            [GameVideoFetchPlayerEvents.type]: 'An error occurred. Please try again!',
            [SeasonVideoFetchPlayerEvents.type]: 'An error occurred. Please try again!',
            [SeasonVideoFetchContext.type]: 'Unable to load season details. Please try again.',
            [FetchAdvancedStatEvents.type]: 'An error occurred while trying to fetch clips.',
        };

        if (actionTypesForErrorSnackbar[getActionTypeFromInstance(action)]) {
            return next(state, action).pipe(
                catchError(err => {
                    const errorSnack = this._snackBar.openFromComponent<ErrorSnackbarComponent>(ErrorSnackbarComponent, {
                        duration: 5000,
                        panelClass: ['sliq-snackbar'],
                    });
                    errorSnack.instance.snackBarRef = errorSnack;
                    errorSnack.instance.message = actionTypesForErrorSnackbar[getActionTypeFromInstance(action)];
                    return throwError(err);
                })
            );
        }

        return next(state, action);
    }
}
