import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { SlLogo } from './sl-logo.component';

@NgModule({
    imports: [CommonModule, InlineSVGModule],
    declarations: [SlLogo],
    exports: [SlLogo],
})
export class SlLogoModule {}
