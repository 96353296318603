<div class="top-part sl-text-default">
    <p *ngIf="success" class="sl-text-default success-message">Your password was changed successfully!</p>
    <!-- Do we even need this? -->
    <div *ngIf="serverError" class="input-error error-message">{{serverError}}</div>
</div>
<form class="fs-hide" #createNewPasswordForm="ngForm" (ngSubmit)="createNewPassword()" novalidate *ngIf="!success">
    <div *ngIf="userLoggedIn">
        <mat-form-field class="with-underline">
            <input matInput [(ngModel)]="_formModel.oldPassword" name="oldPassword" class="centered" #oldPassword="ngModel" type="password" placeholder="{{'Old password'}}" autocapitalize="off" required>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="with-underline">
            <input matInput [(ngModel)]="_formModel.password" name="password" class="centered" validateEqual="confirmPassword" reverse="true" #password="ngModel" type="password" placeholder="{{'New password'}}" autocapitalize="off" minlength="8" required>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="no-pad with-underline">
            <input matInput [(ngModel)]="_formModel.confirmPassword" name="confirmPassword" class="centered" validateEqual="password" #confirmPassword="ngModel" type="password" placeholder="{{'Confirm New password'}}" autocapitalize="off" required>
        </mat-form-field>
        <div class="error-container">
            <div *ngIf="password.invalid">
                <div *ngIf="password.touched && (password.errors.required || password.errors.minlength)" class="sl-text-smaller input-error error-message">Password must be at least 8 characters.</div>
            </div>
            <div *ngIf="confirmPassword.invalid">
                <div *ngIf="confirmPassword.touched && !confirmPassword.errors.validateEqual" class="sl-text-smaller input-error error-message">Passwords don't match.</div>
            </div>
        </div>
    </div>
    <div class="submit-button-wrapper">
        <button class="sl-btn-box" type="submit" value="Submit" [disabled]="_submitted && !createNewPasswordForm.form.valid">{{'Create New Password'}}</button>
        <div class="loading-wrapper">
            <loading-component [active]="_loading" [theme]="userLoggedIn ? 'dark' : 'light'"></loading-component>
        </div>
    </div>
</form>
