import { environment } from './environment';

/* eslint-disable */
export const COLORS = {
    '$sl-primary-color': '#001E61',
    '$sl-secondary-color': '#2A347C',
    '$sl-tertiary-color': '#2C449C',
    '$sl-quarternary-color': '#0b92db',
    '$sl-primary-color-rgba': 'rgba(0, 30, 97, 0.07)',
    '$sl-caption-color': '#333333',
    '$sl-caption-lighter-color': '#AFAFAF',
    '$sl-primary-bg': '#F2F2F2',
    '$sl-separation-border': '#dedede',
    '$sl-player-jersey-number-bg': 'rgba(0, 30, 97, .7)',
    '$sl-player-position-1': '$sl-primary-color',
    '$sl-player-position-2': '$sl-secondary-color',
    '$sl-player-position-3': '#FFCE00',
    '$sl-player-position-4': '#BE4D93',
    '$sl-player-position-5': '#DB730B',
    '$sl-player-position-6': '#56D88D',
    '$sl-selected-table-item': '$sl-primary-color-rgba',
    '$sl-success-text': '#056E14',
    '$sl-success-bg': '#E1FFEB',
    '$sl-error-text': '#6E050B',
    '$sl-error-bg': '#FFE3E1',
    '$sl-login-error': '#FC5B73',
    '$sl-low-rank': '#e8e8e8',
    '$sl-medium-rank': '#7FC2FF',
    '$sl-high-rank': '#0086FF',
    '$sl-expert-rank': '#0AC96D',
};

export const Constants = Object.freeze({
    api_root: environment.api + '/api/0.2',
    api_root_v3: `${environment.api}/api/v3`,
    user_api_root: `${environment.userApi}/api/v1`,
    company_name: 'SPORTLOGiQ',
    experimentalStorageKey: 'experimental_mode',
    impersonationOverrideStorageKey: 'override_impersonation_guard',
    timerMs: (typeof sessionStorage !== 'undefined' && +sessionStorage?.getItem('app_timer')) || (environment.production && 1 * 60000),
    maxRegularPeriod: 3,
    maxPlayableClips: 5000,
    zendeskHelpCenterExpirationTimeInMin: 3,
    return_to_zendesk: 'return_to',
    markerjs2Key: 'MJS2-F001-S959-5027',
    markerjsliveKey: 'MJSL-F990-S434-8667',
    tableauExpirationTimeInMs: 300000, // 5 mins
    defaultAnnotationDurationForExportInSeconds: 3, // 3 seconds
    maxExportedPlaylistDuration: 2700
});

/* eslint-enable */

export const mediaQueryBreakPoints = {
    avgDesktopWidth: '(max-width: 1199px)',
};
