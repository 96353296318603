import { GameAPI } from './game.model';

export class FetchSingleGame {
    static readonly type = '[Game] Fetch Single Game';
    constructor(public gameId: string, public forceFetch = false) {}
}

export class FetchGames {
    static readonly type = '[Game] Fetch Games';
    constructor(public leagueId: string, public seasonId: string, public seasonStage: string, public forceFetch = false) {}
}

export class FetchGamesSuccess {
    static readonly type = '[Game] Success FetchGames';
    constructor(public games: GameAPI[]) {}
}

export class FetchGamePlays {
    public static readonly type = '[Game] Fetch Game Plays';
    constructor(public gameId: string) {}
}
