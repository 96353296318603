import { FaceoffEvent } from './faceoff.model';
import FaceoffTeamPlayer from './faceoff-team-player.model';
import { SeasonStageType } from '@sportlogiq/main/shared/sl-types';

export enum ScopeType {
    player = 'player',
    team = 'team',
}

export interface FaceoffMetrics {
    MetricType: MetricType;
    metricValues: FaceoffEvent[];
    metricPlayerData: FaceoffTeamPlayer[];
}

export interface MetricType {
    id: string;
    label: string;
    metrics: Metrics[];
}

export interface Metrics {
    type: string;
    metricKey: string;
    label: string;
    eventsZone: string;
    displayOrder: number;
    displayFormat: string;
    directionality: string;
    baseMetric: string;
}

export interface FilterParams {
    minDate?: string | Date;
    maxDate?: string | Date;
    teamId?: number;
    gameId?: string[];
    playerId?: number[];
    manpowerSituation?: string;
    period?: number[];
}

export interface FaceoffSectionEvent {
    type: 'click' | 'mouseIn' | 'mouseOut' | 'focus' | 'blur';
    section: FaceoffName;
}

export interface FaceoffSectionState {
    section: FaceoffName;
    backgroundColor: string;
}

export interface PieChartValue {
    name: FaceoffName;
    percentage: number;
    detailValue: string;
}

export interface FaceoffLocation {
    name: FaceoffName;
    key?: string;
    offsetX: number;
    offsetY: number;
}

export enum FaceoffName {
    // NZ
    nzNorthEast = 'NZ North East Face-Offs',
    nzSouthEast = 'NZ South East Face-Offs',
    nzSouthWest = 'NZ South West Face-Offs',
    nzNorthWest = 'NZ North West Face-Offs',
    // OZ
    ozWest = 'OZ West Face-Offs',
    ozEast = 'OZ East Face-Offs',

    // DZ
    dzEast = 'DZ East Face-Offs',
    dzWest = 'DZ West Face-Offs',

    // center
    nzCenter = 'NZ Center Face-Offs',

    // zone total
    ozTotal = 'Total OZ Face-Offs',
    nzTotal = 'Total NZ Face-Offs',
    dzTotal = 'Total DZ Face-Offs',
    ozDzTotal = 'Total OZ DZ Face-Offs Taken',
    total = 'Total Face-Offs',
}

export enum FaceoffDotName {
    dzDot = 'DZ Face-Off Dot',
    ozDot = 'OZ Face-Off Dot',
    nzSouthDot = 'NZ South Face-Off Dot',
    nzNorthDot = 'NZ North Face-Off Dot',
    nzCenter = 'NZ Center Face-off Dot',
    ozTotal = 'Total OZ Face-Offs',
    dzTotal = 'Total DZ Face-Offs',
    totalForPlural = 'Total Face-Offs',
    totalForSingular = 'Total Face-Off',
}

export interface FaceoffParams {
    scopeType?: ScopeType;
    seasonStage: SeasonStageType;
    seasonId: string;
    leagueId: string;
}

export interface ToggleGroupOptions {
    value: string;
    display: string;
    ariaLabel?: string;
}

export enum FaceoffOffset {
    height = 0.5,
    width = 0.7,
    widthNZ = 0.25,
}

export const faceoffLocations: FaceoffLocation[] = [
    { name: FaceoffName.dzEast, key: 'dzEast', offsetX: -FaceoffOffset.width, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.dzWest, key: 'dzWest', offsetX: -FaceoffOffset.width, offsetY: FaceoffOffset.height },
    { name: FaceoffName.ozWest, key: 'ozWest', offsetX: FaceoffOffset.width, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.ozEast, key: 'ozEast', offsetX: FaceoffOffset.width, offsetY: FaceoffOffset.height },
    { name: FaceoffName.nzSouthWest, key: 'nzSouthWest', offsetX: -FaceoffOffset.widthNZ, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.nzSouthEast, key: 'nzSouthEast', offsetX: -FaceoffOffset.widthNZ, offsetY: FaceoffOffset.height },
    { name: FaceoffName.nzNorthWest, key: 'nzNorthWest', offsetX: FaceoffOffset.widthNZ, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.nzNorthEast, key: 'nzNorthEast', offsetX: FaceoffOffset.widthNZ, offsetY: FaceoffOffset.height },
    { name: FaceoffName.nzCenter, key: 'nzCenter', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.ozTotal, key: 'ozFaceoff', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.dzTotal, key: 'dzFaceoff', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.total, key: 'totalFaceoff', offsetX: 0, offsetY: 0 },
];

// game faceoff locations are symmetric to (0,0) compared with team faceoffLocations
export const faceoffLocationsForGame: FaceoffLocation[] = [
    { name: FaceoffName.dzEast, key: 'dzEast', offsetX: FaceoffOffset.width, offsetY: FaceoffOffset.height },
    { name: FaceoffName.dzWest, key: 'dzWest', offsetX: FaceoffOffset.width, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.ozWest, key: 'ozWest', offsetX: -FaceoffOffset.width, offsetY: FaceoffOffset.height },
    { name: FaceoffName.ozEast, key: 'ozEast', offsetX: -FaceoffOffset.width, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.nzSouthWest, key: 'nzSouthWest', offsetX: FaceoffOffset.widthNZ, offsetY: FaceoffOffset.height },
    { name: FaceoffName.nzSouthEast, key: 'nzSouthEast', offsetX: FaceoffOffset.widthNZ, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.nzNorthWest, key: 'nzNorthWest', offsetX: -FaceoffOffset.widthNZ, offsetY: FaceoffOffset.height },
    { name: FaceoffName.nzNorthEast, key: 'nzNorthEast', offsetX: -FaceoffOffset.widthNZ, offsetY: -FaceoffOffset.height },
    { name: FaceoffName.nzCenter, key: 'nzCenter', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.ozTotal, key: 'ozFaceoff', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.dzTotal, key: 'dzFaceoff', offsetX: 0, offsetY: 0 },
    { name: FaceoffName.total, key: 'totalFaceoff', offsetX: 0, offsetY: 0 },
];

export interface PlayerFaceoffData {
    playerId: string;
    playerName: string;
    position: string;
    avatar: string;
    faceoffTaken: number;
    faceoffWon: number;
    faceoffPercentage: number;
    jerseyNum?: string;
}

export interface FaceoffClickEvent {
    teamId?: string;
    playerId?: string;
    playerName?: string;
}

export enum FaceoffMetricTopicId {
    team = '0',
    player = '1',
}
