/* eslint-disable complexity */
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from '@sportlogiq/core/user/user.state';
import { UserPermissions } from '@sportlogiq/shared';
import { Subject, take } from 'rxjs';
import { UserService } from '../../core/user.service';
import { TabsService } from '../core/tabs/tabs.service';
import { TabItem } from '../shared/sl-types';

@Component({
    selector: 'app-scouting',
    templateUrl: './scouting.component.html',
    styleUrls: ['./scouting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoutingComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<boolean>();

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _tabsService: TabsService,
        private _store: Store
    ) {}

    public ngOnInit() {
        this._setSubHeaderTabs();
        this._tabsService.hideTeamSelector(true);
    }

    public ngOnDestroy() {
        this._store.select(UserState.isPlayerUser).pipe(
            take(1)
        ).subscribe((isPlayerUser) => {
            if (!isPlayerUser) {
                this._tabsService.hideTeamSelector(false);
            } else {
                this._tabsService.setTabs([]);
                this._tabsService.hidePageHeader();
            }

            this._destroy$.next(true);
        });
    }

    private _setSubHeaderTabs(): void {
        const tabs: TabItem[] = [
            {
                classlist: ['/scouting', this._isRouteActive(['/scouting', 'player-video']) ? 'active' : ''],
                href: ['/scouting', 'player-video'],
                isActive: this._isRouteActive(['/scouting', 'player-video']),
                label: 'Player Video'.toUpperCase(),
                disabled: !this._userService.checkForPermission(UserPermissions.canViewScoutingPlayerVideo)
            },
        ];

        if (this._userService.checkForPermission(UserPermissions.canViewScoutingPlayerComparison) ||
            this._userService.checkForPermission(UserPermissions.canLoginOnScoutingApp)) {
            tabs.push({
                classlist: ['/scouting', this._isRouteActive(['/scouting', 'player-comparison']) ? 'active' : ''],
                href: ['/scouting', 'player-comparison'],
                label: 'Player Comparison'.toUpperCase(),
                isActive: this._isRouteActive(['/scouting', 'player-comparison']),
                disabled: !this._userService.checkForPermission(UserPermissions.canViewScoutingPlayerComparison)
            });
        }

        if (
            this._userService.checkForPermission(UserPermissions.canViewScoutingPlayerProfile) ||
            this._userService.checkForPermission(UserPermissions.canLoginOnScoutingApp)
        ) {
            tabs.push({
                classlist: ['player-profile', 'menu-item'],
                href: ['/scouting', 'player-profile'],
                label: 'Player Profile'.toUpperCase(),
                isActive: this._isRouteActive(['/scouting', 'player-profile']),
                disabled: !this._userService.checkForPermission(UserPermissions.canViewScoutingPlayerProfile)
            });
        }

        this._tabsService.setTabs(tabs);
    }

    private _isRouteActive(url: string[], queryParams?): boolean {
        return this._router.isActive(this._router.createUrlTree(url, { queryParams }), false);
    }
}
