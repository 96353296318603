import { GameImpactIntervals, PlayerEvent, GameEvent, PlayerShift, Game, AnnotationsByFrame } from 'app/main/core';
import { GameTimeInterval, KeyedById, VideoPlayerScreenMode, VideoFile, Playlist } from '@sportlogiq/main/shared/sl-types';
import { Player } from 'app/main/core/players/player.model';
import { SeasonStage } from 'app/main/core/seasons/season-stage.model';
import { MetricDefinition, PlayerArchetype, PlayerSkillSet } from 'app/main/core/player-metrics/player-metric.model';
import { Team } from 'app/main/core/teams/team';
import { MatSnackBarRef, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { PlayerPrimaryPosition } from '@sportlogiq/main/core/store/player/player.models';
import { VideoPlayerSegment } from '@sportlogiq/main/video-player/store/video-player.models';

export enum AutocompleteCategoryType {
    player,
    team,
    organization,
    tag,
}

export enum UserPermissions {
    hasAllPermissions = 'has_all_permissions',
    canAccessPlayerCard = 'canAccessPlayerCard',
    canAccessTeamRoster = 'canAccessTeamRoster',
    canLoginOnTeamApp = 'canLoginOnTeamApp',
    canLoginOnPlayerApp = 'canLoginOnPlayerApp',
    canLoginOnPlayerWithVideoApp = 'canLoginOnPlayerWithVideoApp',
    canLoginOnAgentApp = 'canLoginOnAgentApp',
    canLoginOnLiveApp = 'canLoginOnLiveApp',
    canExtractClip = 'canExtractClip',
    canExportToCSV = 'canExportToCSV',
    canDownloadRawData = 'canDownloadRawData',
    hasAllLeagueAffiliations = 'hasAllLeagueAffiliations',
    canViewClips = 'canViewClips',
    canViewStatisticsPage = 'canViewStatisticsPage',
    canViewTeamReport = 'canViewTeamReport',
    canWriteNotes = 'canWriteNotes',
    canViewWowy = 'canViewWowy',
    canViewLinesPairs = 'canViewLinesPairs',
    canViewPlayerUsage = 'canViewPlayerUsage',
    canViewXPGChart = 'canViewXPGChart',
    canViewTeamFaceoff = 'canViewTeamFaceoff',
    canViewPlayerFaceoff = 'canViewPlayerFaceoff',
    canViewStyleguide = 'canViewStyleguide',
    canViewLeagueXGChart = 'canViewLeagueXGChart',
    canViewStrengthWeakness = 'canViewStrengthWeakness',
    canViewTradStatsPage = 'canViewTradStatsPage',
    canViewGameSummary = 'canViewGameSummary',
    canViewPlayerCardStats = 'canViewPlayerCardStats',
    canViewGameBoxscore = 'canViewGameBoxscore',
    canChangeAccountSettings = 'canChangeAccountSettings',
    canViewLegacyPlayerComparison = 'canViewLegacyPlayerComparison',
    canViewCanvasOptions = 'canViewCanvasOptions',
    canViewInsideEdge = 'canViewInsideEdge',
    canRetrieveMetricApiUrl = 'canRetrieveMetricApiUrl',
    canViewGameHeader = 'canViewGameHeader',
    canViewPowerplayBreakouts = 'canViewPowerplayBreakouts',
    canViewGameVideo = 'canViewGameVideo',
    canViewSeasonVideo = 'canViewSeasonVideo',
    canViewTrackingMetrics = 'canViewTrackingMetrics',
    canViewHelpCenter = 'canViewHelpCenter',
    canDownloadXOsFile = 'canDownloadXOsFile',
    canDownloadHUDLFile = 'canDownloadHUDLFile',
    canViewNewFeatures = 'canViewNewFeatures',
    canViewCustomReports = 'canViewCustomReports',
    canLoginOnScoutingApp = 'canLoginOnScoutingApp',
    canViewScoutingPlayerVideo = 'canViewScoutingPlayerVideo',
    canViewScoutingPlayerComparison = 'canViewScoutingPlayerComparison',
    canViewScoutingPlayerProfile = 'canViewScoutingPlayerProfile',
    canViewLeagueSelector = 'canViewLeagueSelector'
}

export class TeamPosition {
    team: Team;
    position: PlayerPosition;
    static initialize(item: TeamPosition) {
        const obj = new TeamPosition();
        obj.position = item.position;
        obj.team = item.team;
        return obj;
    }
}

export interface PlaylistSegmentData {
    eventTuple: PlaylistSegmentEvents;
    leadTime?: number;
    trailTime?: number;
    minStart?: number;
    maxStart?: number;
    annotationsByFrame: AnnotationsByFrame;
    date?: string;
    homeTeamid: string;
    awayTeamid: string;
    periodTime: string;
    period: number;
}

export interface PlaylistSegmentEvents {
    start: EventIdentifier;
    end: EventIdentifier;
}

export type MetricDirectionality = 'positive' | 'negative';

export interface Metric {
    id: string;
    label: string;
    baseMetric: string;
    metricKey: string;
    description: string;
    type: MetricType;
    displayOrder: number;
    displayFormat: string;
    directionality: MetricDirectionality;
    eventsZone: string;
    properties: { altGrouping: string; };
    metrics: Metric[];
}

export enum MetricType {
    duration = 'duration',
    metric = 'metric',
    rate = 'rate',
    group = 'group',
}

export interface MetricTopic {
    id: string;
    label: string;
    categories: string[];
    description: string;
    keywords?: string[];
    default: boolean;
}

export interface EventIdentifier {
    id: string;
    eventType: VideoEventType;
}

export enum VideoEventType {
    'PlayerShift' = 'playershiftevents',
    'GameEvent' = 'gameevents',
    'PlayerEvent' = 'playerevents',
}

export enum PlayerPosition {
    forward,
    defenceMan,
    goalie,
    all,
}
export interface PositionSelection {
    label: string;
    type: PlayerPosition;
}

export interface EventPlayer {
    event: PlayerEvent;
    player: Player;
}

export interface GameEventPlayer {
    event: GameEvent;
    player: Player;
}

export enum XYCountType {
    games = 'games',
    events = 'events',
}

export interface SkatersOnIce {
    homeTeamSkatersOnIce: number;
    awayTeamSkatersOnIce: number;
}

export interface TimedSkatersOnIce extends SkatersOnIce, GameTimeInterval {}

export interface GoalieWasOutInterval extends GameTimeInterval {
    teamId: string;
    event: PlayerShift;
}

export interface ToggleGroupOptions {
    value: string;
    display: string;
    ariaLabel?: string;
}
export interface ManpowerChange {
    timestamp: number;
    item: GameEvent;
    skatersOnIce: { prev: TimedSkatersOnIce; current: TimedSkatersOnIce; };
    manpowerSituation: { prev: ManpowerSituationType; current: ManpowerSituationType; };
}

export enum WowyPlayerType {
    with = 'with',
    without = 'without',
}
export interface DateRange {
    start: string;
    end: string;
}

export interface DateRangeAsDates {
    start: Date;
    end: Date;
}
export interface UIFilters {
    gameRange: GameRangeType;
    manpower: ManpowerSituationType;
    dateRange: DateRange;
    wowy?: WowyPlayerType;
}

export enum ManpowerSituationType {
    all = 'all',
    powerPlay = 'powerPlay',
    evenStrength = 'evenStrength',
    shortHanded = 'shortHanded',
}
export interface ManpowerSelection {
    label: string;
    type: ManpowerSituationType;
    skaters: { value: string; display: string; skaterValue: string; }[];
}

export interface ManpowerEvent {
    value: ManpowerSituationType;
    display: string;
}

export interface SituationPlayerEvent {
    ownSkaters: number;
    opponentSkaters: number;
    timeStamp: number;
}

export type ManPowerSituationPlayerEvents = Record<ManpowerSituationType, SituationPlayerEvent[]>;

export type Interval = [number, number];

export interface TimedEvent {
    timeStamp: number;
    item: GameEvent | PlayerShift;
    frame: number;
}

export interface IntervalEvent {
    interval: Interval;
    events: (GameEvent | PlayerShift)[];
    frames: number[];
}

export type IntervalEventDic = Record<string, IntervalEvent>;

export enum GameRangeType {
    all = 'all',
    custom = 'custom',
    last10Games = '10',
    last5Games = '5',
    lastGame = '1',
}

export interface GameRangeEvent {
    dateRangeDisplay: string;
    endDate: string;
    startDate: string;
    gameRangeSelected: GameRangeType;
}

export interface SelectedDateRangeType {
    startDate: string;
    endDate: string;
    gameRangeSelected: string;
    dateRangeDisplay: string;
}

export interface FilteredGameSituationOption {
    enabled: boolean;
    option: MpSituationOption;
}

export interface MpSituationOption {
    label: string;
    singleGameOnly?: boolean;
    disabled?: boolean;
}

export interface PlayEntireSituationRawData {
    game: Game;
    gameEventsPlayer: GameEventPlayer[];
    playerShifts: PlayerShift[];
    manpowerSegments?: ManpowerChange[];
    // timedSkatersOnIce?: TimedSkatersOnIce[];
}

export enum StatisticMetricType {
    team = 'team',
    skater = 'skater',
    goalie = 'goalie',
    opposingteam = 'opposingteam',
    player = 'player',
}

export interface TeamSelectorChange {
    team?: Team;
    season?: SeasonStage;
}

export interface TeamsPlayers {
    [teamId: number]: {
        players: Player[];
        team: Team;
        home: boolean;
    };
}

export interface ByDate<T> {
    date: Date;
    items: T[];
}

export enum GameUrl {
    LEAGUE = '/games/league',
    LEAGUE_DETAILS = '/games/league/:gameid',
    LEAGUE_PRE = '/games/league/:gameid/report/:reportStatus',
    LEAGUE_POST = '/games/league/:gameid/report/:reportStatus',
    LEAGUE_SUMMARY = '/games/league/:gameid/summary',
    LEAGUE_VIDEO = '/games/league/:gameid/video',
    LEAGUE_BOXSCORE = '/games/league/:gameid/boxscore',
    TEAM = '/games/team/:teamId',
    TEAM_DETAILS = '/games/team/:teamId/:gameid/video',
    TEAM_PRE = '/games/team/:teamId/:gameid/report/:reportStatus',
    TEAM_POST = '/games/team/:teamId/:gameid/report/:reportStatus',
    TEAM_SUMMARY = '/games/team/:teamId/:gameid/summary',
    TEAM_VIDEO = '/games/team/:teamId/:gameid/video',
    TEAM_BOXSCORE = '/games/team/:teamId/:gameid/boxscore',
    LEAGUE_LIVE = '/live/:gameid',
}

export interface CsvBlob {
    blob: Blob;
    filename: string;
}

// Common class to identify app actions
// To be used with classes that `next()` values
// to subscribers
export class Action<E, T> {
    action: E;
    data: T;

    constructor(action: E, data: T) {
        this.action = action;
        this.data = data;
    }
}

export enum RowDatumType {
    row = 'row',
    player = 'player',
}
export interface VideoPlayerRequestData {
    type?: VideoPlayerScreenMode;
    data?: VideoPlayerAdditionalData;
}

export interface VideoPlayerAdditionalData {
    videoSegments: VideoPlayerSegment[];
    gameImpactIntervals?: GameImpactIntervals;
    playlist?: Playlist<PlaylistSegmentData>;
    doNotOrderSegments?: boolean;
}

export enum ReportStatus {
    summary,
    detailed,
}

export interface MetricForPosition {
    archetypes: KeyedById<PlayerArchetype>;
    skillSets: KeyedById<KeyedById<PlayerSkillSet>>;
    metrics: KeyedById<KeyedById<MetricDefinition>>;
}

export interface MetricMetaDataGroupedByPosition {
    [position: string]: MetricForPosition;
}

export interface MetricDefinitionDictionary {
    [id: string]: MetricDefinition;
}

export enum MetricMetaData {
    Archetypes,
    SkillSets,
    Metrics,
}

export enum PositionMap {
    'F' = 'FORWARDS',
    'D' = 'DEFENCEMEN',
    'G' = 'GOALIES',
}

export class ByPosition<T> {
    public F: T[] = [];
    public D: T[] = [];
    public G: T[] = [];

    constructor() {
        return this;
    }
}

export enum GameActionEnum {
    GamesFetched = 'GAMES_FETCHED',
    GamesFetchInProgress = 'GAMES_FETCH_IN_PROGRESS',
    GamesFetchFailed = 'GAMES_FETCH_FAILED',
}

export interface Settings {
    gameSummary: boolean;
}

export interface SLDateEventLabelInfo {
    text: string;
    class?: string;
}

export interface SLDateEventLabel {
    text: string;
    class?: string;
    awayOrHome?: string;
    info?: SLDateEventLabelInfo[];
    summary?: SLDateEventLabelInfo[];
}

export interface SLDateEventData {
    icon: string;
    label: SLDateEventLabel;
}

export interface SLDateEventItem {
    id: string;
    date: Date;
    data: SLDateEventData[];
    status: string;
    class?: string;
    clickable?: boolean;
    highlighted?: boolean;
    isPostponed?: boolean;
    selected?: boolean;
    href?: string;
    overTime?: string;
}

export interface PlayerTOI {
    playerId: number;
    second: number;
    gamesplayed?: number;
}

export enum GameStatus {
    Win = 'Win',
    Loss = 'Loss',
    Tied = 'Tied',
    Lead = 'Lead',
    Trail = 'Trail',
}

export type PlayerQuickRef = 'xgf' | 'soo' | 'soodiff' | 'ozstart';

export interface NoteResponse {
    notes: Note[];
    videoRefs: VideoFile[];
}

export interface Note {
    noteid?: string;
    created?: Date;
    updated?: Date;
    note: string;
    tags: NoteMetadata;
    // client side only
    open?: boolean;
    new?: boolean;
}

export interface NoteMetadata {
    players?: NoteTagPlayer[];
    teams?: NoteTagTeam[];
    videos?: NoteVideoTag[];
    tags?: NoteTagItem[];
    games?: string[];
}

export interface NoteTagTeam {
    id: string;
}

export interface NoteTagPlayer {
    id: string;
    name: string;
    picturesrc: string;
}

export interface NoteVideoTag {
    gameid: string;
    period: number;
    frame: number;
    leadtime: number;
    trailtime: number;
    videoId?: string;
}

export interface NoteTagItem {
    id: string;
    tag: string;
    cnt?: number;
}

export interface NoteSearch {
    metadata?: NoteMetadata;
    text?: string;
}

export interface CustomSnackBar<T> {
    message: string;
    action: string;
    theme?: string;
    snackBarRef: MatSnackBarRef<T>;
    hasAction: boolean;
    dismiss(): void;
}

export interface CustomSnackBarOptions<T extends CustomSnackBar<T>> {
    message: string;
    theme?: string;
    actionMessage: string;
    componentType?: ComponentType<T>;
    snackBarConfig: MatSnackBarConfig;
}

export interface FilterRule {
    count?: number;
    teamId?: string;
    leagueId?: string;
    season?: { stage: string; id: string; };
    orderBy?: { order: 'asc' | 'desc'; prop: string; };
}

export interface PlayerMetric {
    computedValue: string;
    player: Player;
    value: number;
}

export interface TeamPlayerMetric {
    [teamId: number]: PlayerMetric[];
}

export interface SectionPlayerMetrics {
    [metricId: number]: TeamPlayerMetric;
}

export interface ShootoutSettings {
    playerId?: string;
    singleGame?: boolean;
}

export type ChartMouseEvents = 'click' | 'mouseover' | 'mouseout';

export enum GameState {
    live = 'live',
    pre = 'pre',
    post = 'post',
}

export enum SortDirection {
    'asc',
    'desc',
}

export enum PolygonPointTypes {
    POINT = 'polygonpoint',
    ARC = 'polygonarc',
}

export enum GoalLocations {
    HIGH_LEFT = 'highleft',
    HIGH_RIGHT = 'highright',
    LOW_LEFT = 'lowleft',
    LOW_RIGHT = 'lowright',
    FIVEHOLE = 'fivehole',
    NONE = 'none',
}

export interface PolygonPoint {
    type?: PolygonPointTypes;
    x: number;
    y: number;
}

/**
 * A polygon arc consists of a triangle with points start, end and vertex. The corner of the triangle represented
 * by the vertex will be rounded with the radius specified in the object such that the size of the circle is not
 * larger than the distance between the start and end points.
 *
 * e.g. let's say we have triangle equilateral triangle with S(0, 5), V(0, 0), E(5, 0) where the vertex is at (0, 0)
 * If we specify the radius as 1 then we will see a line starting at S(0,5) going down 4 units to (0, 1) and then starting to curve
 * towards point E the curve will end at (1, 0) and the line will continue until E(5, 0)
 */
export interface PolygonArc {
    type: PolygonPointTypes;
    start: PolygonPoint;
    end: PolygonPoint;
    vertex: PolygonPoint;
    radius: number;
}

export type Polygon = (PolygonPoint | PolygonArc)[];

export interface TabGroupDynamicItem {
    name: string;
    id: string;
}

export interface TabMenuOptionItem {
    label?: string;
    element?: TabMenuElementType;
    class?: string;
    isDisabled?: (i: number) => boolean;
    isVisible?: (i: number) => boolean;
    clickHandler?: (i?: number) => void;
    subMenuItems?: TabMenuOptionItem[];
}

export enum TabMenuElementType {
    button,
    divider,
}

export enum AdvancedStatType {
    statisticsPage = 'statisticsPage',
    ppt = 'pptMetrics',
    newStatisticsPage = 'newStatisticsPage',
}

export type PositionGroupKeys = PlayerPrimaryPosition.F | PlayerPrimaryPosition.D | PlayerPrimaryPosition.G;

export type GroupLabelByPosition = { [K in PositionGroupKeys]: string };

export interface VidParamsV3Clip {
    gameId: string;
    vidId: string;
    frameRate: number;
    width: number;
    height: number;
    period: number;
    teamWithOZOnRight: string;
    filename: string;
    rootUrl: string;
    avFrameOffset: number;
    trackingQAComplete: number;
    nasUrl: string;
    homeBenchLocationId: string;
    awayBenchLocationId: string;
    updatedOn: string;
    id: string;
    periodType: string;
    copyright: string;
}

export interface PlayerEventsResponseAPI {
    events: (string | number | string[])[][];
    perspective: { events: string[]; };
    vidParams?: Record<string, VidParamsV3Clip>;
}

export enum EventContext {
    FOR = 'for',
    AGAINST = 'against',
}
