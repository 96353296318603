import { Directive, Input, OnDestroy, ViewContainerRef, ContentChild, AfterViewInit } from '@angular/core';
import { GlossaryService, GlossaryItem, LinkedGlossaryItem } from '@sportlogiq/main/core/glossary.service';
import { Subject, fromEvent, of, Observable } from 'rxjs';
import { tap, takeUntil, switchMap, mergeMap, take } from 'rxjs/operators';
import { get as _get } from 'lodash';


@Directive({ selector: '[glossaryItemHighlight]' })
export class GlossaryItemHighlightDirective {
    constructor(public viewContainer: ViewContainerRef) { }
}

@Directive({
    selector: '[glossaryItem]'
})
export class GlossaryItemDirective implements AfterViewInit, OnDestroy {
    @Input('glossaryItem') public glossaryItemLabel: string;

    @ContentChild(GlossaryItemHighlightDirective) public glossaryItemHighlight: GlossaryItemHighlightDirective;

    private _onDestroy$ = new Subject<boolean>();
    private _glossaryElement: HTMLElement;
    private _glossaryLabelHighlightEl: HTMLElement;
    private _elementDefinition: GlossaryItem;

    constructor(
        private _viewContainer: ViewContainerRef,
        private _glossaryService: GlossaryService
    ) { }

    ngOnDestroy() {
        this._glossaryService.removeFromRelevantItems(this._elementDefinition);
        this._onDestroy$.next(true);
    }

    ngAfterViewInit() {
        // Get the glossary definition for this element
        this._elementDefinition = this._getGlossaryDefinition();
        this._glossaryElement = this._viewContainer.element.nativeElement;
        this._glossaryLabelHighlightEl = _get(this.glossaryItemHighlight, 'viewContainer.element.nativeElement');
        this._handleClasses('add', 'glossary-item-label');

        fromEvent(this._glossaryElement, 'click')
            .pipe(
                takeUntil(this._onDestroy$),
                mergeMap(value => this._glossaryService.glossaryActive$.pipe(take(1)))
            )
            .subscribe(
                active =>
                    active
                        ? this._glossaryService.goToItem(this.glossaryItemLabel)
                        : undefined
            );

        this._glossaryService.glossaryActive$
            .pipe(
                takeUntil(this._onDestroy$),
                tap(isActive => {
                    if (isActive && this._elementDefinition) {
                        this._glossaryService.addToRelevantItems(this._elementDefinition);
                        this._handleClasses('add', 'highlighted');
                    } else {
                        this._glossaryService.removeFromRelevantItems(this._elementDefinition);
                        this._handleClasses('remove', 'highlighted');
                    }
                }),
                switchMap(isActive =>
                    isActive
                        ? this._glossaryService.activeGlossaryItem$
                        : of({}) as Observable<LinkedGlossaryItem>
                )
            )
            .subscribe(item => {
                if (
                    this._elementDefinition
                    && item
                    && item.title === this._elementDefinition.title
                ) {
                    this._glossaryElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                    this._handleClasses('add', 'active');
                } else {
                    this._handleClasses('remove', 'active');
                }
            });
    }

    private _getGlossaryDefinition() {
        return this._glossaryService.glossaryDefinitions[this.glossaryItemLabel];
    }

    private _handleClasses(state: 'add' | 'remove', className: 'highlighted' | 'active' | 'glossary-item-label') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this._glossaryLabelHighlightEl
            ? this._glossaryLabelHighlightEl.classList[state](className)
            : this._glossaryElement.classList[state](className);
    }
}
