import { UserPermissions } from '@sportlogiq/shared/types';

export interface UserAPIModel {
    firstName: string;
    lastName: string;
    email: string;
    companyId: string;
    enabled: boolean;
    ssoId: string;
    updatedOn: string;
    createdOn: string;
}
export interface AccountAPIModel {
    user: UserAPIModel;
}

export type LoginAPIModel = UserAPIModel & {
    token: string;
};

export interface LeagueAffiliation {
    leagueId: string;
    /** If true it means that this league has games that are fully processed for this user */
    hasMetricsFullyProcessed: boolean;
}

export interface SLAuthorization {
    permissions: (UserPermissions | string)[];
    leagueAffiliations: LeagueAffiliation[];
    teamAffiliations: string[];
    playerId: string;
    isActive: boolean;
}

export interface SLIdentTokenData {
    /** sub is the user ID */
    sub: string;
    /** impby is the user ID of the impersonator when currently impersonating another user */
    impby: string | null;
    exp: number;
}

export class UserModel {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    username: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    permissions: string[];
    leagueAffiliations: LeagueAffiliation[];
    teamAffiliations: string[];
    companySsoId: string;
    companyId: string;
    playerId: string;
    impersonatedBy: string;

    // eslint-disable-next-line complexity
    constructor(account: UserAPIModel, authorization: SLAuthorization, impersonatedBy?: string) {
        this.id = account?.ssoId;
        this.firstName = account?.firstName;
        this.lastName = account?.lastName;
        this.fullName = `${account?.firstName} ${account?.lastName}`;
        this.email = account?.email;
        this.username = account?.email;
        this.permissions = authorization?.permissions || [];
        this.leagueAffiliations = authorization?.leagueAffiliations || [];
        this.teamAffiliations = authorization?.teamAffiliations || [];
        this.companySsoId = account?.companyId || '';
        this.companyId = account?.companyId || '';
        this.playerId = authorization?.playerId;
        this.impersonatedBy = impersonatedBy;
    }
}

export interface UserStateModel {
    user: UserAPIModel | undefined;
    authorization: SLAuthorization | undefined;
    userCompany: CompanyAPIModel | undefined;
    slIdentTokenData: SLIdentTokenData | undefined;
}

export interface CompanyAPIModel {
    id: string;
    name: string;
}

export const userStateDefaults: UserStateModel = {
    user: undefined,
    userCompany: undefined,
    authorization: undefined,
    slIdentTokenData: undefined,
};
