import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'warning-snackbar',
    templateUrl: './warning-snackbar.component.html',
    styleUrls: ['./warning-snackbar.component.scss'],
})
export class WarningSnackbarComponent {
    public message: string;
    public action: string;
    /**
     *
     * @type {MatSnackBarRef<WarningSnackbarComponent>}
     * @memberof WarningSnackbarComponent
     * @deprecated use _snackRef instead
     */
    public snackBarRef: MatSnackBarRef<WarningSnackbarComponent>;
    public secondaryActionLabel: string;

    constructor(public snackRef: MatSnackBarRef<WarningSnackbarComponent>) {}

    get hasAction(): boolean {
        return !!this.action;
    }

    public dismiss(): void {
        this.snackRef.dismissWithAction();
    }

    public secondaryAction(): void {
        this.snackRef.dismiss();
    }
}
