import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatPlayerNamePipe } from './sl-pypes';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FormatPlayerNamePipe
    ],
    exports: [
        FormatPlayerNamePipe
    ]
})
export class SlFormatPlayerNamePipeModule { }
