/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Color } from './sl-types';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { filter, map, take } from 'rxjs/operators';
import {
    isUndefined as _isUndefined,
    isEmpty as _isEmpty,
    take as _take,
    drop as _drop,
    map as _map,
    reduce as _reduce,
    forEach as _forEach,
    lte as _lte,
    gte as _gte,
    pull as _pull,
    remove as _remove
} from 'lodash';

/**
 * Returns an array of arrays each of length `amount`.
 * Applies a transformation function if supplied to each item before
 * pushing it to the accumulator.
 */
export const takeAll = (arr: any[], amount: number, transform?: Function, accumulator: any[] = []) => {
    if (_isEmpty(arr)) {
        return accumulator;
    }
    let items;
    let remaining;

    // eslint-disable-next-line prefer-const
    items = _take(arr, amount);
    // eslint-disable-next-line prefer-const
    remaining = _drop(arr, amount);

    if (transform !== undefined && transform instanceof Function) {
        accumulator.push(_map(items, item => transform(item)));
    } else {
        accumulator.push(items);
    }
    return takeAll(remaining, amount, transform, accumulator);
};

export const arrayBufferToBase64 = arrayBuffer => {
    const bytes = [].slice.call(new Uint8Array(arrayBuffer));
    const str = _reduce(bytes, (s, byte: any) => (s += String.fromCharCode(byte)), '');
    return window.btoa(str);
};

/**
 * Returns true if the browser is running on a mobile device or tablet
 */
export const isMobileOrTablet = (): boolean => {
    const checkVendor = a => {
        return (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        );
    };
    return checkVendor(navigator.userAgent || navigator.vendor);
};

@Injectable()
export class SharedUtils {
    public static setFormControl(formControl: FormControl, defaultValue: unknown, formValue: unknown): void {
        formControl.updateValueAndValidity();
        const value = formControl.invalid ? defaultValue : formValue;
        formControl.setValue(value);
        formControl.markAsTouched();
    }

    public static fieldValidator(observable$: Observable<boolean>): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return observable$.pipe(
                filter(observable => !_isUndefined(observable) && observable !== null),
                map(observable => (observable ? { validatedControl: { value: control.value } } : null)),
                take(1)
            );
        };
    }

    /**
     * Builds a string of query parameters based on an array of values passed in
     */
    public static queryParamsFromArray(key: string, arrayOfValues: any[], keyIfObject?: string): string {
        const outString =
            '?' +
            _map(arrayOfValues, value => {
                if (keyIfObject !== undefined && value.hasOwnProperty(keyIfObject)) {
                    return `${key}[]=${value[keyIfObject]}`;
                }
                return `${key}[]=${value}`;
            }).join('&');

        return outString;
    }

    /**
     * Find search query in string
     *
     * @memberOf Utils
     */
    public static findInString(query: string, stringToSearch: string): boolean {
        query = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        stringToSearch = stringToSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return stringToSearch.indexOf(query) > -1;
    }

    /**
     * Strips periods from string
     */
    public static stripPeriods(text: string): string {
        return text.toLowerCase().replace(/\./g, '');
    }

    public static toFixedString(valueToFix: number | string, decimalPosition: number): string {
        if (typeof valueToFix === 'number') {
            return valueToFix.toFixed(decimalPosition);
        }

        if (typeof valueToFix === 'string') {
            return parseFloat(valueToFix)
                .toFixed(decimalPosition)
                .toString();
        }
    }

    public static toFixedNumber(valueToFix: number | string, decimalPosition: number): number {
        if (typeof valueToFix === 'number') {
            return +valueToFix.toFixed(decimalPosition);
        }

        if (typeof valueToFix === 'string') {
            return +parseFloat(valueToFix).toFixed(decimalPosition);
        }
    }

    /**
     * Gets the element offset on a page, jQuery style!
     */
    public static getElementOffset(element): any {
        const returnObj = {
            top: null,
            left: null,
        };

        if (!element) {
            return returnObj;
        }

        const rect = element.getBoundingClientRect();
        const doc = element.ownerDocument;
        const win = window;
        const docElem = doc.documentElement;

        returnObj.top = rect.top + win.pageYOffset - docElem.clientTop;
        returnObj.left = rect.left + win.pageXOffset - docElem.clientLeft;

        return returnObj;
    }

    /**
     * Makes it easier to set multiple styles on an object
     */
    public static setMultipleStyles(element: any, styles: Object, renderer: Renderer2) {
        const keys = Object.keys(styles);
        _forEach(keys, key => {
            renderer.setStyle(element, key, styles[key]);
        });
    }

    /**
     * Converts time represented in seconds into a formatted minutes:seconds string
     */
    public static secondsToMin(value: number): string {
        const roundedValue = Math.round(value);
        const seconds = Math.round(roundedValue % 60);
        const minutes = Math.floor(roundedValue / 60);
        const secondsDisplay = seconds < 10 ? '0' + seconds : seconds;
        return minutes + ':' + secondsDisplay;
    }

    public static minToSeconds(value: string): number {
        const split = value.split(':');
        const seconds = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
        return seconds;
    }

    public static getDayDelta(a: Date | string, b: Date | string): number {
        const MS_PER_DAY = 1000 * 60 * 60 * 24;
        const da = a instanceof Date ? a : this.getTimezoneDate(a);
        const db = b instanceof Date ? b : this.getTimezoneDate(b);

        const utc1 = Date.UTC(da.getFullYear(), da.getMonth(), da.getDate());
        const utc2 = Date.UTC(db.getFullYear(), db.getMonth(), db.getDate());

        return Math.floor((utc1 - utc2) / MS_PER_DAY);
    }

    /**
     * Returns the ordinal suffix ("st", "nd", "rd", "th")
     * for the number passed in
     */
    // eslint-disable-next-line complexity
    public static ordinalSuffix(num: string | number): string {
        let intNum;
        if (typeof num === 'string') {
            intNum = parseInt(num, 10);
        } else {
            intNum = num;
        }
        const remainder10 = intNum % 10;
        const remainder100 = intNum % 100;

        if (remainder10 === 1 && remainder100 !== 11) {
            return 'st';
        }
        if (remainder10 === 2 && remainder100 !== 12) {
            return 'nd';
        }
        if (remainder10 === 3 && remainder100 !== 13) {
            return 'rd';
        }
        return 'th';
    }

    /**
     * Feature detection to see if browser has the download HTML5 attribute
     */
    public static hasDownload(): boolean {
        return 'download' in document.createElement('a');
    }

    // Download DATA URI as file
    public static downloadURI(dataURI, fileName) {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataURI;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public static isEmailValid(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public static getTimezoneDate(d: string): Date {
        let timezoned = new Date(d);
        // get the date without taking care of the user timezone...
        timezoned = new Date(timezoned.valueOf() + timezoned.getTimezoneOffset() * 60000);

        return timezoned;
    }

    public static dateToYYYYMMDD(d: Date) {
        const mm = d.getMonth() + 1; // getMonth() is zero-based
        const dd = d.getDate();

        return [d.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
    }

    public static isDateWithinRange(date: Date | string, minDate: Date | string, maxDate: Date | string): boolean {
        date = typeof date === 'string' ? this.getTimezoneDate(date) : (date as Date);
        minDate = typeof minDate === 'string' ? this.getTimezoneDate(minDate) : minDate;
        maxDate = typeof maxDate === 'string' ? this.getTimezoneDate(maxDate) : maxDate;
        return _lte(date, maxDate) && _gte(date, minDate);
    }

    public static calculateHueBetweenColorRanges = (
        color1: Color,
        color2: Color,
        color3: Color,
        minInput: number,
        maxInput: number,
        input: number
    ): string => {
        // https://gist.github.com/gskema/2f56dc2e087894ffc756c11e6de1b5ed
        const inputPerc = ((input - minInput) * 100) / (maxInput - minInput);

        const colorA = inputPerc > 50 ? color2 : color1;
        const colorB = inputPerc > 50 ? color3 : color2;

        const gradient = {
            red: Math.round(colorA.r + ((colorB.r - colorA.r) * inputPerc) / 100),
            green: Math.round(colorA.g + ((colorB.g - colorA.g) * inputPerc) / 100),
            blue: Math.round(colorA.b + ((colorB.b - colorA.b) * inputPerc) / 100),
        };

        return `rgb(${gradient.red}, ${gradient.green}, ${gradient.blue})`;
    };
}

/** Class that handles unsubscriptions */
export class Subs {
    /** @member {Subscription[]} allSubs */
    public allSubs: Subscription[];

    constructor() {
        this.allSubs = [];
    }

    /**
     * Add Subscriptions to allSubs array
     * Expects an array of Subscriptions
     */
    public addSubs(subs: Subscription[]): Subscription[] {
        // Make sure the item it's mapping over is defined
        // and is a valid subscription before pushing
        // TODO: Find way to validate proper sub before unsubbing
        subs.map(sub => {
            if (!_isUndefined(sub) && sub.unsubscribe) {
                (<any>sub).id = subs.indexOf(sub);
                return this.allSubs.push(sub as Subscription);
            }
            return false;
        });
        return this.allSubs;
    }

    /**
     * Unsubscribes all Subjects in allSubs from their Observers
     *
     * Optional: Specify single Subject in allSubs you'd like to unsubscribe
     */
    public unsub(sub?: Subscription): Subscription[] {
        // If sub passed in, remove from allSubs list
        if (!_isUndefined(sub) && (<any>sub).id) {
            const found = this.allSubs.filter((item: any) => item.id === (<any>sub).id);
            // Remove the sub from the list
            _pull(this.allSubs, found[0]);
            found.map(item => item.unsubscribe());
            return this.allSubs;
        }

        // unsubscribe from all subscriptions in allSubs
        // and remove the sub from all subs, leaving it an empty
        // array again
        _remove(this.allSubs, s => {
            s.unsubscribe();
            return s;
        });
        return this.allSubs;
    }
}
