/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { Router, RouterState, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class SlTitleService {
    constructor(private translate: TranslateService, private _titleService: Title, private _router: Router) {}

    setTitle(param?: Object) {
        const titles$: Observable<string>[] = [];
        this._getLocaleTitle(titles$, this._router.routerState, this._router.routerState.root, param);

        const suffixTitle = 'SPORTLOGiQ';
        forkJoin(titles$)
            .pipe(take(1))
            .subscribe(values => {
                values.push(suffixTitle);
                this._titleService.setTitle(values.join(' - '));
            });
    }

    // eslint-disable-next-line complexity
    private _getLocaleTitle(
        titles$: Observable<string>[],
        state: RouterState,
        parent: ActivatedRoute,
        param: Object
    ): Observable<string>[] {
        if (parent?.snapshot.data?.title) {
            const obs = this.translate.get(parent.snapshot.data.title, param || {});
            titles$.push(obs);
        }

        if (state && parent) {
            this._getLocaleTitle(titles$, state, parent.firstChild, param);
        }

        return titles$;
    }
}
