import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttp } from '../custom-http.service';
import { Constants } from '@sportlogiq/shared';
import { IcePartner, SlPartnerAuthentication } from './partner.types';

@Injectable()
export class PartnerService {
    constructor(private _customHttp: CustomHttp) {}

    public getAuthenticationForPartner(partner: IcePartner): Observable<SlPartnerAuthentication> {
        return this._customHttp.get(`/users/authentication/${partner}`, {}, Constants.api_root_v3);
    }
}
