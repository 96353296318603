import { Injectable } from '@angular/core';
import { CanLoad, UrlSegment, Route } from '@angular/router';

import { UserService } from '@sportlogiq/core/user.service';
import { UserPermissions } from '@sportlogiq/shared';

@Injectable()
export class InsideEdgeGuard implements CanLoad {
    constructor(
        private _user: UserService
    ) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        if (this._user.checkForPermission(UserPermissions.canViewInsideEdge)) {
            return true;
        }
        return false;
    }
}
