import { Directive, Input, AfterViewChecked } from '@angular/core';
import { Utils } from '../utils';
import { forEach as _forEach } from 'lodash';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[mat-datepicker-highlight-dates]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdDatepickerHighlitDates implements AfterViewChecked {
    @Input() selectedDates: string[];
    @Input() datePickerOpen: boolean;

    ngAfterViewChecked() {
        if (this.datePickerOpen && this.selectedDates) {
            // element that contains the date value
            const calendarCell = document.body.getElementsByClassName('mat-calendar-body-cell');

            // element that will be highlighted
            const calendarContent = document.body.getElementsByClassName('mat-calendar-body-cell-content');

            // loop through each datepicker date value and check if the value exists in the dateRange array passed in @input
            // if so highlight the appropriate element
            _forEach(calendarCell, (value, key) => {
                const notFormattedDate = calendarCell[key].attributes['aria-label'].value;
                const dateValue = Utils.dateToYYYYMMDD(new Date(notFormattedDate));
                if (this.selectedDates.indexOf(dateValue) > -1) {
                    calendarContent[key].classList.add('highlight');
                }
            });
        }
    }
}
