import { Injectable } from '@angular/core';
import { Action, Selector, SelectorOptions, State, StateContext } from '@ngxs/store';
import { isMobileOrTablet } from '@sportlogiq/main/shared/sharedUtils';
import { Constants, UserPermissions } from '@sportlogiq/shared';
import { map } from 'rxjs';
import { AuthService } from '../auth.service';
import { FetchUserAccount, FetchUserAuthorization, FetchUserCompany, ResetUser, SetSLIdentTokenData, SetUser } from './user.actions';
import { SLAuthorization, userStateDefaults, UserStateModel } from './user.types';

@SelectorOptions({ injectContainerState: false })
@State<UserStateModel>({
    name: 'userState',
    defaults: userStateDefaults,
})
@Injectable({
    providedIn: 'root',
})
export class UserState {
    constructor(
        private _authService: AuthService
    ) {
        registerImpersonationOverride();
    }

    @Action(FetchUserAccount)
    private _fetchUserAccount({ patchState }: StateContext<UserStateModel>) {
        return this._authService.getUserAccount().pipe(
            map(account => {
                return patchState({
                    user: account.user
                });
            })
        );
    }

    @Action(FetchUserAuthorization)
    private _fetchUserAuthorization({ patchState }: StateContext<UserStateModel>) {
        return this._authService.getAuthorization().pipe(
            map(authorization => {
                return patchState({
                    authorization
                });
            })
        );
    }

    @Action(FetchUserCompany)
    private _fetchUserCompany({ patchState }: StateContext<UserStateModel>, { companyId }: FetchUserCompany) {
        return this._authService.getCompany(companyId).pipe(
            map(userCompany => {
                return patchState({
                    userCompany
                });
            })
        );
    }

    @Action(SetUser)
    private _setUser({ patchState }: StateContext<UserStateModel>, { user, impersonatedBy }: SetUser) {
        patchState({ user });
    }

    @Action(ResetUser)
    private _resetUser({ setState }: StateContext<UserStateModel>) {
        setState(userStateDefaults);
    }

    @Action(SetSLIdentTokenData)
    private _setSLIdentTokenData({ patchState }: StateContext<UserStateModel>, { tokenData }: SetSLIdentTokenData) {
        patchState({ slIdentTokenData: tokenData });
    }

    @Selector([UserState])
    static user(state: UserStateModel) {
        return state?.user;
    }

    @Selector([UserState])
    static impersonatedBy(state: UserStateModel) {
        return state.slIdentTokenData?.impby;
    }

    @Selector([UserState])
    static userAuthorization(state: UserStateModel) {
        return state?.authorization;
    }

    @Selector([UserState])
    static userLeagueAffiliations(state: UserStateModel) {
        return state?.authorization?.leagueAffiliations;
    }

    @Selector([UserState])
    static userCompany(state: UserStateModel) {
        return state?.userCompany;
    }

    @Selector([UserState.userAuthorization])
    static isPlayerUser(authorization: SLAuthorization) {
        return !!authorization?.playerId;
    }

    @Selector([UserState.isSuperUser, UserState.hasPermission])
    static isScoutingOnlyUser(isSuperUser: boolean, hasPermission: (permission: string) => boolean) {
        return hasPermission(UserPermissions.canLoginOnScoutingApp) && !isSuperUser;
    }

    @Selector([UserState.userAuthorization, UserState.isSuperUser])
    static hasPermission(authorization: SLAuthorization, isSuperUser: boolean) {
        return (permission: UserPermissions | string) => {
            return isSuperUser || authorization?.permissions.includes(permission);
        };
    }

    @Selector([UserState.hasPermission])
    static canDownloadCSV(hasPermission: (permissions: string) => boolean) {
        return hasPermission(UserPermissions.canExportToCSV) && !isMobileOrTablet();
    }

    @Selector([UserState.userAuthorization])
    static isSuperUser(authorization: SLAuthorization) {
        return authorization?.permissions.includes(UserPermissions.hasAllPermissions);
    }

    @Selector([UserState.hasPermission, UserState.isSuperUser])
    static isUserPlayer(hasPermission: (permission: string) => boolean, isSuperUser: boolean) {
        return (
            hasPermission(UserPermissions.canLoginOnPlayerApp) &&
            !hasPermission(UserPermissions.canLoginOnPlayerWithVideoApp) &&
            !isSuperUser
        );
    }

    @Selector([UserState.hasPermission, UserState.isSuperUser])
    static isUserAgent(hasPermission: (permission: string) => boolean, isSuperUser: boolean) {
        return hasPermission(UserPermissions.canLoginOnAgentApp) && !isSuperUser;
    }

    @Selector([UserState.impersonatedBy])
    static isImpersonating(impersonatedBy: string) {
        return !!impersonatedBy && !sessionStorage.getItem(Constants.impersonationOverrideStorageKey);
    }
}

function registerImpersonationOverride() {
    const impersonationOverride = (): void => {
        sessionStorage.setItem(Constants.impersonationOverrideStorageKey, 'true');
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).sl_override_impersonation_guard = impersonationOverride;
}
