import { Pipe, PipeTransform } from '@angular/core';
import { Game } from 'app/main/core/games/game.model';
import { SeasonStage } from 'app/main/core/seasons/season-stage.model';
import { GameStatus, Metric, PlayerQuickRef } from 'app/shared/types';
import { Player } from 'app/main/core/players/player.model';
import {

    values as _values,
    includes as _includes,
    lowerCase as _lowerCase,
    join as _join,
    pick as _pick,
    chain as _chain,
    indexOf as _indexOf,
    first as _first,
    orderBy as _orderBy,
    filter as _filter,
    isUndefined as _isUndefined,
    size as _size,
    flatten as _flatten,
    groupBy as _groupBy,
    uniq as _uniq,
} from 'lodash';
import { DatePipe, KeyValue } from '@angular/common';
import {
    getOTStatus,
    formatPlayerName,
    secondsToMinuteString,
    computeMetricValueByType,
    getGamePeriod,
    secondsToValidFormattedDuration,
    abbrevFirstName,
    getFullPeriodString,
} from '@sportlogiq/shared/utils';
import { FaceoffName, FaceoffDotName } from '@sportlogiq/main/core/faceoffs/faceoff.types';
import { BoxScoreScorer } from '../game-summary/box-score/box-score.model';
import { PlayerComparisonPlayer } from '@sportlogiq/main/scouting/player-comparison/player-comparison.types';
import { ManpowerInterval } from '../game-summary/game-summary.types';
import { TeamModelAPI } from '@sportlogiq/main/core/store/team/team.models';
import { ContextFiltersTypes, EnabledManpowerSituationInfo } from '../utils/filter-video/filter-video.types';
import { NewPlayerEvent } from '@sportlogiq/main';
import { TableHeaderCell } from '@sportlogiq/main/statistics/statistics.types';
import { ManpowerSituationInfo, ManpowerSituationStatsContext } from '@sportlogiq/main/core/context-data/context-data.types';

@Pipe({
    name: 'getLabel',
})
export class GetSeasonLabelPipe implements PipeTransform {
    transform(val: string): string {
        const value = val.split(' ')[0];
        return value.slice(0, 5) + value.slice(-2);
    }
}

@Pipe({ name: 'getSeasonMinDate' })
export class GetMinDateWithPipe implements PipeTransform {
    transform(selectedSeason: SeasonStage, regularSeason: SeasonStage, includePreviousRegularSeason: boolean) {
        if (selectedSeason?.stage === 'playoffs' && includePreviousRegularSeason) {
            return regularSeason?.startDate;
        } else {
            return selectedSeason?.startDate;
        }
    }
}

@Pipe({ name: 'getGameStatus' })
export class GameStatusPipe implements PipeTransform {
    transform(game: Game, teamId: string) {
        if (!teamId) {
            console.error('No team id provided for gameStatus pipe');
        }

        return this.getGameStatus(game, teamId);
    }

    // eslint-disable-next-line complexity
    private getGameStatus(game: Game, teamId: string): GameStatus {
        if (!game.inProgress && !game.isUpcoming) {
            if (game.homeScore > game.awayScore) {
                return game.homeTeamId === teamId ? GameStatus.Win : GameStatus.Loss;
            } else if (game.homeScore < game.awayScore) {
                return game.homeTeamId === teamId ? GameStatus.Loss : GameStatus.Win;
            } else {
                return GameStatus.Tied;
            }
        } else if (game.inProgress && !game.isUpcoming) {
            if (game.homeScore > game.awayScore) {
                return game.homeTeamId === teamId ? GameStatus.Lead : GameStatus.Trail;
            } else if (game.homeScore < game.awayScore) {
                return game.homeTeamId === teamId ? GameStatus.Trail : GameStatus.Lead;
            } else {
                return GameStatus.Tied;
            }
        }
    }
}

@Pipe({ name: 'getOvertimeStatus' })
export class GameOvertimeStatusPipe implements PipeTransform {
    transform(game: Game) {
        return getOTStatus(game);
    }
}

@Pipe({ name: 'formatScoringPlayers' })
export class FormatScoringPlayersPipe implements PipeTransform {
    transform(player: BoxScoreScorer, isLast: boolean) {
        const { playerName, firstAssistName, secondAssistName } = player;
        // no need "&" sign if there is no second assist player
        const showFirstAssistName = firstAssistName ? firstAssistName : '';
        const showSecondAssistName = secondAssistName ? ' & ' + secondAssistName : '';
        const showPlayerName = playerName ? playerName : 'None';

        // only need comma when it's between two scorer in the same period
        const showComma = isLast ? ' ' : ', ';
        return showPlayerName + this.formatAssists(showFirstAssistName, showSecondAssistName, playerName) + showComma;
    }

    formatAssists(showFirstAssistName: string, showSecondAssistName: string, playerName: string): string {
        // only show "Unassisted" when there is a scorer
        // if there is no scorer, don't show empty "()""
        const showUnassisted = playerName ? ' (Unassisted)' : '';
        return showFirstAssistName || showSecondAssistName ? ' (' + showFirstAssistName + showSecondAssistName + ')' : showUnassisted;
    }
}

@Pipe({ name: 'formatPeriod' })
export class FormatPeriodPipe implements PipeTransform {
    transform(period: number) {
        return getFullPeriodString(period);
    }
}

@Pipe({
    name: 'playerQuickRef',
})
export class PlayerQuickRefPipe implements PipeTransform {
    private _pathForQuickRef = {
        // eslint-disable-next-line complexity
        xgf: (player: Player) => {
            return (
                player.selectedSeasonSummary?.overall?.xgfratioComputed ||
                player.selectedSeasonSummary?.teams[0]?.xgfratioComputed ||
                player.quickRefMetrics?.xgfratioComputed ||
                ''
            );
        },
        // eslint-disable-next-line complexity
        soo: (player: Player) => {
            return (
                player.selectedSeasonSummary?.overall?.sooComputed ||
                player.selectedSeasonSummary?.teams[0]?.sooComputed ||
                player.quickRefMetrics?.sooComputed ||
                ''
            );
        },
        // eslint-disable-next-line complexity
        soodiff: (player: Player) => {
            return (
                player.selectedSeasonSummary?.overall?.soodiffComputed ||
                player.selectedSeasonSummary?.teams[0]?.soodiffComputed ||
                player.quickRefMetrics?.soodiffComputed ||
                ''
            );
        },
        // eslint-disable-next-line complexity
        ozstart: (player: Player) => {
            return (
                player.selectedSeasonSummary?.overall?.ozstartComputed ||
                player.selectedSeasonSummary?.teams[0]?.ozstartComputed ||
                player.quickRefMetrics?.ozstartComputed ||
                ''
            );
        },
    };

    transform(player: Player, type: PlayerQuickRef): string {
        const action = this._pathForQuickRef[type] || (() => '');
        return action(player);
    }
}

@Pipe({
    name: 'iosDatePipe',
})
export class IOSDatePipe implements PipeTransform {
    transform(dateString: string) {
        // Export date in the following format: June 20, 2018
        const dateObj = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return dateObj.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);
    }
}

@Pipe({
    name: 'dateFormat',
})
export class DateFormat implements PipeTransform {
    private defaultFormat = 'EEEE, MMMM d, yyyy';
    constructor(private _datepipe: DatePipe) {}

    transform(dateString: string | Date, dateFormat?: string) {
        if (dateFormat) {
            this.defaultFormat = dateFormat;
        }
        return this._datepipe.transform(dateString, this.defaultFormat);
    }
}

@Pipe({ name: 'filterAttributePipe' })
export class FilterAttributePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform<T>(objects: T[], attributes: string[], search: string): T[] {
        return _filter(objects, object => {
            const attributeValuesToSearchThrough = _values(_pick(object, attributes));
            const searchableString = _lowerCase(_join(attributeValuesToSearchThrough, ' '));
            return _includes(searchableString, _lowerCase(search));
        });
    }
}

@Pipe({ name: 'transformFaceoffNamePipe' })
export class TransformFaceoffNamePipe implements PipeTransform {
    // faceoff dot will not specify the east and west anymore in label
    // eslint-disable-next-line complexity
    transform(faceoffName: FaceoffName, isPlural = true): FaceoffDotName {
        let faceoffDotName: FaceoffDotName;
        switch (faceoffName) {
            case FaceoffName.ozEast:
            case FaceoffName.ozWest:
                faceoffDotName = FaceoffDotName.ozDot;
                break;

            case FaceoffName.dzEast:
            case FaceoffName.dzWest:
                faceoffDotName = FaceoffDotName.dzDot;
                break;
            case FaceoffName.nzSouthEast:
            case FaceoffName.nzSouthWest:
                faceoffDotName = FaceoffDotName.nzSouthDot;
                break;
            case FaceoffName.nzNorthEast:
            case FaceoffName.nzNorthWest:
                faceoffDotName = FaceoffDotName.nzNorthDot;
                break;

            case FaceoffName.nzCenter:
                faceoffDotName = FaceoffDotName.nzCenter;
                break;

            case FaceoffName.ozTotal:
                faceoffDotName = FaceoffDotName.ozTotal;
                break;

            case FaceoffName.dzTotal:
                faceoffDotName = FaceoffDotName.dzTotal;
                break;

            default:
                faceoffDotName = isPlural ? FaceoffDotName.totalForPlural : FaceoffDotName.totalForSingular;
        }
        return faceoffDotName;
    }
}

@Pipe({ name: 'boxScorePipe' })
export class BoxScorePipe implements PipeTransform {
    transform(periodScore: number[], index: number): string | number {
        return periodScore && isFinite(periodScore[index]) ? periodScore[index] : '-';
    }
}

@Pipe({ name: 'formatPlayerName' })
export class FormatPlayerNamePipe implements PipeTransform {
    transform(firstName: string, lastName: string): string {
        return formatPlayerName(firstName, lastName);
    }
}

@Pipe({ name: 'playersToIdsPipe' })
export class PlayersToIdsPipe implements PipeTransform {
    transform(players: PlayerComparisonPlayer[]): string[] {
        return players ? players.map(value => value.player.id) : [];
    }
}

@Pipe({ name: 'getPlayersPositionPipe' })
export class GetPlayersPositionPipe implements PipeTransform {
    transform(players: PlayerComparisonPlayer[]): string {
        if (!players || players.length === 0) {
            return '';
        } else {
            return players[0].player.primaryPosition;
        }
    }
}

@Pipe({ name: 'getPlayersPositionsPipe' })
export class GetPlayersPositionsPipe implements PipeTransform {
    transform(players: PlayerComparisonPlayer[]): string[] {
        if (!players || players.length === 0) {
            return [];
        } else {
            return _uniq(players.map(p => p.player.primaryPosition));
        }
    }
}

@Pipe({
    name: 'secondsToMinutesPipe',
})
export class SecondsToMinutesPipe implements PipeTransform {
    transform(val: number): string {
        return secondsToMinuteString(val);
    }
}

@Pipe({
    name: 'secondsToValidFormattedDurationPipe',
})
export class SecondsToValidFormattedDurationPipe implements PipeTransform {
    transform(inputNumber: number): string {
        return secondsToValidFormattedDuration(inputNumber);
    }
}

@Pipe({
    name: 'getGamePeriod',
})
export class GetGamePeriod implements PipeTransform {
    transform(game: Game): string {
        return getGamePeriod(game);
    }
}

@Pipe({
    name: 'getManpowerSituation',
})
export class GetManpowerSituationPipe implements PipeTransform {
    transform(manpowerInternal: ManpowerInterval): string {
        if (!manpowerInternal.awayTeamSkatersOnIce || !manpowerInternal.homeTeamSkatersOnIce) {
            return '';
        } else if (manpowerInternal.manpowerSituation === 'shortHanded') {
            return manpowerInternal.awayTeamSkatersOnIce + 'v' + manpowerInternal.homeTeamSkatersOnIce;
        } else {
            return manpowerInternal.homeTeamSkatersOnIce + 'v' + manpowerInternal.awayTeamSkatersOnIce;
        }
    }
}

@Pipe({
    name: 'computeMetric',
})
export class ComputeMetricPipe implements PipeTransform {
    transform(rawValue: number, metricType: string, aggregation?: string, distance?: number, rateFormat?: string): string {
        return computeMetricValueByType(metricType, rawValue, aggregation, distance, rateFormat);
    }
}

@Pipe({
    name: 'todayDate',
})
export class TodayDatePipe implements PipeTransform {
    constructor(private _datepipe: DatePipe) {}

    transform(inputDate: Date): string {
        const todaysDate = new Date();
        if (!inputDate) {
            return null;
        }
        const clonedInputDate = new Date(inputDate.getTime());
        if (clonedInputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            return 'today';
        }
        return this._datepipe.transform(clonedInputDate, 'EEE');
    }
}

@Pipe({
    name: 'abbrevFirstName',
})
export class AbbrevFirstNamePipe implements PipeTransform {
    transform(firstName: string, lastName: string): string {
        return abbrevFirstName(firstName, lastName);
    }
}

@Pipe({
    name: 'playerNameById',
})
export class PlayerNameById implements PipeTransform {
    transform(playerId: string, players: Record<string, Player>, abbreviateFirstName: boolean): string {
        if (!playerId) {
            return 'N/A';
        }
        const player = players ? players[playerId] : undefined;
        if (!player) {
            return '';
        }
        return abbreviateFirstName ? abbrevFirstName(player.firstName, player.lastName) : `${player.firstName} ${player.lastName}`;
    }
}

@Pipe({
    name: 'playerNames',
})
export class PlayerNamesPipe implements PipeTransform {
    transform(input: string[], players: Player[]): string {
        return _chain(players)
            .filter(p => _indexOf(input, p.id) > -1)
            .map(p => `${p.firstName.charAt(0).toUpperCase()}. ${p.lastName}`)
            .join(', ')
            .value();
    }
}

@Pipe({
    name: 'playerIdToPlayerName',
})
export class PlayerIdToPlayerName implements PipeTransform {
    transform(playerId: string, players: { id: string; firstName: string; lastName: string; }[]): string {
        // using pipe inside another pipe: https://stackoverflow.com/a/40634201
        const playerNamePipe = new FormatPlayerNamePipe();
        const player = players ? players.find(p => p.id === playerId) : undefined;
        return player ? playerNamePipe.transform(player.firstName, player.lastName) : playerId;
    }
}

@Pipe({
    name: 'teamIdToTeamShorthand',
})
export class TeamIdToTeamShorthand implements PipeTransform {
    transform(teamId: string, teams: TeamModelAPI[]): string {
        const team = teams ? teams.find(t => t.id === teamId) : undefined;
        return team ? team.shorthand : teamId;
    }
}

@Pipe({
    name: 'currentSeason',
})
export class CurrentSeasonPipe implements PipeTransform {
    transform(season: SeasonStage, seasons: SeasonStage[]) {
        const sortedSeasons = _orderBy(seasons, s => s.endDate, 'desc');
        const firstSeason = _first(sortedSeasons);
        return (
            season.leagueId === firstSeason?.leagueId && season.seasonid === firstSeason?.seasonid && season.stage === firstSeason?.stage
        );
    }
}

@Pipe({
    name: 'playerIsSelected',
})
export class PlayerIsSelectedPipe implements PipeTransform {
    transform(selectedPlayers: string[], playerId: string) {
        return selectedPlayers?.includes(playerId);
    }
}

@Pipe({
    name: 'renderContextFilter',
})
export class RenderContextFilterPipe implements PipeTransform {
    transform(contextFilters: ContextFiltersTypes[], contextFilter: ContextFiltersTypes) {
        return contextFilters?.includes(contextFilter);
    }
}

@Pipe({ name: 'orderByPlayerPosition' })
export class OrderByPlayerPositionPipe implements PipeTransform {
    transform(playersByPosition: KeyValue<string, Player[]>[], keyOrder: string[]) {
        return _orderBy(playersByPosition, item => keyOrder.indexOf(item.key));
    }
}

@Pipe({ name: 'isLiveGameDataBadPipe' })
export class IsLiveGameDataBadPipe implements PipeTransform {
    transform(game: Game): boolean {
        if (!game) {
            return false;
        }
        if (!_isUndefined(game.isCompleted)) {
            // necessary to account for the different game model in the old xy viewer
            return !game.isCompleted;
        }
        return !game.isUpcoming && !game.metricsFullyProcessed;
    }
}

@Pipe({ name: 'getEnabledManpowerSituations' })
export class GetEnabledManpowerSituations implements PipeTransform {
    transform(situations: EnabledManpowerSituationInfo[]) {
        return situations?.filter(sit => !sit.disabled);
    }
}

@Pipe({ name: 'getTeamWithEventContext' })
export class GetTeamWithEventContext implements PipeTransform {
    transform(playerEvent: NewPlayerEvent, isAgainstMode: boolean, teamsById: Record<string, TeamModelAPI>) {
        if (!playerEvent) {
            return;
        }
        return teamsById[isAgainstMode ? playerEvent.teamId : playerEvent.opposingTeamId];
    }
}

@Pipe({ name: 'statisticsColumnDefinition' })
export class StatisticsColumnDefinitionPipe implements PipeTransform {
    transform(columns: TableHeaderCell<Metric>[]): string[] {
        return columns.map(column => column.uniqueKey);
    }
}

@Pipe({ name: 'statisticsManpowerLabel', pure: false })
export class StatisticsManpowerLabelPipe implements PipeTransform {
    transform(situations: ManpowerSituationInfo[], manpowerSituationContext: ManpowerSituationStatsContext, verbose?: boolean): string {
        const selectedLength = _size(situations);
        const manpowerSituationContextFlattened = _flatten(_values(manpowerSituationContext));

        if (selectedLength === 0 || selectedLength === manpowerSituationContextFlattened.length) {
            return 'All Manpowers';
        }
        if (selectedLength === 1) {
            return this._getLabelForSituation(situations[0]);
        }

        const situationsGroupedByMpType = _groupBy(situations, s => s.manpower);
        const everyOptionIsSelectedInEveryGroup = this._isEveryOptionInEachGroupSelected(
            situationsGroupedByMpType,
            manpowerSituationContext
        );

        if (this._shouldDisplayCumulativeLabel(verbose, everyOptionIsSelectedInEveryGroup)) {
            const firstKey = Object.keys(situationsGroupedByMpType)[0];
            return this._getLabelForSelectedOption(situationsGroupedByMpType[firstKey], situations.length, verbose);
        }

        const allLabels = this._getLabelsPerAllManpower(situationsGroupedByMpType, manpowerSituationContext, verbose);
        return allLabels.join(', ');
    }

    private _getLabelForSituation(situation: ManpowerSituationInfo): string {
        return `${situation.manpower}: ${situation.skatersOnIceSituation}`;
    }

    // e.g. ES:3v3 + 15 more (in the 15 we will include ES and any other option including the ones from other mp types)
    private _shouldDisplayCumulativeLabel(verbose: boolean, everyOptionIsSelectedInEveryGroup: boolean) {
        return !verbose && !everyOptionIsSelectedInEveryGroup;
    }

    private _getLabelsPerAllManpower(
        situationsGroupedByMpType,
        manpowerSituationContext: ManpowerSituationStatsContext,
        verbose: boolean
    ): string[] {
        return Object.keys(situationsGroupedByMpType).map(key => {
            const selectedOptionsForMp = situationsGroupedByMpType[key];
            const allOptionsForMp = manpowerSituationContext[key as keyof ManpowerSituationStatsContext];
            return allOptionsForMp.length === selectedOptionsForMp.length
                ? `${key}: All`
                : this._getLabelForSelectedOption(selectedOptionsForMp, selectedOptionsForMp.length, verbose);
        });
    }

    private _isEveryOptionInEachGroupSelected(situationsGroupedByMpType, manpowerSituationContext: ManpowerSituationStatsContext): boolean {
        return Object.keys(situationsGroupedByMpType).every(
            (key: keyof ManpowerSituationStatsContext) => situationsGroupedByMpType[key].length === manpowerSituationContext[key].length
        );
    }

    private _getLabelForSelectedOption(
        selectedOptionsForMp: ManpowerSituationInfo[],
        allSelectedOptionsLength: number,
        verbose?: boolean
    ): string {
        if (verbose) {
            return selectedOptionsForMp.map(option => this._getLabelForSituation(option)).join(', ');
        } else {
            return `${this._getLabelForSituation(selectedOptionsForMp[0])} + ${allSelectedOptionsLength - 1} more`;
        }
    }
}
