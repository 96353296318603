import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State, Action, StateContext, Selector, Actions } from '@ngxs/store';
import { filter as _filter } from 'lodash';
import {
    VirtualMetricsAPIModel,
    UserSetting,
    UserSettingsStateModel,
    userSettingsStateDefault,
    UserSettingDefinition,
} from './user-settings.types';
import { UserSettingsFetchAll, UserSettingsSave, UserSettingsDelete } from './user-settings.actions';
import { CustomHttp } from './custom-http.service';
import { Constants } from '../../environments/constants';
import { encodeAndIsoDate } from '@sportlogiq/shared';

class UserSettingsService {
    constructor(public http: CustomHttp) {}

    protected fetchVirtualMetrics(): Observable<VirtualMetricsAPIModel> {
        return this.http.get('/metrics/virtualenumsdefinitions');
    }

    protected fetchUserSettingsDefinitions(): Observable<UserSettingDefinition[]> {
        return this.http.get('/usersettingdefinitions', undefined, Constants.api_root_v3);
    }

    protected fetchUserSettings(): Observable<UserSetting[]> {
        return this.http.get('/usersettings', undefined, Constants.api_root_v3);
    }

    protected saveUserSettings(payload: UserSetting[]): Observable<void> {
        return this.http.post('/usersettings', payload, {}, Constants.api_root_v3);
    }

    protected deleteUserSettings(userSettingId: string): Observable<string> {
        const queryParamString = `?updatedon=${encodeAndIsoDate(new Date())}`;
        return this.http.delete(`/usersettings/${userSettingId}${queryParamString}`, {}, Constants.api_root_v3);
    }
}

@Injectable()
@State<UserSettingsStateModel>({
    name: 'userSettings',
    defaults: userSettingsStateDefault,
})
export class UserSettingsState extends UserSettingsService {
    constructor(public http: CustomHttp, private _actions$: Actions) {
        super(http);
    }

    @Action(UserSettingsFetchAll)
    private _fetchAllUserSettings({ patchState, getState }: StateContext<UserSettingsStateModel>) {
        return forkJoin([this.fetchVirtualMetrics(), this.fetchUserSettingsDefinitions(), this.fetchUserSettings()]).pipe(
            map(([virtualMetrics, userSettingDefinitions, userSettings]) => {
                patchState({ virtualMetrics, userSettingDefinitions, userSettings });
            })
        );
    }

    @Action(UserSettingsSave)
    private _saveUserSetting({ patchState, getState }: StateContext<UserSettingsStateModel>, { userSettings }: UserSettingsSave) {
        const newUserSettings = [...userSettings];

        return this.saveUserSettings(newUserSettings).pipe(
            map(response => {
                patchState({ userSettings: newUserSettings });
            })
        );
    }

    @Action(UserSettingsDelete)
    private _deleteUserSetting({ patchState, getState }: StateContext<UserSettingsStateModel>, { userSettingId }: UserSettingsDelete) {
        const currentValue = getState().userSettings;
        const newValue = _filter(currentValue, setting => setting.id !== userSettingId);
        const newUserSettings = [...newValue];

        return this.deleteUserSettings(userSettingId).pipe(
            map(response => {
                patchState({ userSettings: newUserSettings });
            })
        );
    }

    @Selector()
    static virtualMetrics(state: UserSettingsStateModel) {
        return state.virtualMetrics;
    }

    @Selector()
    static userSettingDefinitions(state: UserSettingsStateModel) {
        return state.userSettingDefinitions;
    }

    @Selector()
    static userSettings(state: UserSettingsStateModel) {
        return state.userSettings;
    }
}
